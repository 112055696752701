import { useEffect, useState } from "react"
import { Button, LinearProgress } from "@mui/material"

import LinkingImage from "components/modals/LinkedAccountsModal/fragments/LinkingImage"
import Screen from "components/modals/LinkedAccountsModal/fragments/Screen"
import { PageProps } from "components/modals/LinkedAccountsModal/screens/types"

const LinkingAccountScreen = ({ progressData, screenProps }: PageProps) => {
  const [linked, setLinked] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLinked(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <Screen {...screenProps}>
        <div className="wfull hfull flex-c mt-8">
          <LinkingImage />

          <div className="wfull flex-c mt-12">
            {linked && (
              <div className="wfull flex-c">
                <img src="/ui/check.svg" width={40} height={40} />
              </div>
            )}

            <p className="fs-2">
              {linked
                ? "Account linked!"
                : `Linking your ${progressData.brand} account...`}
            </p>
          </div>

          {!linked && (
            <div className="mw-80 wfull mt-12">
              <LinearProgress
                color="primary"
                sx={{
                  borderRadius: 6,
                }}
              />
              <div className="wfull flex-c">
                <p className="fs--1 opacity-05">
                  Gathering device information...
                </p>
              </div>
            </div>
          )}
        </div>
      </Screen>

      {linked && (
        <div className="buttons">
          <Button variant="contained" onClick={screenProps.moveForward}>
            Continue
          </Button>
        </div>
      )}
    </>
  )
}

export default LinkingAccountScreen
