import Footer from "components/shell/Footer"
import Navigation from "components/shell/Navigation"

import type { Children } from "types"

/**
 * The root template for the application.
 * @param {Children}
 * @notes Meant to be used by other templates or as a standalone
 *  template if no other template is specified.
 */
const RootTemplate = ({ children }: Children) => {
  return (
    <>
      <Navigation />
      <div className="root-template">{children}</div>
      <Footer />
    </>
  )
}

export default RootTemplate
