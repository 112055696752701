import { useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { useRouter } from "next/router"
import { Box, Button, Typography } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { pick } from "lodash"

import { useSeamMutation } from "hooks/useSeamMutation"
import useSessionStore from "hooks/useSessionStore"

import TextInput from "components/form/TextInput"

import { useOrganizationInfoModalContext } from "../context"

import { colorDefaults } from "lib/mui"

const ORG_NAME_MAX_LENGTH = 40

const textSx = {
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "132%",
  color: colorDefaults.text.textGray1,
}

const OrgInfoScreen = ({}) => {
  const context = useOrganizationInfoModalContext()

  const [name, setName] = useState<string>(context.state.name)

  const { push } = useRouter()
  const { setOrganizationId } = useSessionStore((s) =>
    pick(s, ["setOrganizationId"])
  )

  const queryClient = useQueryClient()
  const { isLoading: isCreating, mutateAsync: createOrganization } =
    useSeamMutation(async (seam, _ev) => {
      if (!name) {
        throw new Error("Can't create organization without name!")
      }

      const organization = await seam.organizations.create({
        name,
      })
      setOrganizationId(organization.organization_id)
      queryClient.invalidateQueries(["organizations"])
      push("/")
    }, {})

  const { isLoading: isUpdating, mutateAsync: updateOrganization } =
    useSeamMutation(async (seam, _ev) => {
      if (!name) throw new Error("Can't update organization without name!")

      await seam.organizations.update({
        name,
      })
      queryClient.invalidateQueries(["organizations"])
    }, {})

  const handleSave = async () => {
    if (context.state.mode === "create") {
      if (process.env.NEXT_PUBLIC_DEMO) {
        toast.success(`Created organization ${name}!`)
      } else {
        await createOrganization({})
      }
    } else if (context.state.mode === "edit") {
      await updateOrganization({})
    }
  }

  useEffect(() => {
    context.setShowBackButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="wfull hfull VStack align-c pt-8">
      {isCreating || isUpdating ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            pt: 7,
          }}
        >
          <div className="spinner size-8"></div>
          <Typography
            sx={{
              ...textSx,
              mt: 2,
            }}
          >
            {isCreating ? "Creating" : "Updating"} {name}...
          </Typography>
        </Box>
      ) : (
        <>
          {context.state.mode === "create" && (
            <div className="wfull flex-c text-c mw-96">
              <Typography
                sx={{
                  ...textSx,
                  mb: 2,
                }}
              >
                You will be the organization&apos;s super admin. The
                organization name and your role can be changed later.
              </Typography>
            </div>
          )}

          <div className="wfull relative px-8">
            <TextInput
              value={name}
              onChange={(ev) => setName(ev.target.value)}
              label="Organization name"
              placeholder='e.g. "My Company"'
              fullWidth
              disabled={isCreating || isUpdating}
            />

            {name.length > ORG_NAME_MAX_LENGTH && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 32,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: "132%",
                    color: "var(--danger)",
                  }}
                >
                  {ORG_NAME_MAX_LENGTH} character limit
                </Typography>
              </Box>
            )}
          </div>
        </>
      )}

      <div className="HStack mt-12 pb-4 gap-2">
        <Button variant="contained" color="secondary" onClick={context.close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSave()
            context.close()
          }}
          disabled={
            isCreating ||
            isUpdating ||
            (name || "").trim() === "" ||
            name.length > ORG_NAME_MAX_LENGTH
          }
        >
          {isCreating || isUpdating ? "Saving..." : "Save"}
        </Button>
      </div>
    </div>
  )
}

export default OrgInfoScreen
