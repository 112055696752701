import { useEffect, useState } from "react"
import classNames from "classnames"

import useMyOrganization from "hooks/auth/useMyOrganization"

import Logo from "components/brand/Logo"
import DynamicLink from "components/interactive/DynamicLink"

import Desktop from "./menus/Desktop"
import Mobile from "./menus/Mobile"

import { generateMods } from "lib/utils"

const Navigation = () => {
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 12) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const organization = useMyOrganization()

  const links = [
    {
      label: organization?.name ?? "Home",
      href: "/",
    },
  ]

  return (
    <nav className={classNames("nav", generateMods({ hasScrolled }))}>
      <div className="container h-100p">
        <div className="nav-container">
          <div className="flex align-c flex-row">
            <Logo />
          </div>
          <div className="flex align-c flex-row">
            {links.map((link) => (
              <DynamicLink
                key={link.href}
                href={link.href}
                className="nav-link"
              >
                {link.label}
              </DynamicLink>
            ))}
            <div className="ml-4">
              <Desktop />
              <Mobile />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
