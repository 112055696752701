import { useState } from "react"
import Image from "next/image"
import { Tooltip } from "@mui/material"

interface Props {
  value: string
}

const CopyInput = ({ value }: Props) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value)
    setShowTooltip(true)

    setTimeout(() => {
      setShowTooltip(false)
    }, 2000)
  }

  return (
    <>
      <Tooltip
        title="Copied!"
        open={showTooltip}
        onClose={() => setShowTooltip(false)}
        placement="top"
        // onOpen={() => setShowTooltip(true)}
      >
        <button className="copy-input" onClick={copyToClipboard}>
          <p>{value}</p>
          <i className="icon">
            <Image src="/icons/copy.svg" alt="Copy" width={24} height={24} />
          </i>
        </button>
      </Tooltip>
    </>
  )
}

export default CopyInput
