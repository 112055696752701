import Image from "next/image"

import { fetchBrandLogoAndName } from "lib/brands"
import { LinkedAccountType, UNKNOWN_LOCK_IMAGE } from "lib/constants"
import ChevronRight from "assets/images/chevron-right@24px.svg"

import { LinkedAccount } from "types"

interface Props {
  linkedAccount: LinkedAccount
  onClick: () => void
}

const LinkedAccountRow = ({ linkedAccount, onClick }: Props) => {
  const brand = fetchBrandLogoAndName(
    linkedAccount.account_type as LinkedAccountType
  )

  const image = brand ? brand.logo : UNKNOWN_LOCK_IMAGE
  const name = brand ? brand.readableName : "Unknown Account Type"

  return (
    <div
      className="d-flex align-center linked-account w-full"
      onClick={onClick}
    >
      <div className="brand-image mr-4">
        <Image src={image} alt={name} height={42} />
      </div>
      <div className="SBStack w-full">
        <div className="fs--2">
          <span>{linkedAccount.user_identifier.email}</span>
          <div className="text-tertiary mt-1">
            <span>{name}</span>
            <span className="mx-2">•</span>
            <span>{linkedAccount.device_count}</span>
          </div>
        </div>
        <ChevronRight />
      </div>
    </div>
  )
}

export default LinkedAccountRow
