import { MouseEventHandler } from "react"
import ms from "ms"

import { useOwnUser } from "hooks/api/useUser"
import { useSeamQuery } from "hooks/useSeamQuery"

import Avatar from "components/media/Avatar"

import { generateColorFromString } from "lib/utils"

interface Props {
  onClick: MouseEventHandler<HTMLButtonElement>
}

const Trigger = ({ onClick }: Props) => {
  const { user } = useOwnUser()

  const { data: incidents } = useSeamQuery(["incidents", {}, "list"], (seam) =>
    seam.incidents.list({
      between: [
        // Last 30 days
        new Date(Date.now() - ms("30d")),
        new Date(),
      ],
    })
  )

  const hasIncidents = () => {
    if (typeof incidents === "undefined") return false
    return incidents.length > 0
  }

  return (
    <button onClick={onClick} className="bg-transparent">
      <Avatar
        color={generateColorFromString(user.full_name)}
        notify={hasIncidents()}
      >
        {user.initials !== ""
          ? user.initials
          : user.email?.charAt(0)?.toUpperCase() ?? ""}
      </Avatar>
    </button>
  )
}

export default Trigger
