import { useEffect } from "react"
import { useRouter } from "next/router"

import { useSeamQuery } from "hooks/useSeamQuery"

import BuildingInfoModal from "components/modals/BuildingInfoModal"
import { ModalProps } from "components/modals/Modal/Modal"

interface Props extends Pick<ModalProps, "isOpen" | "close"> {}

const EditBuildingModal = ({ ...props }: Props) => {
  const router = useRouter()
  const { building_id } = router.query

  const { data: building } = useSeamQuery(
    ["buildings", { building_id }, "get"],
    (seam) =>
      seam.buildings.get({
        building_id: building_id! as string,
      }),
    {
      enabled: !!building_id,
    }
  )

  return (
    <BuildingInfoModal
      operatingData={{
        building,
        mode: "edit",
      }}
      {...props}
    />
  )
}

export default EditBuildingModal
