import { useEffect, useState } from "react"

import { ModalProps } from "components/modals/Modal/Modal"
import {
  defaultState,
  OrganizationInfoModalContext,
  OrganizationInfoModalData,
  OrganizationInfoModalOperatingData,
  OrganizationInfoModalState,
} from "components/modals/OrganizationInfoModal/context"
import OrgInfoScreen from "components/modals/OrganizationInfoModal/screens/OrgInfoScreen"
import StackModal from "components/modals/StackModal"

interface Props extends Pick<ModalProps, "isOpen" | "close"> {
  operatingData: OrganizationInfoModalOperatingData
}

const OrganizationInfoModal = ({ operatingData, ...props }: Props) => {
  const [state, setState] = useState<OrganizationInfoModalState>(defaultState)

  useEffect(() => {
    setState({
      organizationId: operatingData?.organization?.organization_id,
      name: operatingData?.organization?.name || "",
      mode: operatingData.mode,
    })
  }, [operatingData])

  return (
    <StackModal<OrganizationInfoModalData>
      OperatingContext={OrganizationInfoModalContext}
      className="org-info-modal"
      screens={{
        orgName: OrgInfoScreen,
      }}
      sequence={["orgName"]}
      headerLabel={
        operatingData.mode === "create"
          ? "Create a new organization"
          : "Edit organization"
      }
      screenProps={{
        state,
        setState,
      }}
      onReset={() => setState(defaultState)}
      {...props}
    />
  )
}

export default OrganizationInfoModal
