import classNames from "classnames"

import BaseModal, { Props as BaseModalProps } from "components/modals/BaseModal"

interface Props extends Omit<BaseModalProps, "className"> {
  icon: React.ReactNode
  title: string
  content: React.ReactNode

  // Pass a bare `button` element to get the default styling.
  //
  // Some classes will modify the look of the button; allowed
  // classes are:
  // - `danger`
  actions: React.ReactNode

  // Typically for fading the icon as-needed
  iconMods?: string
}

const Alert = ({
  icon,
  title,
  content,
  actions,
  iconMods,
  ...props
}: Props) => {
  return (
    <BaseModal className="alert-dialog" {...props}>
      <div className="content">
        <div className="alert-grid">
          <div className="grid-block">
            <div className={classNames("icon size-8", iconMods)}>{icon}</div>
          </div>
          <div className="grid-block">
            <h1 className="title">{title}</h1>
            <div className="mt-6">{content}</div>
          </div>
        </div>
      </div>
      <div className="actions">{actions}</div>
    </BaseModal>
  )
}

export default Alert
