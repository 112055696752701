import { Ref } from "react"
import { AnimatePresence, motion } from "framer-motion"

import useDynamicPanel from "hooks/useDynamicPanel"

import DynamicLink from "components/interactive/DynamicLink"

import { links } from "./links"
// import Keyboard from "components/interactive/Keyboard"
import Trigger from "./Trigger"

import { transition, variants } from "lib/constants/motion"

const Mobile = () => {
  // const { ref, isOpen, toggle, close } = useDynamicPanel()
  const { ref, isOpen, toggle } = useDynamicPanel()

  return (
    <div className="mobile-menu-wrapper relative">
      <Trigger onClick={toggle} />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={ref as Ref<HTMLDivElement>}
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants.fade.in.left}
            transition={{
              ...transition.baseSpring,
              duration: 0.75,
            }}
            className="mobile-menu"
          >
            {links.map((link) => (
              <DynamicLink
                key={link.label}
                href={link.href ?? ""}
                className="menu-action"
              >
                <span>{link.label}</span>
              </DynamicLink>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {/* <Keyboard
        keys={["esc"]}
        onKeyPress={() => close()}
        handleFocusableElements
      /> */}
    </div>
  )
}

export default Mobile
