import { cloneElement, ReactElement, useState } from "react"
import MuiPopover, {
  PopoverProps as MuiPopoverProps,
} from "@mui/material/Popover"
import { styled } from "@mui/material/styles"

interface AnchorProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void
}

const StyledMuiPopover = styled(MuiPopover)(
  () => `
  // Fix mouse leave event being triggered immediately.
  // Reference: https://stackoverflow.com/questions/48782691/onmouseleave-triggers-when-entering-element
  pointer-events: none;

  & .MuiPaper-root {
    border-radius: 8px;
    padding: 10px;
    font-size: 14px;
    line-height: 134%;
    display: flex;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 16px 2px rgba(15, 22, 28, 0.15);
  }
`
)

export interface TooltipProps
  extends Omit<
    MuiPopoverProps,
    "children" | "anchorEl" | "elevation" | "open"
  > {
  openOnHover?: boolean
  when?: boolean
  anchor: ReactElement<AnchorProps>
  content: JSX.Element
}

/**
 * How much space between popover, and the anchor element.
 */
const SPACE_BETWEEN_ANCHOR_PX = 8

const Tooltip = ({
  anchor,
  content,
  openOnHover,
  when: canOpen = true,
  ...props
}: TooltipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const showing = canOpen && Boolean(anchorEl)

  const callOriginal = (
    event: React.MouseEvent<HTMLElement>,
    key: keyof AnchorProps
  ) => {
    const handler = anchor.props[key]
    if (handler) {
      handler(event)
    }
  }

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (openOnHover) {
      setAnchorEl(event.currentTarget)
    }

    callOriginal(event, "onMouseEnter")
  }

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    if (openOnHover) {
      setAnchorEl(null)
    }

    callOriginal(event, "onMouseLeave")
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!openOnHover) {
      const el = Boolean(anchorEl) ? null : event.currentTarget
      setAnchorEl(el)
    }

    callOriginal(event, "onClick")
  }

  const boundAnchor = cloneElement(anchor, {
    onClick: handleClick,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
  })

  return (
    <>
      <StyledMuiPopover
        anchorOrigin={{
          vertical: -SPACE_BETWEEN_ANCHOR_PX,
          horizontal: "center",
        }}
        elevation={0}
        transformOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        anchorEl={anchorEl}
        open={showing}
        {...props}
      >
        {content}
      </StyledMuiPopover>
      {boundAnchor}
    </>
  )
}

export default Tooltip
