import { useEffect, useMemo, useRef, useState } from "react"
import { Box } from "@mui/material"
import classNames from "classnames"
import { pick } from "lodash"

import { useSeamQueryWithoutOrg } from "hooks/useSeamQueryWithoutOrg"
import useSessionStore from "hooks/useSessionStore"

import { usePanels } from "providers/PanelsProvider"

import MenuItem from "components/menus/MenuItem"
import PortalMenu from "components/menus/PortalMenu"

import ChevronUpIcon from "assets/images/chevron-up.svg"

interface Props {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  closeDesktopMenu: () => void
}

const OrgSwitcher = ({ isOpen, onOpen, onClose, closeDesktopMenu }: Props) => {
  const panels = usePanels()
  const triggerRef = useRef<HTMLDivElement>(null)

  const { organization_id, setOrganizationId } = useSessionStore((s) =>
    pick(s, ["organization_id", "setOrganizationId"])
  )

  const { data: organizations } = useSeamQueryWithoutOrg(
    ["organizations", "list"],
    (seam) => seam.organizations.list({})
  )

  const organization = useMemo(() => {
    if (!organizations) return undefined
    return (
      organizations.find(
        (org: any) => org.organization_id === organization_id
      ) || organizations[0]
    )
  }, [organizations, organization_id])

  const [selectedOrganization, setSelectedOrganization] = useState(organization)

  useEffect(() => {
    if (selectedOrganization) {
      setOrganizationId(selectedOrganization.organization_id)
    }
  }, [organization_id, selectedOrganization, setOrganizationId])

  useEffect(() => {
    if (organization && !selectedOrganization) {
      setSelectedOrganization(organization)
    }
  }, [organization, selectedOrganization])

  return (
    <div className="org-switcher">
      <Box
        ref={triggerRef}
        className="trigger"
        onClick={() => {
          onOpen()
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            appearance: "none",
            minWidth: "248px",
            width: "100%",
            height: "2.5rem",
            padding: "0.25rem 0rem 0.25rem 0.75rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            userSelect: "none",
          }}
        >
          <span>
            {typeof organization_id === "undefined"
              ? "No organization yet"
              : selectedOrganization?.name}
          </span>
        </Box>

        <div className="adornment">
          <div className="fader"></div>

          <button
            onClick={() => {
              if (isOpen) {
                onClose()
              } else {
                onOpen()
              }
            }}
          >
            <div
              className={classNames(
                "icon size-6 chevron",
                !isOpen && "rotate-180"
              )}
            >
              <ChevronUpIcon />
            </div>
          </button>
        </div>
      </Box>

      <PortalMenu
        isOpen={isOpen}
        onClose={onClose}
        anchorEl={triggerRef.current}
        verticalOffset={48}
        menuSx={{
          minWidth: 246,
          backgroundColor: "white",
          boxShadow:
            "0px 0px 1px rgba(0, 0, 0, 0.3), 0px 2px 16px 2px rgba(15, 22, 28, 0.15)",
          borderRadius: "0.5rem",
          padding: "0.5rem 0",
          overflowY: "scroll",
          maxHeight: "420px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MenuItem
            onClick={() => {
              panels.open("createOrganizationModal")
              closeDesktopMenu()
            }}
          >
            <span>+ Add new organization</span>
          </MenuItem>

          {organizations &&
            organizations.length > 0 &&
            organizations.map((option) => (
              <MenuItem
                key={option.organization_id}
                onClick={() => {
                  setSelectedOrganization(option)
                  onClose()
                }}
              >
                <span>{option.name}</span>
              </MenuItem>
            ))}
        </Box>
      </PortalMenu>
    </div>
  )
}

export default OrgSwitcher
