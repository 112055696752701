import { Button } from "@mui/material"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import SuccessScreen from "components/modals/AccessPassCreateModal/fragments/SuccessScreen"
import Screen from "components/modals/StackModal/fragments/Screen"

const PassSharedScreen = () => {
  const context = useAccessPassModalContext()

  return (
    <Screen>
      <SuccessScreen content="Access pass sent!" autoRedirect={false} />
      <div className="buttons">
        <Button variant="contained" onClick={context.close}>
          Done
        </Button>
      </div>
    </Screen>
  )
}

export default PassSharedScreen
