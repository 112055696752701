import { useRef } from "react"
import { BsSearch, BsX } from "react-icons/bs"

interface Props {
  context: MultiselectContext
}

interface MultiselectContext {
  value: string
  setValue: (value: string) => void
  selected: string[]
  select: (item: string) => void
  deselect: (item: string) => void
  toggleSelected: (item: string) => void
}

const ChipSelect = ({ context }: Props) => {
  const ref = useRef<HTMLInputElement>(null)

  const focus = () => {
    if (ref.current) {
      ref.current.focus()
    }
  }

  return (
    <div className="chip-select-control" tabIndex={0} onClick={focus}>
      <div className="adornment">
        <i className="icon">
          <BsSearch />
        </i>
      </div>
      <div className="chips">
        {context.selected.map((item) => (
          <div
            key={item}
            className="chip"
            onClick={() => context.toggleSelected(item)}
          >
            <p>{item}</p>
            <button>
              <i className="icon">
                <BsX />
              </i>
            </button>
          </div>
        ))}
        <div className="chip-select">
          <input
            ref={ref}
            type="text"
            placeholder="Search devices"
            value={context.value}
            onChange={(ev) => context.setValue(ev.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default ChipSelect
