import { DEVICE_TYPES } from "lib/constants"

const getRandomIntegerInRange = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const time = (days?: number) => {
  let date = new Date()
  if (days != undefined) {
    date.setDate(date.getDate() + days)
    date.setHours(
      getRandomIntegerInRange(0, 23),
      getRandomIntegerInRange(0, 59)
    )
  }
  return date.toISOString()
}

const uuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const padNumbers = (num: number, size: number) => {
  let numberString = num.toString()
  while (numberString.length < size) numberString = "0" + numberString
  return numberString
}

const BuildingNames: Array<string> = [
  "Azure Apartments",
  "Bay View Lofts",
  "Birchwood Apartments",
  "Sunset Lofts",
  "Castle Apartments",
  "North Beach Apartments",
  "350 Union St Apartments",
  "The Park Apartments",
  "Union Parkside",
  "1355 Euclid Ave",
  "Mission Bay Courtyard",
  "Bridgeview Suites",
  "Infinity Apartments",
  "The Edge",
  "Rockridge Apartments",
  "South End Apartments",
  "Maple Lofts",
  "Oakridge Apartments",
]

export const ExampleBuilding = (index: number) => {
  const buildingName = BuildingNames[index]
  return {
    building_id: uuid(),
    organization_id: "org-1",
    owner_id: uuid(),
    created_at: time(),
    name: buildingName,
    timezone: "America/Los_Angeles",
    ...({
      active_access_pass_count: getRandomIntegerInRange(0, 100),
      device_count: getRandomIntegerInRange(0, 100),
      device_issues_count: 0,
      low_battery_issues_count: 0,
      offline_account_issues_count: 0,
      recent_event_count: getRandomIntegerInRange(0, 20),
      issue_count: 0,
    } as any),
  }
}

export const ExampleBuildingWithIssues = (index: number) => {
  const device_issues_count = Math.floor(Math.random() * 3)
  const low_battery_issues_count = Math.floor(Math.random() * 3)
  const offline_account_issues_count = Math.floor(Math.random() * 3)

  const buildingName = BuildingNames[index]
  return {
    building_id: uuid(),
    organization_id: "org-1",
    owner_id: uuid(),
    created_at: time(),
    name: buildingName,
    timezone: "America/Los_Angeles",
    active_access_pass_count: Math,
    ...({
      active_access_pass_count: getRandomIntegerInRange(0, 100),
      device_count: getRandomIntegerInRange(0, 100),
      device_issues_count,
      low_battery_issues_count,
      offline_account_issues_count,
      recent_event_count: getRandomIntegerInRange(0, 20),
      issue_count:
        device_issues_count +
        low_battery_issues_count +
        offline_account_issues_count,
    } as any),
  }
}

// Create a list of example buildings of length variable `count`
// const createExampleBuildings = (count: number) => {
//   return Array(count)
//     .fill(0)
//     .map((v, i) => {
//       return getRandomIntegerInRange(0, 100) > 90
//         ? ExampleBuildingWithIssues(i)
//         : ExampleBuilding(i)
//     })
// }

export const ExampleAugustDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: `Room ${padNumbers(index - 2, 3)}`,
  linked_account_id: "linked-account-au",
  device_type: DEVICE_TYPES.AUGUST_LOCK,
  created_at: time(),
  properties: {
    battery_level: index == 8 || index == 16 ? 0.2 : 0.9,
    is_powered: false,
    locked: true,
    online: index % 50 != 1,
    august_metadata: {
      _skuNumber: "AUG-041",
    },
  },
  building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 30),
    active_access_pass_count: getRandomIntegerInRange(0, 10),
    issue_count: index,
    offline_account: false,
    recent_event_count: index,
  } as any),
})

export const ExampleDoorKingDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: `Lobby Door ${index + 1} (Callbox)`,
  linked_account_id: "linked-account-dk",
  device_type: DEVICE_TYPES.DOORKING_LOCK,
  created_at: time(),
  properties: {
    battery_level: "none",
    is_powered: true,
    locked: true,
    online: true,
  },
  building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 50),
    active_access_pass_count: getRandomIntegerInRange(0, 40),
    issue_count: index,
    offline_account: false,
    recent_event_count: index,
  } as any),
})

export const ExampleTwoNDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: `Maintenance room`,
  linked_account_id: "linked-account-2n",
  device_type: DEVICE_TYPES.TWON_LOCK,
  created_at: time(),
  properties: {
    battery_level: 0.9,
  },
  building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 30),
    active_access_pass_count: getRandomIntegerInRange(0, 10),
    issue_count: index,
    offline_account: false,
    recent_event_count: index,
  } as any),
})

const SALTO_WALL_READER_NAMES = [
  "Lobby Door (Card Reader)",
  "Gym",
  "Rooftop Lounge",
  "Swimming Pool",
  "Garage",
]

export const ExampleSaltoWallReaderDevice = (
  index: number,
  building_id: string | undefined
) => ({
  device_id: uuid(),
  name: SALTO_WALL_READER_NAMES[index],
  linked_account_id: "linked-account-sa",
  device_type: DEVICE_TYPES.SALTO_LOCK,
  created_at: time(),
  properties: {
    battery_level: "none",
    is_powered: true,
    online: true,
    salto_metadata: {
      lock_type: "wall_reader",
      locked_state: "locked",
    },
  },
  building_id: building_id || ExampleBuildings[0]?.building_id,
  ...({
    access_pass_count: getRandomIntegerInRange(0, 30),
    active_access_pass_count: getRandomIntegerInRange(0, 10),
    issue_count: index,
    offline_account: false,
    recent_event_count: getRandomIntegerInRange(0, 10),
  } as any),
})

export const createExampleDevices = (
  count: number,
  building_id: string | undefined
) => {
  const devices = []
  devices.push(ExampleDoorKingDevice(0, building_id))
  devices.push(ExampleSaltoWallReaderDevice(1, building_id))
  devices.push(ExampleSaltoWallReaderDevice(2, building_id))
  for (let i = 3; i < count; i++) {
    devices.push(ExampleAugustDevice(i, building_id))
  }
  devices.push(ExampleTwoNDevice(count, building_id))
  return devices
}

const ACCESS_PASS_NAMES = [
  "United Plumbing",
  "Emergency Services",
  "Alexandra Aoun",
  "Belle Blue",
  "Callie Class",
  "Dylan Dancer",
  "Ethan Earhorn",
  "USPS Delivery",
]

export const ExampleUpcomingAccessPass = (
  index: number,
  device_id: string,
  building_id: string
) => ({
  access_pass_id: uuid(),
  name: ACCESS_PASS_NAMES[index % ACCESS_PASS_NAMES.length],
  created_at: time(),
  code: "1234",
  type: "timebound",
  device_id: device_id,
  building_id: building_id,
  starts_at: time(1),
  ends_at: time(2),
  ...({
    last_used_at: time(),
    recently_used_count: 1,
    used_count: 0,
  } as any),
})

export const ExampleActiveAccessPass = (
  index: number,
  device_id: string,
  building_id: string
) => ({
  access_pass_id: uuid(),
  name: ACCESS_PASS_NAMES[index % ACCESS_PASS_NAMES.length],
  created_at: time(),
  code: "1234",
  device_id: device_id,
  building_id: building_id,
  type: "timebound",
  starts_at: time(-1),
  ends_at: time(Math.floor(Math.random() * 10) + 1),
  ...{
    last_used_at: time(-1 * Math.floor(Math.random() * 3)),
    recently_used_count: 1,
    used_count: getRandomIntegerInRange(0, 10),
  },
})

export const ExampleExpiredAccessPass = (
  index: number,
  device_id: string,
  building_id: string
) => {
  return {
    access_pass_id: uuid(),
    name: ACCESS_PASS_NAMES[index % ACCESS_PASS_NAMES.length],
    created_at: time(),
    code: "1234",
    device_id: device_id,
    building_id: building_id,
    type: "timebound",
    starts_at: time(-4),
    ends_at: time(-2),
    ...({
      last_used_at: time(getRandomIntegerInRange(-6, -2)),
      recently_used_count: 1,
      used_count: getRandomIntegerInRange(0, 10),
    } as any),
  }
}

export const ExampleAugustAccount = {
  linked_account_id: "linked-account-au",
  organization_id: "org-1",
  login_portal_id: "login-portal-0",
  user_identifier: {
    email: "eahab@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-0",
  created_at: "2021-07-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 846,
  account_type: "august",
  did_complete_first_sync: true,
}

export const ExampleSmartThingsAccount = {
  linked_account_id: "linked-account-st",
  organization_id: "org-1",
  login_portal_id: "login-portal-1",
  user_identifier: {
    email: "joni@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-1",
  created_at: "2021-08-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 0,
  account_type: "smartthings",
  did_complete_first_sync: true,
}

export const ExampleSaltoAccount = {
  linked_account_id: "linked-account-sa",
  organization_id: "org-1",
  login_portal_id: "login-portal-2",
  user_identifier: {
    email: "kelly@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-2",
  created_at: "2021-09-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 34,
  account_type: "doorking",
  did_complete_first_sync: true,
}

export const ExampleDoorKingAccount = {
  linked_account_id: "linked-account-dk",
  organization_id: "org-1",
  login_portal_id: "login-portal-3",
  user_identifier: {
    email: "kelly@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-3",
  created_at: "2021-09-02T00:00:00Z",
  errors: [
    {
      message: "account_disconnected",
      error_code: "account_disconnected",
      created_at: time(),
    },
  ],
  warnings: [],
  device_count: 17,
  account_type: "doorking",
  did_complete_first_sync: true,
}

export const Example2NAccount = {
  linked_account_id: "linked-account-2n",
  organization_id: "org-1",
  login_portal_id: "login-portal-0",
  user_identifier: {
    email: "2n@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-0",
  created_at: "2021-07-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 846,
  account_type: "2n",
  did_complete_first_sync: true,
}

export const ExampleSchlageAccount = {
  linked_account_id: "linked-account-schlage",
  organization_id: "org-1",
  login_portal_id: "login-portal-0",
  user_identifier: {
    email: "schlage@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-0",
  created_at: "2021-07-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 17,
  account_type: "schlage",
  did_complete_first_sync: true,
}

export const ExampleYaleAccount = {
  linked_account_id: "linked-account-yale",
  organization_id: "org-1",
  login_portal_id: "login-portal-0",
  user_identifier: {
    email: "yale@example.com",
  },
  ext_seam_connected_account_id: "ext-seam-connected-account-0",
  created_at: "2021-07-01T00:00:00Z",
  errors: [],
  warnings: [],
  device_count: 17,
  account_type: "yale_access",
  did_complete_first_sync: true,
}

export const ExampleLinkedAccounts = [
  ExampleAugustAccount,
  ExampleSmartThingsAccount,
  ExampleSaltoAccount,
  ExampleDoorKingAccount,
  Example2NAccount,
  ExampleYaleAccount,
  ExampleSchlageAccount,
]

export const ExampleBuildings = [
  {
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "2cc697a3-2a0a-4675-9956-c4e0fff899ab",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Azure Apartments",
    timezone: "America/Los_Angeles",
    location: {
      latitude: 37.758282,
      longitude: -122.410622,
      addr1: "123 Main St",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      zip_code: "94105",
      full_address_string: "123 Main St, San Francisco, CA 94105",
      country: "US",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "e5cc39d8-855e-4922-b2ff-b7a2ced3c836",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Bay View Lofts",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "124 16th Ave, San Francisco, CA 94122",
      latitude: 37.76166,
      longitude: -122.40935,
      addr1: "124 16th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "6ed5fab9-98ab-4fe3-9b36-085ab535701d",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Birchwood Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "125 17th Ave, San Francisco, CA 94122",
      latitude: 37.763161,
      longitude: -122.415161,
      addr1: "125 17th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "4324d62f-fca1-4a4c-8ac3-e52d8f7e1fdf",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Sunset Lofts",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "126 18th Ave, San Francisco, CA 94122",
      latitude: 37.768539,
      longitude: -122.412979,
      addr1: "126 18th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "9b6f6d96-fc65-4090-b152-cc642bcad1df",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Castle Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "127 19th Ave, San Francisco, CA 94122",
      latitude: 37.746712,
      longitude: -122.422401,
      addr1: "127 19th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "85a335a9-b4d8-4a0a-bf30-7bca59245bdb",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "North Beach Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "128 20th Ave, San Francisco, CA 94122",
      latitude: 37.7509899,
      longitude: -122.4182186,
      addr1: "128 20th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "dd631142-8632-4505-86de-946a5c0ad5e4",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "350 Union St Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "129 21st Ave, San Francisco, CA 94122",
      latitude: 37.7425957,
      longitude: -122.4264613,
      addr1: "129 21st Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "e56f91f2-b0eb-49f8-aabc-2a59833633dc",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "The Park Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "130 22nd Ave, San Francisco, CA 94122",
      latitude: 37.7608915,
      longitude: -122.4466,
      addr1: "130 22nd Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "79af708a-0cb1-4680-bf0e-641a8213c702",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Union Parkside",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "131 23rd Ave, San Francisco, CA 94122",
      latitude: 37.7643981,
      longitude: -122.4383671,
      addr1: "131 23rd Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "27f48c95-9f54-4e04-b97a-93b92e28b4c7",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "1355 Euclid Ave",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "132 24th Ave, San Francisco, CA 94122",
      latitude: 37.7698246,
      longitude: -122.4660867,
      addr1: "132 24th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "f5e34cad-de6b-4d04-87e4-afeef242ce8c",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Mission Bay Courtyard",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "133 25th Ave, San Francisco, CA 94122",
      latitude: 37.7463573,
      longitude: -122.4802386,
      addr1: "133 25th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJN1t_tDeuEmsRUsoyG83frY4",
    },
  },
  {
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "94857520-1bea-42be-a8a5-cd75961ccf31",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Bridgeview Suites",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "134 26th Ave, San Francisco, CA 94122",
      latitude: 37.7245167,
      longitude: -122.4800002,
      addr1: "134 26th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "21bbe2e7-2653-4051-9a6f-b45bb3971536",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Infinity Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "135 27th Ave, San Francisco, CA 94122",
      latitude: 37.7802891,
      longitude: -122.4159728,
      addr1: "135 27th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq9Z1Z1KAh",
    },
  },
  {
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "3e716de0-603e-4710-9f4a-056a93b08a00",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "The Edge",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "136 28th Ave, San Francisco, CA 94122",
      latitude: 37.7898335,
      longitude: -122.4044682,
      addr1: "136 28th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq9Z4Zj1-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "d10f03c4-b628-4895-8be2-586493bfe4f2",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Rockridge Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "137 29th Ave, San Francisco, CA 94122",
      latitude: 37.7934198,
      longitude: -122.399472,
      addr1: "137 29th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "72aee6c9-93ac-4bf5-a337-84de120d0e86",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "South End Apartments",
    timezone: "America/Los_Angeles",
    location: {
      full_address_string: "138 30th Ave, San Francisco, CA 94122",
      latitude: 37.7976525,
      longitude: -122.4066167,
      addr1: "138 30th Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
  {
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    organization_id: "org-1",
    image_url: "/building.jpg",
    owner_id: "9748df89-1ce7-4959-90c2-b0bba3c7a3a6",
    created_at: "2022-12-09T00:52:24.330Z",
    name: "Maple Lofts",
    timezone: "America/Los_Angeles",
    active_access_pass_count: 30,
    location: {
      full_address_string: "139 31st Ave, San Francisco, CA 94122",
      latitude: 37.794636,
      longitude: -122.411491,
      addr1: "139 31st Ave",
      addr2: null,
      city: "San Francisco",
      state: "CA",
      country: "US",
      zip_code: "94122",
      ext_google_place_id: "ChIJq6Z4Zj9-j4AR4Z1Z1Z1Z1Z1Z",
    },
  },
]

const make2nDevice = (
  building_id: string,
  name: string = "Lobby rear door"
) => ({
  device_id: "388872ca-4ffa-4e45-a06e-7038914f8f39",
  name,
  linked_account_id: "linked-account-2n",
  device_type: "2n_lock",
  created_at: "2022-12-18T04:35:20.737Z",
  properties: {
    battery_level: 0.9,
    is_powered: false,
    locked: true,
    online: true,
  },
  building_id,
  access_pass_count: 2,
  active_access_pass_count: 1,
  issue_count: 6,
  offline_account: false,
  recent_event_count: 6,
})

const makeSchlageDevice = (
  building_id: string,
  name: string = "Conference Room"
) => ({
  device_id: "388872ca-4ffa-4e45-a06e-7038914f8f38",
  name,
  linked_account_id: "linked-account-schlage",
  device_type: "schlage_lock",
  created_at: "2022-12-18T04:35:20.737Z",
  properties: {
    battery_level: 0.9,
    is_powered: false,
    locked: true,
    online: true,
  },
  building_id,
  access_pass_count: 2,
  active_access_pass_count: 1,
  issue_count: 6,
  offline_account: false,
  recent_event_count: 6,
})

const makeYaleDevice = (
  building_id: string,
  name: string = "Breakfast Room"
) => ({
  device_id: "388872ca-4ffa-4e45-a06e-7038814f8f38",
  name,
  linked_account_id: "linked-account-yale",
  device_type: "yale_lock",
  created_at: "2022-12-18T04:35:20.737Z",
  properties: {
    battery_level: 0.9,
    is_powered: false,
    locked: true,
    online: true,
  },
  building_id,
  access_pass_count: 2,
  active_access_pass_count: 1,
  issue_count: 6,
  offline_account: false,
  recent_event_count: 6,
})

export const ExampleDevices = [
  make2nDevice("e1741d59-61df-418e-a458-56cc1429b242"),
  make2nDevice("2f072a89-f29a-4fe7-a43d-3fae0f09ab31"),
  make2nDevice("28511b9f-db2a-4b53-86cf-529a02f996b0"),
  make2nDevice("421679a8-71cf-4dd5-8e31-09bbc4e1b1ec"),
  make2nDevice("b6ab1921-9ad9-4b6a-bafd-1a0b27746adf"),
  make2nDevice("d4c68b97-cf0f-43b0-a3f7-31a369155ed9"),
  make2nDevice("241a0ce1-f0cf-4027-bc11-dd979fdfea78"),
  make2nDevice("69f1ce59-daad-4775-a530-62cfe4a64c8b"),
  make2nDevice("27816c08-658c-4c86-83ee-fde8a65ac669"),
  make2nDevice("fe38801c-b4c0-4e11-8195-e6f96bdac092"),
  make2nDevice("227daf76-35d8-42d7-9068-14ef0c4a056b"),
  make2nDevice("8ad13286-17c9-48bd-a60f-ed6be3ecf057"),
  make2nDevice("c9450aa1-276f-496e-b333-dea0a6b2fee6"),
  make2nDevice("2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5"),
  make2nDevice("80605ae7-9cb0-4015-8481-daea1916de00"),
  make2nDevice("0570bd0f-00c4-4cd3-918a-15f6167ba81e"),
  make2nDevice("57051d8d-c75e-48ca-8151-f73d7277db22"),

  makeSchlageDevice("e1741d59-61df-418e-a458-56cc1429b242"),
  makeSchlageDevice("2f072a89-f29a-4fe7-a43d-3fae0f09ab31"),
  makeSchlageDevice("28511b9f-db2a-4b53-86cf-529a02f996b0"),
  makeSchlageDevice("421679a8-71cf-4dd5-8e31-09bbc4e1b1ec"),
  makeSchlageDevice("b6ab1921-9ad9-4b6a-bafd-1a0b27746adf"),
  makeSchlageDevice("d4c68b97-cf0f-43b0-a3f7-31a369155ed9"),
  makeSchlageDevice("241a0ce1-f0cf-4027-bc11-dd979fdfea78"),
  makeSchlageDevice("69f1ce59-daad-4775-a530-62cfe4a64c8b"),
  makeSchlageDevice("27816c08-658c-4c86-83ee-fde8a65ac669"),
  makeSchlageDevice("fe38801c-b4c0-4e11-8195-e6f96bdac092"),
  makeSchlageDevice("227daf76-35d8-42d7-9068-14ef0c4a056b"),
  makeSchlageDevice("8ad13286-17c9-48bd-a60f-ed6be3ecf057"),
  makeSchlageDevice("c9450aa1-276f-496e-b333-dea0a6b2fee6"),
  makeSchlageDevice("2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5"),
  makeSchlageDevice("80605ae7-9cb0-4015-8481-daea1916de00"),
  makeSchlageDevice("0570bd0f-00c4-4cd3-918a-15f6167ba81e"),
  makeSchlageDevice("57051d8d-c75e-48ca-8151-f73d7277db22"),

  makeYaleDevice("e1741d59-61df-418e-a458-56cc1429b242"),
  makeYaleDevice("2f072a89-f29a-4fe7-a43d-3fae0f09ab31"),
  makeYaleDevice("28511b9f-db2a-4b53-86cf-529a02f996b0"),
  makeYaleDevice("421679a8-71cf-4dd5-8e31-09bbc4e1b1ec"),
  makeYaleDevice("b6ab1921-9ad9-4b6a-bafd-1a0b27746adf"),
  makeYaleDevice("d4c68b97-cf0f-43b0-a3f7-31a369155ed9"),
  makeYaleDevice("241a0ce1-f0cf-4027-bc11-dd979fdfea78"),
  makeYaleDevice("69f1ce59-daad-4775-a530-62cfe4a64c8b"),
  makeYaleDevice("27816c08-658c-4c86-83ee-fde8a65ac669"),
  makeYaleDevice("fe38801c-b4c0-4e11-8195-e6f96bdac092"),
  makeYaleDevice("227daf76-35d8-42d7-9068-14ef0c4a056b"),
  makeYaleDevice("8ad13286-17c9-48bd-a60f-ed6be3ecf057"),
  makeYaleDevice("c9450aa1-276f-496e-b333-dea0a6b2fee6"),
  makeYaleDevice("2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5"),
  makeYaleDevice("80605ae7-9cb0-4015-8481-daea1916de00"),
  makeYaleDevice("0570bd0f-00c4-4cd3-918a-15f6167ba81e"),
  makeYaleDevice("57051d8d-c75e-48ca-8151-f73d7277db22"),

  {
    device_id: "288872ca-4ffa-4e45-a06e-7038914f8f39",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: null,
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "c186f8a7-01fe-406c-83c5-6598abf16ef3",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: null,
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc3",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: null,
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "2dfaf3c6-9a52-46f8-9319-7a9035578f2d",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "c2e5f00f-9efd-4661-af5a-b7e2e6317425",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "79f6bf35-bca7-4c9c-99d0-0e82f188495c",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "a96d1a6a-cf4c-4c62-a62a-110443e819e6",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "250d9ff4-d3fe-4a64-8d53-49b2206add5e",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "8c76a1f2-1583-4535-8fc9-8f04a6f45144",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "288872ca-4ffa-4e45-a06e-7018914f8f39",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "c186f8a7-01fe-406c-83c5-6598abf16ef9",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc2",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "402fd3cc-4fe8-4638-9b45-7fad61d7103d",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "056a79d3-1767-416a-bb92-acb8e1fda027",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "546a274f-56b1-4bef-a593-debb0cf3080f",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "d01494c7-9826-4e93-8217-70855e451f09",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "bdfeb5ee-b4e2-4f69-a3b7-0ee8d0e969b3",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.737Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "75718afb-5a3b-4bee-9bdb-7b6f522b5c95",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "ba5869c3-50c9-49a4-a58e-4ae41e8bd25a",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "785587c8-13e1-4b83-84ba-fe1528ec8cff",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "ef3b716d-4fb0-4ead-b29b-6a791098fb00",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "77f4387b-802d-48fb-a948-db8f0c33b881",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "5d576095-9581-46c4-a096-7d84e9b6247b",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "15653865-7574-4b23-a41f-434a08ed8858",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "7a493849-6b15-401f-83c2-c8e9b9f406b3",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "f8f50725-6288-403c-bc6e-62fbf885319c",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "62ee31bf-6288-49a3-92d1-b26f12d44f4d",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "a83fb95a-684b-4f72-a6a8-dcc751b69be3",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "c0c823e3-1b60-4a64-aef0-6b507f68d524",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "2ffc2c08-dcca-43ef-b470-a8fa05e62f1d",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "53c89473-a264-43f3-a965-dc1bec8d1eb2",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "d7ebd09d-1d65-4c61-8014-cd01b70ab46b",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "45cec35b-aae0-4ff2-9d93-c9e6663dc42f",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "a4d6c940-85fb-4b3d-be5e-ddeeae56638f",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "9e7d66ca-386f-46fd-a950-d3366ff6cc3b",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "77600596-d1da-43f5-812e-708276ee5059",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "355141e0-dded-4132-90dc-ffa255e27a62",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "14dbd035-db38-42b7-aa36-b6d381e36832",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "9d6c78eb-d96b-48f7-b20e-307f6d6c513d",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "e55ecbba-d710-494d-b374-f8025e182629",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "27af4334-d80c-4d7e-ab7a-f6fae876c34c",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "37af5d3e-f761-45b7-8029-eaa2778c7590",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "1c29ea9c-01ec-4618-8788-2939dd728997",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "05670828-70e8-4f06-befc-91940df97473",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "a6b40b93-dbe8-4ef4-8457-bf7a65ea17fe",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "2c22d141-2f00-4b84-bcf8-7d31180e6f52",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "bcd24cf7-c036-4432-919a-01e4563dc326",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "da836d6d-1d26-4865-a8e5-9701acd2eaaa",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "bee96acd-ec99-4ba8-885b-d218d36b5a7b",
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "925f0520-8deb-4757-923a-36eedeb9e99f",
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "a1ad2f2e-cc8f-4aa0-8492-61337e9bf0ae",
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.738Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "ab132fbf-a617-43a5-84e8-99908f0cf7f2",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "a09adb82-d319-4636-acc1-1d8893b4830c",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "4db91d63-73c7-442e-905f-b4bd6df81a7e",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "6f5aafc1-48cc-474c-ad88-ca28e35888cf",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "b1fa1bc6-8ee5-42dc-89cb-15f4054ebed0",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "e394c2c0-ef1d-4856-b491-71535ed5b75d",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "5ab5bfc6-b973-4adb-8a7b-4520e8e27ccc",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "969f6404-58d7-4895-9a02-6d30965207da",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "1849c4e2-ca94-4811-9c2a-91607ff1382a",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "e93e275c-a64c-4041-a084-2d27ff29a242",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "89355cac-a34d-42c7-a4f0-01247bdba256",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "39b0aa27-384f-46c6-a25d-602034c16c24",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "049b3789-d62f-4c52-9296-a900ba6b4174",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "5d9c7599-8aa9-403c-96a5-b5f679e65a88",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "c96892a6-5fdb-4c51-a036-202c2e3995ae",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "9c4634c1-f0db-43be-b8da-6600b912d98b",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "f874b5e1-9fba-4b6d-9310-a98c8b8508cf",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "fd1edbbc-a9bd-4548-8504-ef4cad517012",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "c74fda4d-02cb-45e7-89ff-6ca7cc4af174",
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "36ff22d3-a549-4b54-b949-d5e04d3dcfa6",
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "50d44916-d98d-4ed9-a97a-b0ceacb14fc4",
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "02960793-587c-4942-8df6-f65e5f0333f9",
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "021b8d59-3f3c-48b6-95a5-fe1145f6a564",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "126a72f8-91f3-46d4-973c-4f300cd20267",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "f07d92d4-3a8b-43e8-838d-1e3cc446bb95",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "592e67b4-ceb2-44b6-bf51-7274da8c5a5b",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "d0895650-344b-4f90-92db-b80292c07bcd",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 5,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "4bf5006c-6cb1-41bb-bf9c-acae79ddda8f",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 5,
    active_access_pass_count: 4,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "a270b053-dcb1-4680-a0f7-8a007e224222",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 3,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "3da6ebc1-a85b-4746-84c7-4cb0c8667f18",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "b7fe0a5c-3bd8-4897-824a-760d61a49abc",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "7d64e870-951b-41c7-ab36-4611e18c9eb0",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "f5f61988-46f5-458a-9358-b321580c3ddd",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "0229110d-bddf-4d55-9365-856c3ad48caa",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "7a938950-80a6-401f-99cf-b51ec0e4ebbd",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "07d9b176-e3e3-41f0-a4cb-6c93649bdfc2",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "848f126e-875e-46f2-a8f7-ae1a97ff8630",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "d75911e5-5985-4df0-9c69-03cd9c66db92",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "88d847e0-8a02-4db9-a8bc-e3aa1c4ed95e",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "63ccc55c-1876-4ec0-b589-fa49342717f7",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "5b0502d9-0c74-4ba3-a153-49403b831d6b",
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "02e51fe2-9a13-45c5-8859-a7edf49bdcfc",
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "c0e60057-a3dc-4b1e-b824-8c04f14ecbac",
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    access_pass_count: 4,
    active_access_pass_count: 4,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "2732820f-6453-4f5d-9104-6b688a4b58fd",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "1cd7eeec-ab58-4e9b-9135-1aea8fb4070a",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "0abd616b-ecb2-4061-88d3-dcfafb70552f",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "ca4163cc-98eb-4cb4-86c6-0cb49eef5669",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "28b52aa9-e816-48fa-acae-4b38f83cae8f",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "ad43cf60-994b-4074-9c6b-7732972585bf",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "d260a125-5963-4140-a348-a57755a7f2bc",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "3bedf53c-6d6c-4348-b5ba-85663c764195",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "e18ec69d-44e2-4fa8-bfcb-2210a6720fe0",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.2,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "e893dae2-451f-4d07-9928-49d82dd0de91",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "e8adecec-182a-4231-9f25-1e044ccfe5f4",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "5a932c81-0af3-4cfd-ad6c-f6c3bee0539a",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "470ac0d4-05c7-4242-aa81-c8096e493f97",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "44f1355e-d504-42e0-8299-84542992b1e3",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "0b2e6569-2bb9-404a-980e-cb9896567ba0",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "1c22561f-55a3-4ec7-9cb9-3bd9f5a210c8",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "473f9293-07bf-44b8-8f47-5a12b239f00c",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "b33934c6-67b9-4389-a8de-ea1164e1d660",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "5b1a70ab-5840-433a-9030-4b5a470c5983",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "97618635-0731-4511-93c1-ad3119e8e97c",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "ef2f8317-8535-455e-9aa9-5259e6446ce8",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "d74c3102-164a-42cb-a2bc-659a68060fa0",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "a18d6395-07ce-43f1-a5ae-14589b12012c",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "9385851d-dc9d-4462-9a72-a1249840030c",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "6a5c7e30-1550-45b3-b66f-2fbd96bdee3c",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "4ccb88a8-c7ac-495f-9a29-63473a4f9796",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.739Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "527f1d88-7ddf-4c7f-94ab-fac8ec3d4ac1",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "d6cda425-a0fd-4ac4-b4f7-22270fd2d86d",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "98c2b2e0-237e-41f2-8d37-bd08b95ff016",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "f9cffd3e-f72e-40c6-9e64-e969f819f501",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "8c74a765-0b50-4247-b76b-731c95f52041",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "9ac88ab0-149f-4fcf-91da-b31602b6fd7f",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "07a31679-a12f-46c4-9f23-a968db92b1b9",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "85b9a31e-80bd-4bd0-b44b-8790d49c7c80",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "d3fa96b2-6066-48c1-942e-4d46703ffe9b",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "d836356d-c6cd-46ef-94ff-82b49bb44c1d",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "68cbb570-8bfe-4a20-b6ac-147159301424",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "09f4dd1b-ffda-4e07-b1b8-da3844ad1f5d",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "2c90cb1b-536b-404b-a2b9-646cd75fba13",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "70c00223-7f1b-4046-8fe9-67d1eac97a61",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "f7256fbb-919f-4dc6-b394-2df1dd99153d",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    access_pass_count: 15,
    active_access_pass_count: 14,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    access_pass_count: 15,
    active_access_pass_count: 15,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    access_pass_count: 15,
    active_access_pass_count: 14,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "652e074b-91f1-4865-8cf1-e80cf2ad9e0b",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "e553d62a-4ab0-4d02-b438-ce91a2810b53",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "8b6c751c-bd53-42d7-8abf-6820b934032a",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "7495ff13-1209-4f37-8477-bddb08ab8eb4",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "9d49e8fb-0770-453b-bfc1-044acb4aea43",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "0a1d5a4a-8d01-4f8a-b38e-1e04d670a8d3",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "aac78e9f-9532-4eb9-bee8-d7085781f65e",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "5b48dd67-ade8-44d3-898b-fdbcf8fb98f9",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "3e3ac0ca-3422-4e39-ab1e-5067e001677f",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "9fef663b-17d8-4cd9-bcb6-4087a042cddb",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "5672e41e-34be-4a2a-bdcd-4350fce4ce08",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "5b8612ea-2053-4d6f-a3e2-ddd09b824a5f",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "f233d23b-4dd2-4806-9897-ad545a4cdaf1",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "19c04cd0-72da-4555-b12f-5b28bf1b4e86",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "01f97216-fe02-4bab-9ad3-5471f61c8dc2",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "aaa189fb-6774-488f-84e4-1da3b9efd7b1",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "654b3a0e-9b99-4580-98ab-64bc82fde1dd",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "edbe997b-21b7-4567-84b8-b55beae1235c",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "fc3e3e00-5cb5-477c-93b1-ceb78a9b779c",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "6e27fff4-9b76-461d-96f5-bb17f59d52d0",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "4e8f45a1-a1b4-4016-9e03-a740537aaa48",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "0e21dd78-7473-42cf-b9a5-264d29d07590",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "6be35199-e977-43da-9c1b-25c1a880bef3",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "242095bc-c82a-4028-8a31-9f75a03b795d",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "c03a1640-9187-4760-bb1c-f623cdf0aaee",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "274d6ab3-b780-477f-9884-52bcba87165f",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "0594c332-5bf8-42f9-98db-e445813276a1",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "4000aead-a6e6-4e37-b421-3456093ea203",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "0ef5b8a5-cc72-4776-b464-c79111540c77",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "0ef9c7d4-7c53-4dad-99d6-17a6ef2d7ec8",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "2c75480b-5842-43b2-9089-c2d3d2bf1f90",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "edc8adee-329c-49b4-abad-14355bdbd40b",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "6fce444d-d86d-4f59-8601-a7ee1b2608bc",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "1efb6f7b-6e3c-43e2-8446-0f1cd34a0db1",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "790de092-8303-44f3-9397-2874b3a2dfe8",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "4fae1dd1-d131-4cda-9dcf-0af6054ff1cc",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 1,
  },
  {
    device_id: "cd968a6b-b531-4caa-884b-e414ce2be398",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "ea125a83-0f8b-42de-8dca-af9f844504d7",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "c1753a8b-d22c-4fb3-98e7-6935ee4f3511",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "18c53769-4c36-4871-a062-91e36ff026e0",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "e3cf8d32-e511-49bc-81d9-d17257c9689f",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "ce291b4b-5183-43d5-a23c-c2c9dd49d204",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "73d80b6d-52bb-4ce4-8153-3016795afbb1",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "074237ee-5fc9-407a-84c9-560f683e906e",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "aadd6c5f-18e8-43fe-b67d-88be7c451fa1",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "24109c89-e85b-4142-80e3-14625beaa219",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "640c9489-65ea-4c9e-b085-d5a1f187d15b",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "a1be7d36-d1d5-4de3-abce-06db9bf754ee",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "340d4d9f-9664-4240-bb03-47336e5c5408",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "a69420df-4a6f-4054-af8e-47d5fcaf4c83",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "8682c06d-ab4e-4822-90ed-1b687c6caf9e",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "4e2a3d77-5f59-4b80-9613-8baf74e0d949",
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "2f3ecdf6-4d61-481b-8d00-19edbe31d319",
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "abf056e2-bf14-4242-84f6-a16c1108a6b8",
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "74c4ffc4-23a3-400e-9277-40e27cf51917",
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "4d930b36-242f-4686-8ccc-1282c49f9f36",
    name: "Room 020",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 22,
    offline_account: false,
    recent_event_count: 22,
  },
  {
    device_id: "040cde3c-f150-460b-b45c-db17ec1b46ee",
    name: "Room 021",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 23,
    offline_account: false,
    recent_event_count: 23,
  },
  {
    device_id: "bb629ade-d23c-46be-abd9-227a95a705a8",
    name: "Room 022",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 24,
    offline_account: false,
    recent_event_count: 24,
  },
  {
    device_id: "8cd75a85-ed1e-415f-bfa1-e58e261695cb",
    name: "Room 023",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 25,
    offline_account: false,
    recent_event_count: 25,
  },
  {
    device_id: "bebb0024-89bc-4b5f-b188-25b93da73dd8",
    name: "Room 024",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 26,
    offline_account: false,
    recent_event_count: 26,
  },
  {
    device_id: "a869379f-65b8-4d0a-a169-7f076f8324f6",
    name: "Room 025",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 27,
    offline_account: false,
    recent_event_count: 27,
  },
  {
    device_id: "f8bdf069-c5a9-45a9-815f-33868ced10d4",
    name: "Room 026",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 28,
    offline_account: false,
    recent_event_count: 28,
  },
  {
    device_id: "f0421162-e471-47dd-af03-394dfdcad2c2",
    name: "Room 027",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 29,
    offline_account: false,
    recent_event_count: 29,
  },
  {
    device_id: "075c2687-469f-4f91-ae62-b0eeec71ce4c",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "fd0e5cf9-741b-4279-aa15-7446588d8418",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "3d166f7b-4f52-4566-a3f8-ae0d64759643",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "2bd99808-3375-4860-b776-efc3d9f991fe",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "03e1a824-1619-431f-9159-9eef34baf7c4",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "d6ecb2de-b8cc-46d6-a380-921387806a6a",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "3c94b133-395d-4127-a649-1efe752cd2d0",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 1,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "109c9712-97b6-4868-801e-ae6d53f01099",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "235afd9c-35d9-4a38-8ce6-0e713337655f",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "8fbc544c-d421-4bae-9e68-47aac1db1eca",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "ea5f6060-09f6-4467-b87a-74863ec710db",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "fe359c52-acf6-4212-afd9-d394364e01d2",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "a308e4ec-4a16-43a8-9b98-b4f78d682aa0",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "f485bbb3-d44b-48b9-aede-853bfc346812",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "7dc729da-f0d7-4e3b-91da-71d3250f55ca",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "7914ea4b-81bd-4129-8d91-7afabafd3312",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "09e98216-96fa-4ccc-8e33-168eec30dc75",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "8418f2e0-3b30-4db3-8814-aa1173df8e3b",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "de36ecc5-d03a-439e-acbb-e5cb6a02398f",
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "483098d8-e0fc-49ea-a42f-39e156229c39",
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "0ed06d38-bba7-441e-8335-05fa5bb6c5d6",
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "c0016ae5-549d-41f5-bd03-f3ead7b70edc",
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "88f3aa22-7789-4efb-b462-6f0d9232933e",
    name: "Room 020",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 22,
    offline_account: false,
    recent_event_count: 22,
  },
  {
    device_id: "07376b2e-7739-4271-96f2-c15981ca8ae4",
    name: "Room 021",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 23,
    offline_account: false,
    recent_event_count: 23,
  },
  {
    device_id: "bf476594-6c69-4973-8abe-d8495c34f64c",
    name: "Room 022",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 24,
    offline_account: false,
    recent_event_count: 24,
  },
  {
    device_id: "368ea69c-e488-4b96-8579-6e4e82ac3096",
    name: "Room 023",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 25,
    offline_account: false,
    recent_event_count: 25,
  },
  {
    device_id: "814826fb-6685-4168-878f-117f1535cc4a",
    name: "Room 024",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 26,
    offline_account: false,
    recent_event_count: 26,
  },
  {
    device_id: "4e456c03-e2b3-4e57-80dc-ed64ef1c5ba2",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "42fa1af4-4447-4d4d-8867-3c836704b7a1",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "13f9a58b-af92-49cd-9a4e-11d67eda4c00",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 2,
  },
  {
    device_id: "55b844b4-6530-4247-b31c-2d63e5adaf8d",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "9cc0bc5a-f9ab-4d37-bc3c-52020f60d035",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "d2448623-5362-42e0-86ba-cc0db7d7bddf",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "8a6b5929-5dbd-4d8f-9822-fd0ab4081ab5",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 2,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "b7be540a-8eb8-494c-ad77-d3b69186e262",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "76696d82-96ba-4d76-8348-577783371845",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 3,
    active_access_pass_count: 3,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "4c1cdd40-48cf-4aaf-b31e-c57cd8145041",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "e1578a4a-9c4b-46c6-aa7c-bca733e640b2",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "6068312f-be23-47c3-b63c-1255ebae1f37",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "ce659449-af98-4d0e-9866-4b9e6f047bd6",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "4a34dac2-85c5-46d9-8a5f-ea150990bdc0",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "5472a410-bbec-426e-9b3c-15653bf936ec",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "b38b285c-80cc-486b-9844-2a81357eb0ae",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "77f3f379-2a1b-49de-9de9-f46741639446",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "f4a39294-0412-40ab-8542-1369b6faa4ab",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "d755b97a-49c3-46e9-be3f-7f2a541459d3",
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "3cbd3a1f-60fb-4176-b751-3ab3ec9692fd",
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "f20d54b9-3736-47ad-a86c-6d44b5f53c6d",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "df9833df-b794-4f2d-a2cd-dc5c5202ac77",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "d3557d28-8fc5-4837-94a4-75c6a1fb8893",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "c96674b3-2e5c-434b-b1f0-ada0dcee8f43",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "35a92636-dc58-4fa2-8e05-bd29b89dc405",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "03d9eb01-d49b-4c41-8d11-02ac8725f7aa",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "07868bc7-0ea5-4e1d-8646-53742a0a29da",
    name: "Room 004",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 6,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "da956da2-9438-470d-a9e6-0d0713870a55",
    name: "Room 005",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 7,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "6d09717a-a56f-4e6a-a95b-3882174c2308",
    name: "Room 006",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 8,
    offline_account: false,
    recent_event_count: 8,
  },
  {
    device_id: "65ce138e-a757-4b74-8f7d-c3756b5f1452",
    name: "Room 007",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 9,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "83ee04e7-196e-4545-993b-def2e5fc109c",
    name: "Room 008",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 10,
    offline_account: false,
    recent_event_count: 10,
  },
  {
    device_id: "7db83a9f-6afe-419f-9d09-b04ecfb62ac8",
    name: "Room 009",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 11,
    offline_account: false,
    recent_event_count: 11,
  },
  {
    device_id: "270884a5-9433-4451-87b1-74bc970c398f",
    name: "Room 010",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 12,
    offline_account: false,
    recent_event_count: 12,
  },
  {
    device_id: "80f959d7-5fc0-4f46-81c5-60ea8c6d6945",
    name: "Room 011",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 13,
    offline_account: false,
    recent_event_count: 13,
  },
  {
    device_id: "2546d809-e763-4259-9a62-2035754aa5bd",
    name: "Room 012",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 14,
    offline_account: false,
    recent_event_count: 14,
  },
  {
    device_id: "a7711c02-1468-4f23-8aa1-b59970b93c3f",
    name: "Room 013",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 15,
    offline_account: false,
    recent_event_count: 15,
  },
  {
    device_id: "ea182511-3f9d-494e-b400-47c631e92c46",
    name: "Room 014",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 16,
    offline_account: false,
    recent_event_count: 16,
  },
  {
    device_id: "0896c51b-b3ff-48c3-b12a-c2936eb777fa",
    name: "Room 015",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 17,
    offline_account: false,
    recent_event_count: 17,
  },
  {
    device_id: "9b2567d8-61bf-4b62-9196-6de04a073cfd",
    name: "Room 016",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 18,
    offline_account: false,
    recent_event_count: 18,
  },
  {
    device_id: "cb619490-368b-4ea5-aa65-585fafc1527c",
    name: "Room 017",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 19,
    offline_account: false,
    recent_event_count: 19,
  },
  {
    device_id: "5a4ad751-bb60-40d3-a7f3-c95151f547f8",
    name: "Room 018",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 20,
    offline_account: false,
    recent_event_count: 20,
  },
  {
    device_id: "69ddd073-8930-46e7-8f9f-cdaa8d3316b9",
    name: "Room 019",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 21,
    offline_account: false,
    recent_event_count: 21,
  },
  {
    device_id: "d3337a1a-efdf-4a8d-9a8f-07ce5b4875a4",
    name: "Room 020",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 22,
    offline_account: false,
    recent_event_count: 22,
  },
  {
    device_id: "ce97b839-44d0-4a0c-ae7f-bc10d4b0c0b5",
    name: "Room 021",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 23,
    offline_account: false,
    recent_event_count: 23,
  },
  {
    device_id: "08918e94-c495-450a-9bf1-7ada81a47be6",
    name: "Room 022",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 24,
    offline_account: false,
    recent_event_count: 24,
  },
  {
    device_id: "142c6141-589c-4c56-830b-6d8ea2d64716",
    name: "Room 023",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 25,
    offline_account: false,
    recent_event_count: 25,
  },
  {
    device_id: "c093e2e9-739c-4d5f-beb5-c6926abfab5d",
    name: "Room 024",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 26,
    offline_account: false,
    recent_event_count: 26,
  },
  {
    device_id: "02e48050-d375-4018-9a76-d186468d6059",
    name: "Room 025",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 27,
    offline_account: false,
    recent_event_count: 27,
  },
  {
    device_id: "d3a061d0-30ec-47ef-b824-2c2507c70850",
    name: "Room 026",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 28,
    offline_account: false,
    recent_event_count: 28,
  },
  {
    device_id: "b0ee301c-ff46-4a27-b21b-d6d28ef22b54",
    name: "Room 027",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 29,
    offline_account: false,
    recent_event_count: 29,
  },
  {
    device_id: "751cc2c8-daf4-4f44-8e2a-0f0ed6e405d6",
    name: "Room 028",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 30,
    offline_account: false,
    recent_event_count: 30,
  },
  {
    device_id: "fc0d6b4d-ee54-4144-b171-a6c381f31dd7",
    name: "Room 029",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    access_pass_count: 0,
    active_access_pass_count: 0,
    issue_count: 31,
    offline_account: false,
    recent_event_count: 31,
  },
  {
    device_id: "577ee38b-1753-496c-824e-07662ded837d",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "0d0be591-e219-4797-9e16-510d67847f63",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "ba820a05-b71d-4fda-9512-0a0a4965d642",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: "none",
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 6,
  },
  {
    device_id: "8edf9164-6062-4d54-bee6-ded3209f40ad",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "1bb48879-b4ad-4bc8-b097-adacd2d205c5",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 1,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
  {
    device_id: "e17b7dd1-83d9-4e20-a0f8-e033c5808b2c",
    name: "Room 003",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    access_pass_count: 1,
    active_access_pass_count: 0,
    issue_count: 5,
    offline_account: false,
    recent_event_count: 5,
  },
  {
    device_id: "aed06ec9-790e-46e8-9c57-e4952cd06f10",
    name: "Lobby Door 1 (Callbox)",
    linked_account_id: "linked-account-dk",
    device_type: "doorking_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      locked: true,
      online: true,
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 0,
    offline_account: false,
    recent_event_count: 0,
  },
  {
    device_id: "20b1797a-ce4b-4fb6-b53e-8a1261e820ed",
    name: "Gym",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 1,
    offline_account: false,
    recent_event_count: 7,
  },
  {
    device_id: "8f49b3d4-b89d-4edc-a42e-400f733d80ea",
    name: "Rooftop Lounge",
    linked_account_id: "linked-account-sa",
    device_type: "salto_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: true,
      online: true,
      salto_metadata: {
        lock_type: "wall_reader",
        locked_state: "locked",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 2,
    offline_account: false,
    recent_event_count: 9,
  },
  {
    device_id: "4abd9b83-43da-440b-874d-1ef6999ac1a0",
    name: "Room 001",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 3,
    offline_account: false,
    recent_event_count: 3,
  },
  {
    device_id: "7fc8811a-bad4-4a04-b169-6bc0d39df06d",
    name: "Room 002",
    linked_account_id: "linked-account-au",
    device_type: "august_lock",
    created_at: "2022-12-18T04:35:20.740Z",
    properties: {
      battery_level: 0.9,
      is_powered: false,
      locked: true,
      online: true,
      august_metadata: {
        _skuNumber: "AUG-041",
      },
    },
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    access_pass_count: 2,
    active_access_pass_count: 2,
    issue_count: 4,
    offline_account: false,
    recent_event_count: 4,
  },
]

// ExampleDevices.forEach((d, i) => {
//   const building = ExampleBuildings.find((b) => b.building_id === d.building_id)
//   if (building?.issue_count == 0) {
//     ExampleDevices[i].properties.battery_level = 0.9
//   }
// })
// console.log(ExampleDevices)

export const createExampleAccessPasses = (
  count: number,
  building_id: string
) => {
  const devices = ExampleDevices.filter((d) => d.building_id === building_id)
  const passes = []
  passes.push(
    ExampleUpcomingAccessPass(
      0,
      devices[0 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleUpcomingAccessPass(
      1,
      devices[1 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleActiveAccessPass(
      2,
      devices[2 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleActiveAccessPass(
      3,
      devices[3 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleActiveAccessPass(
      4,
      devices[4 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleExpiredAccessPass(
      5,
      devices[5 % devices.length].device_id,
      building_id
    )
  )
  passes.push(
    ExampleExpiredAccessPass(
      6,
      devices[6 % devices.length].device_id,
      building_id
    )
  )
  for (let i = 7; i < count; i++) {
    passes.push(
      ExampleActiveAccessPass(
        i,
        devices[i % devices.length].device_id,
        building_id
      )
    )
  }
  return passes
}

// let ExampleAccessCodes = []
// ExampleBuildings.forEach((building) => {
//   ExampleAccessCodes = ExampleAccessCodes.concat(createExampleAccessPasses(
//     (building as any)?.active_access_pass_count,
//     building?.building_id,
//   ))
// })

export const ExampleAccessCodes = [
  {
    access_pass_id: "6dff71fb-6065-469c-aaae-f7b03fb26116",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    type: "timebound",
    device_id: "2dfaf3c6-9a52-46f8-9319-7a9035578f2d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    starts_at: "2022-12-19T05:50:18.231Z",
    ends_at: "2022-12-20T05:50:18.231Z",
    last_used_at: "2022-12-18T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "a6ba23ca-a42e-49ab-b75b-e99eefe19220",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    type: "timebound",
    device_id: "c2e5f00f-9efd-4661-af5a-b7e2e6317425",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    starts_at: "2022-12-19T05:50:18.231Z",
    ends_at: "2022-12-20T05:50:18.231Z",
    last_used_at: "2022-12-18T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "4e1ddf8d-a5dd-4436-b0e9-5229a687cf3f",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "79f6bf35-bca7-4c9c-99d0-0e82f188495c",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-27T05:50:18.231Z",
    last_used_at: "2022-12-18T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "4141ea90-c49c-4937-a363-47fd99e6ae71",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "a96d1a6a-cf4c-4c62-a62a-110443e819e6",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-24T05:50:18.231Z",
    last_used_at: "2022-12-18T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "ee75a2d7-91cb-4354-a7a6-3dab4f90104b",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "250d9ff4-d3fe-4a64-8d53-49b2206add5e",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-27T05:50:18.231Z",
    last_used_at: "2022-12-17T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "c9b8d7d8-477c-40a7-8f6f-648c7d4b9724",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "8c76a1f2-1583-4535-8fc9-8f04a6f45144",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.231Z",
    ends_at: "2022-12-16T05:50:18.231Z",
    last_used_at: "2022-12-14T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "f136c3ec-17d6-4cf7-8243-9f10712d48c9",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "288872ca-4ffa-4e45-a06e-7018914f8f39",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.231Z",
    ends_at: "2022-12-16T05:50:18.231Z",
    last_used_at: "2022-12-16T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "7fcc2ffe-0dd5-441d-9bbd-1872f1fcb96d",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "c186f8a7-01fe-406c-83c5-6598abf16ef9",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-20T05:50:18.231Z",
    last_used_at: "2022-12-16T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "e922daaf-019a-49f7-91f9-19948dd755c6",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc2",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-23T05:50:18.231Z",
    last_used_at: "2022-12-17T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "ed7c7300-1771-47c1-9130-b623a4a202f8",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "402fd3cc-4fe8-4638-9b45-7fad61d7103d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-21T05:50:18.231Z",
    last_used_at: "2022-12-17T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "8e2701a4-5fd7-46ab-bda0-f00592cfa509",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "056a79d3-1767-416a-bb92-acb8e1fda027",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-27T05:50:18.231Z",
    last_used_at: "2022-12-18T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "8877c758-847a-472d-a9e8-daa5bbc4f989",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "823764ce-b1fa-48e1-a5c6-7315f04b0f1d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-23T05:50:18.231Z",
    last_used_at: "2022-12-17T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "55cae2e2-ddda-4118-81ee-3fddf8d3affc",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "067a172c-068f-454e-a5b1-1ee6600591fe",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-24T05:50:18.231Z",
    last_used_at: "2022-12-16T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "0a8dffdf-6ddd-4a4c-bcb0-7aacc87fd510",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "e077efe5-ebe5-40e8-9892-b892cd25b999",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-27T05:50:18.231Z",
    last_used_at: "2022-12-17T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "b631a2bf-a91b-4d3a-bddc-49028aabd7d1",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "2141490d-b0dc-45f7-884d-1c929acbb143",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-24T05:50:18.231Z",
    last_used_at: "2022-12-18T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "0806f3dd-714b-43e2-879d-3f945802fcba",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "1164d929-47a4-4f0e-a379-b694079991cd",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-27T05:50:18.231Z",
    last_used_at: "2022-12-17T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "986a4a80-0d7a-4b99-bbf7-b72c2cc5d9b2",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "704bc62c-1e8b-4fd2-b561-a42d7e8883f5",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-22T05:50:18.231Z",
    last_used_at: "2022-12-16T05:50:18.231Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "25dc5f9c-8c22-437b-aa6a-8f20b12b9f4e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.231Z",
    code: "1234",
    device_id: "51e61694-aba9-474c-a94e-e418e76dc3d7",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.231Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "1e554fc4-ee13-412a-b941-036a22582061",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "bc826f8c-026e-4959-9fcf-cabd77dba81d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "3c8d2fd7-c44d-4256-8538-c00bad57fc49",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0deb17f8-8280-49c0-9901-5e6ed31b68ae",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "d229b742-859a-4515-a0cb-95ead1bf25e8",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "62df7ec4-6a4a-4b30-97ef-f3afc82b6fb5",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "ae5afcf7-02ec-4de6-85ab-d0c5d63afd73",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "e6286ed4-d58a-41e7-b917-8496120e23eb",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "b9b9cea0-f290-43ea-871b-fccc8bf2a024",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "4cbccbf3-ba43-465b-acb0-8d00b0428ee6",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "6372eefb-67aa-492c-b725-3557f2af9268",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "b3d6d806-bc14-476b-b867-2ae921470e41",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "95e45953-889a-4314-b3dd-31e25ad73554",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "05901ec2-db31-4580-a13e-f029d9222a3b",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "e07d20d4-2422-42d2-8b72-2daa85b7bef2",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "20495c09-b373-4bed-a113-527367bd8b8e",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "e8b773b9-51bb-4815-b8ca-a2b5a8a2068a",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "abbbcd27-ced0-4514-b96c-781cfb820802",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "c557061a-aae3-4513-9982-f338e237258a",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "11e4b45a-1e07-4bd8-b9ef-27c11ad144ee",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "041ed828-1875-4c47-b316-e78882c25bc2",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "c1481ee4-9309-4648-9909-75e4bc2ff19a",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "75be419c-2fd4-4a39-9f50-4465363c925a",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "ab570b5d-bb58-4ed0-9ee1-3db601c05272",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "5e2bd0d6-7216-4bc2-8d10-3864e50c637d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "b1f3d325-fbd0-44e9-87e5-8b65721fcbcb",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "9f71317d-aa23-46c0-bca1-77a6dcb90ede",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0d59d091-d14f-49f4-ad74-9cc9403daa30",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "a2db0461-ed72-41b6-b041-0a5ad09d322a",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "54016605-5f4d-445a-9817-96d59b541a74",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "bafe1fbb-fe78-4e5e-9c40-988ceb7f2e21",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "60e1e6f1-97ca-4559-805f-3eaaf34f7c66",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "3f24caf7-9433-4b51-836b-8e2ccd97fa46",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "219cbfee-ccb4-4764-b76f-cffc49365554",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "f1e92cd4-2721-4fb0-9fcd-89b62c44b1f8",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "9abf1f86-3c1d-418d-a008-f930d2161427",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "308ab0e7-43bd-4a9a-8136-43be1ff22996",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "13ab3ad4-573f-486a-803c-a55904a9300a",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "58e41e9f-5f4f-488f-869a-d320e0679dc8",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "b8f871fb-bb59-4751-b959-133ad6a40f21",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "96abbe8c-e460-4504-b956-e6a411c09a61",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "45a570bc-74fe-46da-8b8e-370475b6ed39",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "84973a40-e3b5-4b6b-a2c1-b75f6dc45d56",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a8c0b5c0-4f8c-4762-b007-be80206d100f",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "d68224f9-e057-4119-9b1c-d4b6e47e31c5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "9ebf5a28-14d1-4ac4-bbb8-97093c57a176",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "1098d331-989c-43d4-ad8f-e5e5972cd650",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0aca7f95-3668-420c-9094-497e902b3466",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "bdf844e1-9543-406c-8dbb-f0c1da088904",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "b42f6618-14b6-4b19-85e6-afeac420193a",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "69b05c85-f1e0-4066-a48a-e7b9fc03d718",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "03d793d8-b09b-4256-8df1-d44332cdbf90",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "4534b167-38be-410e-8566-28b18bb642ad",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "4ce5c23e-799e-48b0-a080-f9168819a191",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "c6e3ebcf-b49b-4b6c-969c-a437bccc31fe",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "8a593be0-b48c-48d1-b125-ea286a6e34df",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "b01f5e8b-948b-4e6f-acf0-8a376a7cbff0",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "17b06135-91b5-4766-9d74-989cd4984a5c",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "ca881690-ae65-4409-8809-235e2c121bbd",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "aff50df8-cd32-4dff-b6f7-27ae76f9a280",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "f0127415-0c1b-40f9-8702-789792043a42",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "e8cd12bd-dd68-426b-9f4c-c5c0bb7b6bfc",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "b590ea06-b8f6-47c7-9516-43b89e854d28",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "778a04ed-07d2-4870-ac67-c1feef547547",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "7853d291-29c5-40ef-8451-313c35153eda",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a7df6efe-0a90-4a92-a606-946ee0517ab7",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "c7bf7a87-fb1b-4220-986c-1b4e5f9b89c4",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "50ac6659-e27b-4b8d-aed2-e9c94958dcd1",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "c096c6cf-56f8-4af2-a23b-c94839704ff6",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "b27728d3-264f-4881-8f1e-84513574c6d9",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "7869ec9f-4b18-42c4-b00f-46496e0d47f1",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "e4c2ffa2-5e6e-4910-ac91-3430768c5bcc",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "86338b4f-d4c4-4633-92f3-7208b612b573",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "62a727d8-c883-4e76-9fb2-775b06b5eaae",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "be5a6c81-a4d6-433d-b89f-7503ceea84b3",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "1a9b8b84-f10d-4dda-999f-daf75bcd3086",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "09e0d030-749d-49c5-a0bb-9089163d2b84",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0fae7994-9dad-4b4f-b86a-2e4b753315e1",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "0e646b1e-923a-4950-b846-add75cf9601a",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "7444b1b0-87a5-47e7-b846-a956ae3f289f",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "123432de-183c-4f01-8c49-ff9fb9ffbb21",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "5bda64bd-d2dd-4e54-854b-93ff976abda7",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "c066ebed-bc77-47bc-b56c-d40efa786db9",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "3bee87ca-5f9b-47ea-a0d0-112526635495",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "76065cf1-7778-47c7-af88-e1e34e66084c",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "234e0975-feff-4631-a15a-617191ab06e9",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e0885298-6443-4e1c-91e2-38dd5c5a2f6f",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "69afd878-3b9e-4234-bae6-581a4cc295ee",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "21fc2cae-6a5f-4fa2-854a-db977eef2970",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "2dfaf3c6-9a52-46f8-9319-7a9035578f2d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "89b0924d-eb01-4370-a7c3-ddb59ef3f8a5",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "c2e5f00f-9efd-4661-af5a-b7e2e6317425",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "d2a9f1a0-f120-4c7f-87ad-08af62693134",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "79f6bf35-bca7-4c9c-99d0-0e82f188495c",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "e4f18ef8-2695-4a3a-90e0-641c6941eaa9",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a96d1a6a-cf4c-4c62-a62a-110443e819e6",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "7e0f4b0d-ec1c-4723-9514-bbca96ab34d3",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "250d9ff4-d3fe-4a64-8d53-49b2206add5e",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "37ad61cb-c5b0-4507-af11-a40ff4f16399",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "8c76a1f2-1583-4535-8fc9-8f04a6f45144",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "776d4f5c-4591-4b6e-8871-1cfde5088bec",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "288872ca-4ffa-4e45-a06e-7018914f8f39",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "60a012db-b62e-4fe6-9a5c-b3d0894b099c",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "c186f8a7-01fe-406c-83c5-6598abf16ef9",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "f789361b-1c96-483a-8fb9-7a0f3033f91b",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "759cbd55-3be5-4463-aa7e-90e706d9dcc2",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "5c525b7f-faf1-4b28-93c3-879f23b5f25c",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "402fd3cc-4fe8-4638-9b45-7fad61d7103d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d3aae2af-4f37-41a1-ac93-846b5e1dc8a4",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "056a79d3-1767-416a-bb92-acb8e1fda027",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "63e65774-c359-4de6-9a63-c00024f1b5e6",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "823764ce-b1fa-48e1-a5c6-7315f04b0f1d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "e22bb1fb-4cce-4220-b3ed-a351cf4cc8c9",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "067a172c-068f-454e-a5b1-1ee6600591fe",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "3d91f9d6-ed51-428e-8fbf-b219a9b549bc",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "e077efe5-ebe5-40e8-9892-b892cd25b999",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "fe43ad43-5701-43fe-9e29-166c47cf8d3f",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "2141490d-b0dc-45f7-884d-1c929acbb143",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e1e4e640-f4fe-465e-9cb6-f55e4bd68b7c",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "1164d929-47a4-4f0e-a379-b694079991cd",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "68e8f1d5-06fe-4715-b725-b72727c19bd4",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "704bc62c-1e8b-4fd2-b561-a42d7e8883f5",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "8e831b29-b797-4ef2-98a3-3c3512739900",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "51e61694-aba9-474c-a94e-e418e76dc3d7",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "7be54cc6-6ea3-4398-9d86-9199ba31a37b",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "bc826f8c-026e-4959-9fcf-cabd77dba81d",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "6746d590-b9f5-4f7b-9f43-b8b6215a9903",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0deb17f8-8280-49c0-9901-5e6ed31b68ae",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "35065202-ff71-41d8-bc29-03ce8bd7244d",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "62df7ec4-6a4a-4b30-97ef-f3afc82b6fb5",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "8052d7b6-2216-4988-af1d-4ed0e64fd21a",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "e6286ed4-d58a-41e7-b917-8496120e23eb",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d53bb582-d200-4b6d-93bb-091f1041c6af",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "4cbccbf3-ba43-465b-acb0-8d00b0428ee6",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "334151d9-6a56-4593-8101-cb966ea62c57",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "b3d6d806-bc14-476b-b867-2ae921470e41",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "0debd7e9-3ee4-4480-8eca-3bf0c4d91ee9",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "05901ec2-db31-4580-a13e-f029d9222a3b",
    building_id: "e1741d59-61df-418e-a458-56cc1429b242",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "d8760f7b-d3ba-4ded-ab24-098cf83c84a4",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    type: "timebound",
    device_id: "546a274f-56b1-4bef-a593-debb0cf3080f",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    starts_at: "2022-12-19T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "65b00716-d80e-493d-a176-01d4f8e3d794",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    type: "timebound",
    device_id: "d01494c7-9826-4e93-8217-70855e451f09",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    starts_at: "2022-12-19T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "2ed86f03-9f9f-4a55-a6de-53a1e47d57e4",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "bdfeb5ee-b4e2-4f69-a3b7-0ee8d0e969b3",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "9c9c46cf-26a6-4546-b7ce-509088d7f357",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "75718afb-5a3b-4bee-9bdb-7b6f522b5c95",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "00a9ad2b-7ab4-422f-a89a-8fbfe73181e0",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "ba5869c3-50c9-49a4-a58e-4ae41e8bd25a",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "e0db4223-a7f0-4ea5-91ed-1a487a0aabcb",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "785587c8-13e1-4b83-84ba-fe1528ec8cff",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.232Z",
    ends_at: "2022-12-16T05:50:18.232Z",
    last_used_at: "2022-12-15T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "1c441cfd-c2ad-4b7d-b601-8ca295bafc2d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "ef3b716d-4fb0-4ead-b29b-6a791098fb00",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.232Z",
    ends_at: "2022-12-16T05:50:18.232Z",
    last_used_at: "2022-12-14T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "10cc5384-10eb-4356-8e66-292d739da5f6",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "77f4387b-802d-48fb-a948-db8f0c33b881",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "da1aa5a4-eb1b-4ebc-a4bb-960ca83c4723",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "5d576095-9581-46c4-a096-7d84e9b6247b",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "aff59690-341c-4f57-8b9c-c4f4fb522c3e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "15653865-7574-4b23-a41f-434a08ed8858",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "5eb064ec-8978-4985-9e0f-63d32f38d5b8",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "7a493849-6b15-401f-83c2-c8e9b9f406b3",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "220daf25-f9e1-43ce-bf0a-939ccd1fe95b",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "f8f50725-6288-403c-bc6e-62fbf885319c",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "31b5a3df-4ab0-4e40-abe5-d742838460ed",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "62ee31bf-6288-49a3-92d1-b26f12d44f4d",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "ab313e4f-f057-49dc-8c1a-a858f2485873",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a83fb95a-684b-4f72-a6a8-dcc751b69be3",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "88555610-c778-4a8b-829c-651292feab4a",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "c0c823e3-1b60-4a64-aef0-6b507f68d524",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "dafda1b5-1eeb-4d2c-99ce-e53915e4c318",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "2ffc2c08-dcca-43ef-b470-a8fa05e62f1d",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "deb408f1-07d3-4784-8178-49e7eef75809",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "f4af5f4a-fa9c-47f6-8338-a20d35203d84",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "16bc655c-74de-4632-8b50-51418725a720",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "3f172d9e-a12a-4784-a5ff-d40023f9bfac",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "dcbf04bf-da51-4eb9-a3ec-19b57bcb0abf",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "d98d59f2-7ac8-4318-88f6-02ed97b222ca",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d871fe8a-dc0f-402d-8c65-c3bdfedd346b",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0a2725aa-0b11-4bd7-9a7d-f1324f981f4f",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "ae18a293-872a-4f63-be99-122bc46a2501",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "3bdeddb2-5045-4502-888a-358229e76af7",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "d01e2205-1dbd-4316-a1ca-e438192c6cd9",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "acd27ae1-a041-4b54-84f9-3c525b2b4c29",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "38b492bd-35fe-415f-9154-44b8d7d6e5bf",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "92779932-5983-4f92-aa39-de0486cb50d9",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "235bd9b6-fdea-4846-af03-4c62a2b63a0c",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "1de9557e-c43d-4d6f-afdc-14e7ea0b645c",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "d048afe4-3d5f-4642-8a97-f5a6bf247fb5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "9313dbe3-238a-46c4-b9f1-045bafd06ac1",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "6689d3b5-152f-4cc7-96aa-f9814ed2f05f",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "24eb0341-cc2f-44c8-9300-e7afe54cd3bf",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "a1b98f68-d881-435d-8bcc-ccc12765f727",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "ffb5756c-0477-4e5f-a789-e24741ae8305",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "86ef5b41-a791-4150-93ac-581ab3939a04",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0debc2d1-ab3f-4285-9c64-c3845c45bee9",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "4e6678aa-e3d3-41b3-9eb7-b1e438fa6fe4",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "611a29a6-50b1-4cc7-b2d9-c1c3b53a78c5",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "2dcb12fe-9a0e-4350-a831-a6c163dd6a7a",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "84e38944-5a85-4eef-b383-296348b8e3fb",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "8c300e20-fb63-433e-a690-a2f09f78e1e7",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "77c1ffa2-e941-4ff0-ac10-2f39b1d80f6e",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "c842f08f-d471-4440-b764-8666dec180d4",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "d6a04c6f-6ae3-49a4-abae-ff0d94235f98",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "8b738351-97d1-4e7e-8d08-dc860a92366f",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "1a609ff1-cd42-4063-b3bc-458f3190ead6",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "3b0ac96d-0c91-4799-8947-152dd2df3e74",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "8b93fa83-492f-456e-b5c3-b8b46c03ff80",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "7d222f0d-4e50-4158-9cbc-06e3050bf2b6",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "ad36ff86-8e72-49d5-8bd3-0622cf10248c",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "b14c3acc-42fa-48d8-abf7-d3434108ec7f",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "e3000902-8687-4869-b12f-766de53a09d0",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "78c0c209-664d-4387-9fdb-38eb24cc969b",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "b8d0e878-35ad-419a-ae6e-2fa6aa30c175",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "a4ff6ed5-0e93-42f3-89a9-e83efa0aff8b",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "36d72dc1-5029-4c18-9acc-9ea994de8ec0",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "3d9e0e5b-8c33-4ba6-9f27-346e70c68f3e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "93900a54-ac28-4035-9641-58f546abc591",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "0ea9de76-68d1-4d58-a345-70ccefcda33d",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "1444ef6e-da27-4ecd-82c6-a852d9a1883b",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "d668c141-46b6-4d3b-ae9b-f08c2d3c9225",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "94de6b1a-15e7-457e-8ae8-98fb67c8e85e",
    building_id: "2f072a89-f29a-4fe7-a43d-3fae0f09ab31",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "7717050b-86d1-4db6-972b-2cd1ac82c8c5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    type: "timebound",
    device_id: "53c89473-a264-43f3-a965-dc1bec8d1eb2",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    starts_at: "2022-12-19T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "ba63a35a-624f-450f-9191-b8d2b8dde0d5",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    type: "timebound",
    device_id: "d7ebd09d-1d65-4c61-8014-cd01b70ab46b",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    starts_at: "2022-12-19T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e086b35f-bec8-45c7-8479-92b1487624f8",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "45cec35b-aae0-4ff2-9d93-c9e6663dc42f",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "11096df2-0271-418c-b23a-f41b62064367",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a4d6c940-85fb-4b3d-be5e-ddeeae56638f",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "3f4a7e4c-90d6-49e9-b0c1-2a3bef41e04e",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "9e7d66ca-386f-46fd-a950-d3366ff6cc3b",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "d1dd4221-ac8b-4704-92f7-51ecaefbea29",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "77600596-d1da-43f5-812e-708276ee5059",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.232Z",
    ends_at: "2022-12-16T05:50:18.232Z",
    last_used_at: "2022-12-14T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "16aea60b-8202-4235-8cdb-26588acbfb60",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "355141e0-dded-4132-90dc-ffa255e27a62",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.232Z",
    ends_at: "2022-12-16T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "44fefb1e-568b-465b-bfc7-f095a16e52e3",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "14dbd035-db38-42b7-aa36-b6d381e36832",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "a3c90ad0-83f7-40f9-8ec1-1dd217f3b8cc",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "9d6c78eb-d96b-48f7-b20e-307f6d6c513d",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "f56ef5fd-a214-4cde-83a5-036cdf1a8e22",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "e55ecbba-d710-494d-b374-f8025e182629",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "409dc750-a694-4baf-a18f-84e0a29e276c",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "27af4334-d80c-4d7e-ab7a-f6fae876c34c",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "60a8cfb2-f1d7-40c4-9528-07963bea38dd",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "37af5d3e-f761-45b7-8029-eaa2778c7590",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-24T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "a62326b1-e481-4a8f-b617-bf3c01c89d39",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "1c29ea9c-01ec-4618-8788-2939dd728997",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "631d7d5d-8cc6-4551-a26b-aa106af99c3d",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "05670828-70e8-4f06-befc-91940df97473",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "f7050497-3e99-4edc-8fc3-e1b58763d664",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a6b40b93-dbe8-4ef4-8457-bf7a65ea17fe",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "47def6be-d4d7-4a33-a280-7387669f539a",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "2c22d141-2f00-4b84-bcf8-7d31180e6f52",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "39f0d14f-7959-42ce-a0fe-63ace0e08a2c",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "bcd24cf7-c036-4432-919a-01e4563dc326",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-26T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "d59d3eea-163e-41ba-b0e6-d82141f2f9aa",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "da836d6d-1d26-4865-a8e5-9701acd2eaaa",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "f9993d80-999e-4c6d-8035-4f9f5a4b8051",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "bee96acd-ec99-4ba8-885b-d218d36b5a7b",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "641df493-91f8-4116-930a-bf4a852fa6a7",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "925f0520-8deb-4757-923a-36eedeb9e99f",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "dcbefafd-db2d-4721-a102-8b2ca684954a",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a1ad2f2e-cc8f-4aa0-8492-61337e9bf0ae",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "7ae474d7-18e6-49c5-9c77-99f23cb6dbfe",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "efd7ca62-f9d6-4955-b6b6-a4b18932a29f",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "8c8b1c70-cac6-49e2-aa20-03c74a3f1268",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "c84ea2a7-74a8-48a3-acbd-604902b4eb18",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "df64510a-bace-4bf1-bcfa-9ac3b91942dd",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "adab994d-9f02-4364-a6a0-0b912e305133",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "2afb87e6-45b2-4505-a162-7b9f3b59c355",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "2df4084d-3774-4675-a01a-83b462d21be0",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "d7e1ed5a-c6df-4a5f-802d-af0923220e98",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "8d8744ca-0d91-46e5-b570-7aba8914220a",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "43f83bdd-137c-46cd-a8df-135da615d72c",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "3c4e6855-2247-4c07-a03d-a13f919c2d39",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "284d15f0-69f8-4096-a4f8-dbce21a78efd",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "73472c94-6522-4ab2-92b2-ce793a1ab29e",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "8171501f-8f9d-4fe1-ac49-8decdf4464b3",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "04910604-3be7-443c-9eae-221ff244b578",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "9dc35b33-11e8-4767-b4e0-5a4856cd847b",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "8f0cc3ce-17be-4b55-9045-90f0e613beba",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "ab75a708-9f69-49b2-8cff-acaf3e40250c",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "5ad430e2-821e-4298-a993-5c4a297a6639",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-19T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "7107d6c6-eb50-4761-b8fe-b79d9bab2049",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "c4c212c5-2a8d-4489-a580-c6bbfb2e2a8a",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "38013051-4aa2-4d6c-b13e-7e8b68c07e51",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "851c008d-6808-4e3b-8218-8c42d26206e8",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "35d31681-8434-4a4b-952b-49393d7ae6c2",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "1297b6b6-10e2-47e6-a31d-9740c0eaa8f6",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "2bae8820-8db4-4cf2-ac9d-350f6a5af6dd",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "24974045-1690-4757-af14-c6599cbf9209",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "b4177fa3-60e6-4b0d-8635-6ae1cac48e92",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0314add6-9e96-47af-b92e-c6f55503ec3d",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "73217dc0-3bf0-4d13-a45c-42092eccea28",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0eb3f04f-469b-49a9-8892-d44c12bec69b",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-21T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "88b38935-2d28-4290-a044-caf32b8ea7c8",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "df0b9aca-d70c-47aa-abee-a17d5e45f1d2",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-23T05:50:18.232Z",
    last_used_at: "2022-12-16T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "ab756fbe-e867-426c-bd88-912260addc53",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0fefb7e5-1ba3-471d-9448-8dbbf6c33be1",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-20T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "d2ec2d39-1c2e-4593-a031-2bdea44f4027",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "a0672bbb-8da5-467d-b89c-b175d6d94204",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-28T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "0cb9e9e3-6cb2-4cf5-bd46-d2d9c8e580f5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "0ef1dfa6-d9e3-41d3-932f-da13cd8f105b",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-22T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "48c0fce2-3c77-4e75-81d4-81a3c8f7987f",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "433e4733-bdea-4e26-ad34-6781659ed817",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-27T05:50:18.232Z",
    last_used_at: "2022-12-17T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "690b5ff6-cf6a-45ae-8fc3-a8d703cc1993",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.232Z",
    code: "1234",
    device_id: "93e41c97-69d5-4ab6-a179-65d209a18404",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.232Z",
    ends_at: "2022-12-25T05:50:18.232Z",
    last_used_at: "2022-12-18T05:50:18.232Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "c63e51b4-8364-4b39-b3b6-8150cacc032f",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "bfaf8558-ceed-4b75-9e78-2337259a5be7",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "98eb29b5-6d1d-4cab-877d-fd20da743325",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5a17e07e-7a7b-44de-9024-86f9f3922492",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "9f7bb1ae-c51d-4e15-abf5-475f0b631bac",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d1c2a3c8-34cb-42e6-8ae0-c58df439a405",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "23fb528b-bd25-4134-8b7f-d41f8291d471",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "081cccfc-1714-44c3-b1a0-1b442e0b9fab",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "f21b2855-98f5-48b4-b85c-1bd186754920",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5ca0d92b-2197-401e-b1b6-5ceec640d78a",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "4b44ead6-9f38-465b-8fd2-275b17f4c0c9",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "462184b3-f657-4800-a94a-52e00e227cca",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "0f582505-4dea-408f-aef5-cb44afdfc7ed",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "1db89034-2236-4c21-a58a-dc670eb6d85e",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "1357a367-b4e1-49a0-98be-1ac89c7a8e9d",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "84c2ff6d-8b6e-4096-8608-1604c4edd902",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "7fa08d54-8772-44e0-9f39-4454ed222d71",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "33eca49a-37ae-48d9-a220-595f92e624a2",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "e82d6ed4-021e-48da-8085-55e31aef8566",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "74207be6-3496-4525-961d-1ef487e2abd7",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "8f689189-8a90-46df-9581-38acff481194",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "dd6a2279-0f32-49e4-8f80-89f40b02c1ba",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "4b560501-5ea0-4fc5-8d2a-bf6c32f30745",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "6cd17b59-4d19-4cfa-a176-a9c34d662251",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "f112cae0-63e6-49ba-8b39-a81668dd9a14",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "a169d29c-0583-42ef-9d23-5309dd6de022",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "96e06cfd-0def-43da-ab09-025312b93a70",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "bed7f9f8-5dc7-45d5-9e37-b4348525c353",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "adfcf0ea-2595-497b-a516-93578778ff27",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b73e4696-c324-4159-9480-e11c6376ae7d",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "4cd0912b-f03e-40b8-9df5-aa6aa4be6dba",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "ff64d3af-5c7f-4ae4-bc44-ce45d39222b1",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "030e3413-9904-4235-ba50-d733290f36c6",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "ec5c1afe-f304-4fef-b511-b189f6c31f69",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "15628d05-38e9-41af-90a2-efc47357212b",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "4340d1b7-219f-4653-9a19-28dcfe7d503c",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "948560da-2b61-4901-a365-afb3e6a3fca3",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "fd50b6bb-0d3b-479f-b7d9-d4c90ac14e94",
    building_id: "28511b9f-db2a-4b53-86cf-529a02f996b0",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "10d9afe2-42bd-4538-8907-453629a9204a",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    type: "timebound",
    device_id: "ab132fbf-a617-43a5-84e8-99908f0cf7f2",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    starts_at: "2022-12-19T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "40863625-d18b-4e50-8461-94f6a2898ee9",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    type: "timebound",
    device_id: "a09adb82-d319-4636-acc1-1d8893b4830c",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    starts_at: "2022-12-19T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "2515db15-a905-4f1f-b325-d3ab27211dd4",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "4db91d63-73c7-442e-905f-b4bd6df81a7e",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "db7f0c0a-dc16-41e5-bd39-d0d91fbd19c0",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "6f5aafc1-48cc-474c-ad88-ca28e35888cf",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "ede7514b-63e8-4190-8fbc-f77c0183adc6",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b1fa1bc6-8ee5-42dc-89cb-15f4054ebed0",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "311a9e6d-0ead-4869-bbbf-8742be810c97",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "e394c2c0-ef1d-4856-b491-71535ed5b75d",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.233Z",
    ends_at: "2022-12-16T05:50:18.233Z",
    last_used_at: "2022-12-14T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "64fed21f-8315-42cd-bc93-211de018992e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5ab5bfc6-b973-4adb-8a7b-4520e8e27ccc",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.233Z",
    ends_at: "2022-12-16T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e2b687be-4d0b-454b-98d8-6a116ffbbeae",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "969f6404-58d7-4895-9a02-6d30965207da",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "8c6f5daa-dcea-445c-9de3-817f86fae32a",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "1849c4e2-ca94-4811-9c2a-91607ff1382a",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "0ac0babe-e730-4f9b-9a3f-0963c2e08f68",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "e93e275c-a64c-4041-a084-2d27ff29a242",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "a9d2d578-b2b5-4ac6-b7a9-528c20e580c2",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "89355cac-a34d-42c7-a4f0-01247bdba256",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "818b83a8-93b0-4e17-8436-94d9978a8bd2",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "39b0aa27-384f-46c6-a25d-602034c16c24",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "35038077-6fcf-46cc-8d46-ec8af26fe500",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "049b3789-d62f-4c52-9296-a900ba6b4174",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "63f2b0d7-18c8-4058-8a31-6f2349836497",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5d9c7599-8aa9-403c-96a5-b5f679e65a88",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "571dda47-8c4c-4381-b661-ad1cf03331a2",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c96892a6-5fdb-4c51-a036-202c2e3995ae",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "d733b745-474c-4de9-bfdf-925de0d14b7d",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "9c4634c1-f0db-43be-b8da-6600b912d98b",
    building_id: "421679a8-71cf-4dd5-8e31-09bbc4e1b1ec",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "fa572290-8501-4caf-8a9e-bfe77bc599d3",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    type: "timebound",
    device_id: "021b8d59-3f3c-48b6-95a5-fe1145f6a564",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    starts_at: "2022-12-19T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "7fbced32-85ae-419a-9756-632d68d187a2",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    type: "timebound",
    device_id: "126a72f8-91f3-46d4-973c-4f300cd20267",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    starts_at: "2022-12-19T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "abba5612-319c-4cfe-8142-984a2bbf9205",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f07d92d4-3a8b-43e8-838d-1e3cc446bb95",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "a77a3098-7bd3-4a18-b66b-3b7c24d45c4e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "592e67b4-ceb2-44b6-bf51-7274da8c5a5b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "604838b2-c09d-4730-b8f5-85304c6a90f6",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d0895650-344b-4f90-92db-b80292c07bcd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "3d9d1ee3-eab1-4e42-9a4f-523b09ad0a45",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "4bf5006c-6cb1-41bb-bf9c-acae79ddda8f",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.233Z",
    ends_at: "2022-12-16T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "9e418ace-d7d4-4c85-b402-cb29eb58f655",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "a270b053-dcb1-4680-a0f7-8a007e224222",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.233Z",
    ends_at: "2022-12-16T05:50:18.233Z",
    last_used_at: "2022-12-14T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "2f3aacd1-92b7-4019-b513-0c266178c87f",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "3da6ebc1-a85b-4746-84c7-4cb0c8667f18",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "aaddde0b-f113-4fd2-84ed-cf3dfb2ae395",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b7fe0a5c-3bd8-4897-824a-760d61a49abc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "86f73a87-0a63-43a7-b325-c2fdbdac5714",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7d64e870-951b-41c7-ab36-4611e18c9eb0",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "4d45b780-445b-4d4d-9579-48e1316afcb1",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f5f61988-46f5-458a-9358-b321580c3ddd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e74af11f-45dd-4594-b734-8d3ec8911889",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "0229110d-bddf-4d55-9365-856c3ad48caa",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "8503040f-8438-4b64-ae43-5fc85fb4f3e0",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7a938950-80a6-401f-99cf-b51ec0e4ebbd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "fb5d47c2-bd43-4f99-94a6-1f856fb00713",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "07d9b176-e3e3-41f0-a4cb-6c93649bdfc2",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "d70b1e09-0031-43af-9da9-2de78fdd6d0a",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "848f126e-875e-46f2-a8f7-ae1a97ff8630",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "1cdb70f0-3bc7-4848-8f11-b50e47614d03",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d75911e5-5985-4df0-9c69-03cd9c66db92",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "5e0af945-59e1-4a5a-a046-8326d81567ac",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "88d847e0-8a02-4db9-a8bc-e3aa1c4ed95e",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "26382919-e564-4104-82c1-a4d2555e9e01",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "63ccc55c-1876-4ec0-b589-fa49342717f7",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "3fda0860-07ad-4d09-afc2-2bd2aac2c2d1",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5b0502d9-0c74-4ba3-a153-49403b831d6b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "5db0ab6c-6ce9-43c2-8511-2bc753b6d0fd",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "02e51fe2-9a13-45c5-8859-a7edf49bdcfc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "f62f2390-e659-4a17-9dfc-a54728441dab",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c0e60057-a3dc-4b1e-b824-8c04f14ecbac",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "aed253f1-77ea-445b-be0d-6e43fd7b2071",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "021b8d59-3f3c-48b6-95a5-fe1145f6a564",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "f918abc1-4b07-4500-91b9-040612dc0e8f",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "126a72f8-91f3-46d4-973c-4f300cd20267",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "00aad13f-262b-429f-af15-6d8d58121043",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f07d92d4-3a8b-43e8-838d-1e3cc446bb95",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "1f20d26e-64f1-4046-8426-52b034f13fdf",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "592e67b4-ceb2-44b6-bf51-7274da8c5a5b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "95ff7d67-43df-4e23-bda0-e2acf65469ca",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d0895650-344b-4f90-92db-b80292c07bcd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "4ca28ce9-d410-4b97-80d1-a6ba0d7263e2",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "4bf5006c-6cb1-41bb-bf9c-acae79ddda8f",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "bba2aa16-a7a1-4df9-9abb-fabe344e378b",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "a270b053-dcb1-4680-a0f7-8a007e224222",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "1c05bfc9-850f-4da3-b55e-e56e1e161a62",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "3da6ebc1-a85b-4746-84c7-4cb0c8667f18",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "6332d4f7-a59f-4563-8fba-a77a08eac7b8",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b7fe0a5c-3bd8-4897-824a-760d61a49abc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "cab2ba59-4d4a-4d93-9b97-f50f6e9b8300",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7d64e870-951b-41c7-ab36-4611e18c9eb0",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "7d27bf69-2d89-4507-bad8-67be15f50af8",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f5f61988-46f5-458a-9358-b321580c3ddd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "f0b42a72-b386-4ba1-a08a-12c14c6ebf09",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "0229110d-bddf-4d55-9365-856c3ad48caa",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "f2873c56-7757-4aa7-b03d-b4e20bcae0b0",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7a938950-80a6-401f-99cf-b51ec0e4ebbd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "ed664090-e3f5-4b95-8490-245422187188",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "07d9b176-e3e3-41f0-a4cb-6c93649bdfc2",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "c3d1bae2-4cd5-4f4c-9950-644ba573f1f9",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "848f126e-875e-46f2-a8f7-ae1a97ff8630",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "10a64978-a41a-44a8-a2ef-514bf458fe3b",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d75911e5-5985-4df0-9c69-03cd9c66db92",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "f5943da1-362e-4d45-93ce-30e67bf429c9",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "88d847e0-8a02-4db9-a8bc-e3aa1c4ed95e",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "017b15bd-1878-466e-a9d2-a0f673c78737",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "63ccc55c-1876-4ec0-b589-fa49342717f7",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "a0508525-1466-44aa-9b13-85032abffe91",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5b0502d9-0c74-4ba3-a153-49403b831d6b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "85e18449-07c1-46b2-b745-ba09400c23a1",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "02e51fe2-9a13-45c5-8859-a7edf49bdcfc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "809ef1cf-c59c-4e77-959e-ba3dcb188cf9",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c0e60057-a3dc-4b1e-b824-8c04f14ecbac",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "fd68b7a2-4a9e-46fd-be79-0f5f3ecd714e",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "021b8d59-3f3c-48b6-95a5-fe1145f6a564",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "38f073c0-1884-4507-a839-b8aea28201ed",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "126a72f8-91f3-46d4-973c-4f300cd20267",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "dc35a5e7-8f11-4360-a429-e7b38d6a48cf",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f07d92d4-3a8b-43e8-838d-1e3cc446bb95",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "8967fc73-5956-4c9a-90f1-83776cf91cd7",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "592e67b4-ceb2-44b6-bf51-7274da8c5a5b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "7115e361-d832-4fe6-b159-2fbbaeff4632",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d0895650-344b-4f90-92db-b80292c07bcd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "1cf17972-9fc8-4a0e-a241-7ceedcd9debb",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "4bf5006c-6cb1-41bb-bf9c-acae79ddda8f",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "5010478b-4380-4e51-9351-e1caabb273ca",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "a270b053-dcb1-4680-a0f7-8a007e224222",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "ead34032-04a4-4798-aa6c-7bad20025c35",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "3da6ebc1-a85b-4746-84c7-4cb0c8667f18",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "bd2a5a93-ae09-4a1e-a116-b395297186c1",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b7fe0a5c-3bd8-4897-824a-760d61a49abc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "3d41d3e2-5741-4a94-8552-56749178d635",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7d64e870-951b-41c7-ab36-4611e18c9eb0",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "e9f58b06-8839-4392-b7bc-97c564d618d2",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f5f61988-46f5-458a-9358-b321580c3ddd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "17c15212-4cd0-41c4-be23-49ef94cbe07b",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "0229110d-bddf-4d55-9365-856c3ad48caa",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "1da881a0-1cba-4ecb-8d27-b4137f07a34e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7a938950-80a6-401f-99cf-b51ec0e4ebbd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "714f73e3-df47-4a15-9782-6796582e0b29",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "07d9b176-e3e3-41f0-a4cb-6c93649bdfc2",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "a12c0825-4f52-4cd5-8257-ccc03c4a0066",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "848f126e-875e-46f2-a8f7-ae1a97ff8630",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "cb6e114d-42a9-4144-95a7-99f79bf2ac23",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d75911e5-5985-4df0-9c69-03cd9c66db92",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "20d60d18-f49f-45fd-a86e-6514dc7efc61",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "88d847e0-8a02-4db9-a8bc-e3aa1c4ed95e",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "858fba9a-b6f4-4f34-8524-fee36942c45c",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "63ccc55c-1876-4ec0-b589-fa49342717f7",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "28edd6b5-1a1d-42d8-8a7a-9f83bcc3b9c9",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5b0502d9-0c74-4ba3-a153-49403b831d6b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "b65f37b0-0c0a-4919-8491-dc90d1392a5d",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "02e51fe2-9a13-45c5-8859-a7edf49bdcfc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "3a698965-d4c8-43c1-9f0a-73efb855e6b2",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c0e60057-a3dc-4b1e-b824-8c04f14ecbac",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "408af5ca-df57-4413-83ba-478252381d01",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "021b8d59-3f3c-48b6-95a5-fe1145f6a564",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "2cb60f37-0788-4b57-a729-057283a8e519",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "126a72f8-91f3-46d4-973c-4f300cd20267",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "7cb3ff2e-af6d-469c-a397-9f68080f3040",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f07d92d4-3a8b-43e8-838d-1e3cc446bb95",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "7fe8a342-6f49-4fd1-b099-8f4973a9be02",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "592e67b4-ceb2-44b6-bf51-7274da8c5a5b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "a19d5ad0-8a8d-4b99-bf77-a7f2ce249948",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d0895650-344b-4f90-92db-b80292c07bcd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "2e4f8f02-5737-4167-b415-f9b3af088978",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "4bf5006c-6cb1-41bb-bf9c-acae79ddda8f",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "bc3365a9-b453-4067-b335-03ccfabb3c04",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "a270b053-dcb1-4680-a0f7-8a007e224222",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "ef113f1e-6c0d-4e99-8130-5a6a11e1302c",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "3da6ebc1-a85b-4746-84c7-4cb0c8667f18",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "4324d517-f2f1-469b-8328-d9dcccb70584",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b7fe0a5c-3bd8-4897-824a-760d61a49abc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "f0e8de02-1c6c-4bb5-b0c5-6e9b2f5beea1",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7d64e870-951b-41c7-ab36-4611e18c9eb0",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "4f988d35-cc21-4f7d-9132-66115660cbe2",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f5f61988-46f5-458a-9358-b321580c3ddd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "7682f54e-baec-4d82-8a18-b051f61b5f44",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "0229110d-bddf-4d55-9365-856c3ad48caa",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d8ff346a-ada4-48bd-88b9-54c72c558f78",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7a938950-80a6-401f-99cf-b51ec0e4ebbd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "b42fe044-ca16-4fe9-936d-330f835cd699",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "07d9b176-e3e3-41f0-a4cb-6c93649bdfc2",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "83125f74-b178-4e57-bcfc-5058e5ad92fe",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "848f126e-875e-46f2-a8f7-ae1a97ff8630",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "ca582f82-32a8-421b-bc47-0c1eec5018b6",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d75911e5-5985-4df0-9c69-03cd9c66db92",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "bf1f1f5c-d710-449d-90ff-cf69c7006d01",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "88d847e0-8a02-4db9-a8bc-e3aa1c4ed95e",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "7dd4f837-2078-450e-aea9-456d44847bf2",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "63ccc55c-1876-4ec0-b589-fa49342717f7",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "b44644c4-b41f-4d71-a33a-db1b76e86a23",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "5b0502d9-0c74-4ba3-a153-49403b831d6b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "5d91dd2c-d072-4042-8bec-58f16ea3ebdb",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "02e51fe2-9a13-45c5-8859-a7edf49bdcfc",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "74ddc7c0-aeba-40a1-a37b-15cdbe1134c0",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c0e60057-a3dc-4b1e-b824-8c04f14ecbac",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "dd56afb6-5d98-4c8b-b759-9fa0b8c64215",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "021b8d59-3f3c-48b6-95a5-fe1145f6a564",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "96abc6a4-1160-44d2-8b1d-37aea29fd96e",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "126a72f8-91f3-46d4-973c-4f300cd20267",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "1358c11e-c6bb-442c-a564-287f353fd700",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f07d92d4-3a8b-43e8-838d-1e3cc446bb95",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "af254b1e-4554-4f14-82e8-5d7481d454d0",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "592e67b4-ceb2-44b6-bf51-7274da8c5a5b",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "cb98695b-f485-419e-9027-e5e09718a380",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d0895650-344b-4f90-92db-b80292c07bcd",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "a7f49bec-9dc0-4a93-9a25-1a74d05928e2",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "4bf5006c-6cb1-41bb-bf9c-acae79ddda8f",
    building_id: "b6ab1921-9ad9-4b6a-bafd-1a0b27746adf",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "755f1808-61de-4701-953a-62b0266e1b6f",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    type: "timebound",
    device_id: "2732820f-6453-4f5d-9104-6b688a4b58fd",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    starts_at: "2022-12-19T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "b55c0432-6882-4803-8f92-bdc57d40c756",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    type: "timebound",
    device_id: "1cd7eeec-ab58-4e9b-9135-1aea8fb4070a",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    starts_at: "2022-12-19T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "eb3e0125-62bf-4863-b9a8-1f3c8e8a179b",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "0abd616b-ecb2-4061-88d3-dcfafb70552f",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "385a55e3-efb6-48ca-a40a-91cad0063bdd",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "ca4163cc-98eb-4cb4-86c6-0cb49eef5669",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "0ef9728c-0e6d-4c7b-a6a2-c07dc155bdbf",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "28b52aa9-e816-48fa-acae-4b38f83cae8f",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "a3836040-5989-40b7-9540-fb28445e2cc1",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "ad43cf60-994b-4074-9c6b-7732972585bf",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.233Z",
    ends_at: "2022-12-16T05:50:18.233Z",
    last_used_at: "2022-12-14T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "bee85835-cea8-4293-b231-5f470a2d352e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d260a125-5963-4140-a348-a57755a7f2bc",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.233Z",
    ends_at: "2022-12-16T05:50:18.233Z",
    last_used_at: "2022-12-14T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "4b25db1f-4a83-449a-9466-a05ffc4f1269",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "3bedf53c-6d6c-4348-b5ba-85663c764195",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d88a8c58-7fcf-4c41-9644-6c359d46fb5d",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "e18ec69d-44e2-4fa8-bfcb-2210a6720fe0",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "b36e6380-284b-4143-b48f-a7bd28b837e3",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "e893dae2-451f-4d07-9928-49d82dd0de91",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "20225efe-876d-48e2-ab21-d4aa517df38d",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c648fadb-f179-41f8-8249-044e92d6640d",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "05627d47-cff6-4cec-910c-316c06d187ae",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "386ad330-d50d-4cef-a211-345fab7f297c",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "e0d86774-46d1-49d6-a30e-72c61da96afd",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "1f82d2b2-a056-4b8a-a6df-62cdac3748a3",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "948a60c6-fd3f-4215-a02b-b1b8ceb8e5b7",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "dd073d3e-8a00-42dc-8049-7f52cc480d35",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "c914d846-3b60-479d-a234-58eb1dbaf249",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "93a0eda8-e204-4ae4-a9c3-597d758e98e5",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "dff92698-6255-405d-858d-c7290c4b845d",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "ee37a3d2-24a0-4a6e-b6ff-b02d2641fc2d",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "6cdb67ce-b11e-4347-8362-541ba18f4c33",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "0edc7c36-80b2-4474-9fe8-a4765b95038d",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "fc8c46f0-0d81-4914-8c92-6310cba015e3",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d68cc47a-c286-4758-8f0c-dd5b3aad91f9",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "e89ac1f8-14ad-4915-8890-c2eb1fe9a44a",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "28b8d9b5-1671-4e1c-b357-606d2df44d94",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "bd519a5b-b9c0-4bf1-b394-62e7887cd87a",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b5aed708-5111-425c-9a05-d3c746cc5f16",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "85be43fe-498b-46bd-91cb-470045646afb",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "459551e6-cd2f-4be3-9bb7-3b0e83b5bc6d",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "1bd4336b-0f14-452e-b7b3-9f0b3d7ed623",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d0a38ea3-3f8d-4311-8a3f-62bce38ce25a",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "d30f091b-f447-4165-9dae-16211ab92e3a",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "d377d148-93f0-442d-a415-e6275a3230ac",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "54a69df1-ba61-4510-b3d0-882b039bc6dd",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "756108b7-de20-4f3d-89c7-65fe8a3d0399",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "7db8874b-6a58-4395-a29d-5d0a3271b320",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "003732f9-ae4b-4b76-b66b-24326e327a21",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e22ad3bb-81cd-4f42-80b8-2c50df14bed3",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c8545f94-51e7-4486-9c08-91252ed50154",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "dd601c63-bbb3-40e3-a853-6417ac02b05c",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "1c754636-583e-40f3-b294-31258fd9eb4f",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-27T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "064719c4-4c10-47aa-bbf3-0c7b8bcd0b7b",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "83a06faf-460a-4f8c-bec5-3f91d6c84c69",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "70aeb2a7-f680-4109-b716-d960ae879fa2",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "96c2b767-af9d-40ce-acc8-3fb04cea72c5",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "4ef73832-cf1c-4ad4-9699-421fa2831fc1",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "6bf3c155-ceae-4e63-b96b-33fc67143df2",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "4f536059-4cd8-4973-8257-9545e504056f",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f41a37eb-d6ba-420a-8925-412c273787fb",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "fedd3dfe-458d-4644-9133-9f9c843ab1a1",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "7a2036ee-bfe8-4854-aab2-cbd9941998b1",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "de59e655-f2e1-42d5-a1a4-4cfa4acaf63f",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "e32bf76b-1787-4126-8ae1-bc87453bab50",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "13459320-c529-4b3e-8bfe-ef7ccb98690a",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "22149161-4fbd-4c6e-986c-88472a5df7a1",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "4b8124e6-b411-449e-997b-0d71daea3cce",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "21c357a1-cd9a-46e3-aa33-a57844755fae",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-26T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "15769f1b-d192-4d35-9e6a-7bc9700e94e6",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "9211f8c2-9cf2-4ca8-9c4a-dd0ec69ea8c9",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "2c871c63-3005-4b01-bf09-38f7a4e33b59",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "c4377f65-0220-46c6-b63b-1256ed334525",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "31d5dbe0-ad4e-4962-8e34-6266b8cb0d9b",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "85250777-96ad-4a89-930a-3ef20a403d45",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "a1d1afe4-7e2c-4eaf-a077-b27dd8bf487c",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "611be27b-2d46-4b9f-bdc0-0f248745caa8",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "db034ac1-e61c-46a6-9fb4-43f565b3ceaa",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "ebe0ca31-fa55-418e-8bb3-c3f9d8dd01df",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "f41f57a6-f91b-4ea7-b2c6-e901397a8359",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "181ee653-9753-4575-b091-5d7b867efe73",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-19T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "55f6bc03-b94c-4968-b572-0e5794131ab5",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "92a908f7-f267-4e75-b993-dee94584544a",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "b6ef05f3-e5d0-4e00-9cc8-c5d96aa4ac78",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "351c1c2a-0a4d-470e-80e8-6816bd47e68e",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "5f16e6e9-f9b8-48e1-ac2d-025b3a6526cb",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "a34c42a8-0cf4-4bb0-9e79-e67608c3d01a",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-23T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "1a101cea-1eb4-45dc-aab3-3d0385042afb",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "1422662c-f999-4571-8b59-26d16110cf7d",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-20T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "7ae07802-df93-4e75-a175-63a03be220cb",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "53bec9cb-0f96-49cf-b616-7214a0f79104",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-22T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "cec49beb-8362-490c-8dc0-bea72038a52d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "f3fa3a02-e32c-4adb-a0bc-a4e26bd5fc5a",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "1702d02c-090f-40b7-bfe7-c8ab32a52b2e",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "cd271d70-6ea8-4efb-b14e-5bcadf68d83b",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "82d5d51c-a506-42f1-a70e-8f48309ded9d",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "cc6b156f-317d-4666-9984-9f50d6631138",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-24T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "aefb3ae8-007c-4eda-9c78-35b0513608eb",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "87e1e4bd-f6e6-4e89-aaec-528ba20dbefd",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-21T05:50:18.233Z",
    last_used_at: "2022-12-17T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "4e8537ff-55bf-4c5f-b9a6-e59bdcb6a255",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b1dbf10a-9095-4781-b080-8a6fa545b070",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-25T05:50:18.233Z",
    last_used_at: "2022-12-16T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "e86d75f4-f03b-48a6-a2b7-2fbbddc03699",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.233Z",
    code: "1234",
    device_id: "b157c270-8521-41c3-8362-010c5f498da0",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.233Z",
    ends_at: "2022-12-28T05:50:18.233Z",
    last_used_at: "2022-12-18T05:50:18.233Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "47705e34-b86f-4f4a-a3f2-c43e8d4df1e6",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "34b3af75-9031-4d32-af89-8883b98410d7",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "273b63ec-81da-4734-83c0-d2e58ab6c743",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "ae2575f9-f022-4dc8-8f4f-78e5cbe15dfa",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "4bc9b7cb-b340-457d-8280-2de8694aad05",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "295e581f-ddaf-4508-836d-b251723123da",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "b17f71a7-773c-4dd3-ae85-8fe095fd308e",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "1334a940-0b7a-46f0-bf9f-147eaea8a069",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "cd237069-adf1-4c2b-bc43-4e7e9fb814d4",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "eaa9135b-c5d7-4042-b6c8-83b6cbf05180",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "0ac17240-4bb0-4f96-85b5-705faa86843f",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "3dad108a-f030-4efd-ab0c-7b32896aeb8e",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "c8cc1897-2d0d-4137-ab52-0ce2e964c9ce",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "2b850aee-9d7e-474d-8703-4f3716db3167",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "9438e0a4-9406-49ff-8f8f-667151473f0b",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "767f4add-0c06-4905-adf4-9a4bd5cd526f",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "4b01b3dc-613a-41ae-9229-b7621ede2cd8",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "fb57d7c7-2a8f-4f0d-84dc-88e33c746527",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "4ca328c2-6e2f-4375-be77-e4a356f8d3ef",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "1e8d1ed0-11be-4399-8c99-79eb97b18722",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "b8381ce7-be61-498f-a330-f5e1d26a7b7b",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "26c14c13-1717-44fc-b7af-ea5d630607b4",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "464528c0-d02d-48dc-a400-c71556117d20",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "ace14e81-e31e-4500-9114-7e6d62e41d59",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "65e26df8-c0be-4c14-ad3e-fc500b5936d8",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "c4a04498-65de-495e-bd0e-04fbece07bed",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "76236175-4db8-4407-8ab0-aa22ab322e6d",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "2019f959-4af1-4b77-89fa-ef11d67bcf3b",
    building_id: "d4c68b97-cf0f-43b0-a3f7-31a369155ed9",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "1bf27d52-8f6f-40a9-aa56-98f728c65c96",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    type: "timebound",
    device_id: "e8adecec-182a-4231-9f25-1e044ccfe5f4",
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    starts_at: "2022-12-19T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "1ed6f7be-139e-4cef-a9c9-53bf4dc9bec2",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    type: "timebound",
    device_id: "5a932c81-0af3-4cfd-ad6c-f6c3bee0539a",
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    starts_at: "2022-12-19T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "d319b0bf-0713-427d-99e6-c85e7452ebfa",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "470ac0d4-05c7-4242-aa81-c8096e493f97",
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "36a1c660-bd06-40f0-bdd5-c7c5a165f541",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "44f1355e-d504-42e0-8299-84542992b1e3",
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "35beebe0-82ff-40ef-8586-42484c2f40cc",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "0b2e6569-2bb9-404a-980e-cb9896567ba0",
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "d5f260d2-f22a-4551-b7b3-a6bbc677f122",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "1c22561f-55a3-4ec7-9cb9-3bd9f5a210c8",
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.234Z",
    ends_at: "2022-12-16T05:50:18.234Z",
    last_used_at: "2022-12-14T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "627fb1f8-faaa-4733-aabf-0d3dbc05c334",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "473f9293-07bf-44b8-8f47-5a12b239f00c",
    building_id: "241a0ce1-f0cf-4027-bc11-dd979fdfea78",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.234Z",
    ends_at: "2022-12-16T05:50:18.234Z",
    last_used_at: "2022-12-14T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "b553c356-0b48-4446-be30-bad1bb6d933c",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    type: "timebound",
    device_id: "527f1d88-7ddf-4c7f-94ab-fac8ec3d4ac1",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    starts_at: "2022-12-19T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "8cf1558e-ee4e-4422-abaa-d832f4d7292e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    type: "timebound",
    device_id: "d6cda425-a0fd-4ac4-b4f7-22270fd2d86d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    starts_at: "2022-12-19T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "6e237ccf-149f-4a5a-9888-369f5f6b2a53",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "98c2b2e0-237e-41f2-8d37-bd08b95ff016",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "2fb60959-6e3b-430e-b37e-83035e41f058",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f9cffd3e-f72e-40c6-9e64-e969f819f501",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "f6c69897-f20d-48ab-8cf4-0c14665d493c",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "8c74a765-0b50-4247-b76b-731c95f52041",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "7c5c8dcc-7c37-469b-a956-4dede1cfc18c",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "9ac88ab0-149f-4fcf-91da-b31602b6fd7f",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.234Z",
    ends_at: "2022-12-16T05:50:18.234Z",
    last_used_at: "2022-12-14T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "f624b3f6-ff29-437d-a68e-86a9b9c7f55d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "07a31679-a12f-46c4-9f23-a968db92b1b9",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.234Z",
    ends_at: "2022-12-16T05:50:18.234Z",
    last_used_at: "2022-12-15T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "5e5ab1e4-d985-4c46-bd21-8881d90f213b",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "85b9a31e-80bd-4bd0-b44b-8790d49c7c80",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "0d41f59d-241d-47cf-99f8-c827f49c9247",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d3fa96b2-6066-48c1-942e-4d46703ffe9b",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "5c06ec99-1ce7-412c-a286-50a1daf213d9",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d836356d-c6cd-46ef-94ff-82b49bb44c1d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "e056c2b2-8b96-4c96-9fc8-72e679d36dc4",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "68cbb570-8bfe-4a20-b6ac-147159301424",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "5940d61e-9b6a-49b8-a277-a6f0c65c4788",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "09f4dd1b-ffda-4e07-b1b8-da3844ad1f5d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "9be38de0-65f7-471e-85a4-9f4f4a971df9",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "2c90cb1b-536b-404b-a2b9-646cd75fba13",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "28d1b5ac-e654-4395-8c67-cfac366f7d4b",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "70c00223-7f1b-4046-8fe9-67d1eac97a61",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "8c470a57-bec9-42fa-833c-449db10c01ce",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f7256fbb-919f-4dc6-b394-2df1dd99153d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "1c2c2732-f470-4360-8417-f566bd1babdd",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "e94b13ab-58ad-4834-82d1-3bd63313e3ac",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "1adfd80e-4c84-47f4-9c85-b3e6defcb130",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "5e1ffc7b-a211-4e9d-b5d7-ecb0366f454e",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "5ea0ad55-747d-4ac3-a866-8cdb865591f3",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "c783b558-0767-46c1-9cbc-84b48177657f",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "c16507ac-746e-46de-b38b-894e2b8e0bce",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "9da91c99-9473-4a3c-a666-d036e438b1ed",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "fe58d1fc-37d2-41ec-92b3-909e20f3453e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "801810de-8089-45cf-8cf3-274b5341f673",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "7fe9f28a-18a1-4283-b4ac-1068e43ee92b",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "96e4d299-587b-4730-8c12-9f67d6f4ac1f",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "84df613c-fbd2-42a4-bf17-d1108687fe54",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "0f434638-a915-416f-877a-913cde41c7c1",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "4c7e57fe-fa07-434f-aaa4-f939ffe4068c",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "e4fb1332-f8fd-47c0-82e2-e1847f08e868",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "a0f0acdf-3fa5-4672-873c-b4eb1e9cfac5",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "c1c24546-338a-4ac6-a1cc-4b4ad6de4648",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "f13057b1-157e-438f-aeac-100e78af3162",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "95908125-0c66-45de-9caa-6fc89108e370",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "2e0c00db-a15a-45f6-b542-1500771bab23",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "85677ae9-f1c0-4a4d-8cfd-7ec469f4961c",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "57c14d26-4600-444f-814e-0226781bcd0e",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "5bc21cf1-58c2-4df2-9822-dc76656d417a",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "4c82b191-f67a-4d00-a320-086022673d34",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "abe4a5ce-5848-49e5-b17f-dfb0afbd17f8",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "8ce7fa34-c0a2-4c87-8c3c-73b5ef7298ff",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "571de548-eeee-43ec-87ea-303ea7620e10",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "05049fb5-eaf6-4ddf-8ff5-2b07b7370537",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "fc44f133-4da2-4d8c-a610-0a32ae54e56c",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "132b8b17-5129-4d05-9dd5-693391343c5f",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "5f351ced-76df-4c36-81ee-c0bfee5b115b",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "7c2af6ab-dd02-4fcc-a901-67a3f86774fe",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f8683dba-7b64-4ef2-b1cf-50636079c7f5",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "760d6309-b2d0-47f7-824e-e85c2a93cc03",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "3be21765-e8ea-4079-ae96-c88891d9ad2d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "bf653fa5-0632-44b6-a02c-5a879c52841e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "43dd62dc-b989-43b3-a5c8-a9c7bb78f0c8",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "25529fa6-c876-4152-aaf4-41fe57fc9c89",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "642aff03-cee6-41ae-a133-61f7d231b7c3",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "5dce13ad-8f2c-4fb3-a6b3-f377639bdfb9",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "3172796f-1982-409f-943d-fd28f2b20a98",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "4a077b12-1690-40c4-8cfe-58ca056ee235",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "07032891-091f-4f66-b12c-d3cbfbd6beab",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "f8066dc3-d544-4e99-b8dd-7678b279925c",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "589f5510-309a-48e8-8e53-ebc595d278f6",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "069600bb-fa46-42ac-8043-dbb325c8231e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "3b192df2-4a53-4110-9031-415304179c20",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "759030b7-ec0b-40fe-a451-681c23de58f7",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "527f1d88-7ddf-4c7f-94ab-fac8ec3d4ac1",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "22f61d37-56d2-4e86-ad9c-a04d556a2aa5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d6cda425-a0fd-4ac4-b4f7-22270fd2d86d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "41fb9640-6c7e-4471-a565-853d9b25bc6a",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "98c2b2e0-237e-41f2-8d37-bd08b95ff016",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "c5d69454-24fd-4c0e-8ad9-93c9ec05e862",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f9cffd3e-f72e-40c6-9e64-e969f819f501",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "55f3302e-a6ea-4515-960e-828e17bdbde3",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "8c74a765-0b50-4247-b76b-731c95f52041",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "7221d0bd-02e7-4d45-a1dc-d222c6872706",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "9ac88ab0-149f-4fcf-91da-b31602b6fd7f",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "6f3fd37c-f85b-4a0f-a4b7-5051b0c73864",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "07a31679-a12f-46c4-9f23-a968db92b1b9",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "a34a5b04-3d8e-41d3-b2ab-eb538a952734",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "85b9a31e-80bd-4bd0-b44b-8790d49c7c80",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "2f7bf07c-550a-46ad-923c-db9498cf8688",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d3fa96b2-6066-48c1-942e-4d46703ffe9b",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "7165dbe1-fefd-48e0-83bc-5876e59970e6",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d836356d-c6cd-46ef-94ff-82b49bb44c1d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "5bc35451-fc89-4ad5-879b-cfc14fe5f4f3",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "68cbb570-8bfe-4a20-b6ac-147159301424",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "93a9447f-c25d-47dc-978c-083718a60c6f",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "09f4dd1b-ffda-4e07-b1b8-da3844ad1f5d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "39bbddf7-77a9-48bd-a7ea-7ef7e5760c4e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "2c90cb1b-536b-404b-a2b9-646cd75fba13",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "a5a9433f-18c2-439e-9f17-5704e013e368",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "70c00223-7f1b-4046-8fe9-67d1eac97a61",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "d28b7b56-ad1c-4adc-9142-ed7e925bec82",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f7256fbb-919f-4dc6-b394-2df1dd99153d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "f7239b6f-c4b1-4b32-ba55-6889fa3dd00e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "e94b13ab-58ad-4834-82d1-3bd63313e3ac",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "04107a9d-8adf-4fe4-bd13-098bcd0b6b2f",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "5e1ffc7b-a211-4e9d-b5d7-ecb0366f454e",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "1c92d43f-f5af-4064-a9c4-2f4db0a46585",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "c783b558-0767-46c1-9cbc-84b48177657f",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "3300770f-3cb1-478b-acb5-7f078ef43357",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "9da91c99-9473-4a3c-a666-d036e438b1ed",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "f5bc527d-cbec-4e23-ae2b-21b0064818c2",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "801810de-8089-45cf-8cf3-274b5341f673",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "2f4ecdc5-a5d0-483b-8437-5e9601c49640",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "96e4d299-587b-4730-8c12-9f67d6f4ac1f",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "6bcb9784-1c20-4d84-844d-ce9366182643",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "0f434638-a915-416f-877a-913cde41c7c1",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "69b11eb9-e794-4255-bcbe-3517ffe97499",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "e4fb1332-f8fd-47c0-82e2-e1847f08e868",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "cc8e7036-499a-4a7d-b8ad-2829967c05e5",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "c1c24546-338a-4ac6-a1cc-4b4ad6de4648",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e3a52cbd-1237-4b66-bf6f-9023061f5481",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "95908125-0c66-45de-9caa-6fc89108e370",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "69e1053d-ba7a-41e2-88a0-460df0c9fbc1",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "85677ae9-f1c0-4a4d-8cfd-7ec469f4961c",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "d818bd5c-641a-40f8-88ea-da096392e40f",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "5bc21cf1-58c2-4df2-9822-dc76656d417a",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "da98adf7-7143-4be2-9487-c3cb46f68c20",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "abe4a5ce-5848-49e5-b17f-dfb0afbd17f8",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "7d5a2867-aa8b-41e0-ab14-3f266a3d82bf",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "571de548-eeee-43ec-87ea-303ea7620e10",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "9c8c4c32-eded-4e9e-899d-540397d28389",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "fc44f133-4da2-4d8c-a610-0a32ae54e56c",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "fd572cb0-b0ab-4d57-9b32-c44c5427f1c8",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "5f351ced-76df-4c36-81ee-c0bfee5b115b",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "6a15661e-01cb-46db-a1ee-237bc613f207",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f8683dba-7b64-4ef2-b1cf-50636079c7f5",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "dd267017-d8a4-49b3-97c6-e01eca9babe9",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "3be21765-e8ea-4079-ae96-c88891d9ad2d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "27a39253-306f-4c96-91db-42198226fa6d",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "43dd62dc-b989-43b3-a5c8-a9c7bb78f0c8",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "c3a82305-81e3-4c74-a248-ddac799a38f1",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "642aff03-cee6-41ae-a133-61f7d231b7c3",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "a5633d76-8ed6-4cd8-8a6f-b4e65da1f3b9",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "3172796f-1982-409f-943d-fd28f2b20a98",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "dde9b58a-75e4-40d0-91bc-6fb1c582de1d",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "07032891-091f-4f66-b12c-d3cbfbd6beab",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "9d653689-cbb4-463a-ab22-35e88b0fc33e",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "589f5510-309a-48e8-8e53-ebc595d278f6",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "477ea062-3877-4fca-954a-37f29b23dbcc",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "3b192df2-4a53-4110-9031-415304179c20",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "38c60990-4200-46bf-ba07-7ed87f39ceae",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "527f1d88-7ddf-4c7f-94ab-fac8ec3d4ac1",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "23c96755-cea6-4466-a8b8-e7257afb3476",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d6cda425-a0fd-4ac4-b4f7-22270fd2d86d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "cbd9dd72-cddb-41b5-bf58-562d7d65d340",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "98c2b2e0-237e-41f2-8d37-bd08b95ff016",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "1a8465f9-01af-45ca-b51d-77926eecd2da",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f9cffd3e-f72e-40c6-9e64-e969f819f501",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "ed1c4b82-368d-4013-a2d9-94e01c93f539",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "8c74a765-0b50-4247-b76b-731c95f52041",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "52f3c22b-cb07-46ce-ac20-620d997502b6",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "9ac88ab0-149f-4fcf-91da-b31602b6fd7f",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "82fe1f15-5d04-4dec-b8c1-12b842e5f135",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "07a31679-a12f-46c4-9f23-a968db92b1b9",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "aaa1e3ae-eab4-4168-b7f4-901bab428e9a",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "85b9a31e-80bd-4bd0-b44b-8790d49c7c80",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-25T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "24fff517-5f09-4ec6-bc9e-5a0ce2545c70",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d3fa96b2-6066-48c1-942e-4d46703ffe9b",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "1b00505a-f51b-4fc5-9ac0-2c0eb4bcc69b",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "d836356d-c6cd-46ef-94ff-82b49bb44c1d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-26T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "4d9064d4-c13c-4e95-90e0-9ce45849a676",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "68cbb570-8bfe-4a20-b6ac-147159301424",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-19T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "10be6326-96ac-446e-98ec-439f83f53af0",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "09f4dd1b-ffda-4e07-b1b8-da3844ad1f5d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "8b0734ca-48f1-4c7a-9f1b-cbb2a9a138cc",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "2c90cb1b-536b-404b-a2b9-646cd75fba13",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "afbbb9a0-9dc8-4522-ba51-6a851078537e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "70c00223-7f1b-4046-8fe9-67d1eac97a61",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-23T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "2975ca99-9886-4b7c-8e75-8d3be2ae0f8e",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "f7256fbb-919f-4dc6-b394-2df1dd99153d",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "4e3611b8-d12c-4142-a071-bea7952df230",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "e94b13ab-58ad-4834-82d1-3bd63313e3ac",
    building_id: "69f1ce59-daad-4775-a530-62cfe4a64c8b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "940e5a56-47dc-437d-a373-efd866ba3393",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    type: "timebound",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    starts_at: "2022-12-19T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "0fe84468-681e-49e4-8a10-29a57e4387b6",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    type: "timebound",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    starts_at: "2022-12-19T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "b49ec11f-1293-49e3-b2d8-27e4223a949a",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "e4d6fa7b-08b1-4a8c-99bb-bd9f0f5b54f4",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "680026e9-1a00-4e28-8eaa-1b712b352316",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "ac7fcda2-2892-47b1-80af-2fa0c1c479f0",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.234Z",
    ends_at: "2022-12-16T05:50:18.234Z",
    last_used_at: "2022-12-14T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "9b6c5e08-7772-4968-994a-709b011b246a",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.234Z",
    ends_at: "2022-12-16T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "3ed60f51-ea5d-4e87-92fb-1ff136ec05bd",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-24T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "8d812f68-a7c3-4845-af3d-03ba1fd14b7b",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "4f3bad3a-38be-4085-b64a-e7b31ab1f915",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "32ce8a56-2037-4956-b4cf-18b2026d055d",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-20T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "873eaa1a-b5bc-44c0-9121-a19cd7c57d30",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "e79b2dce-dc9a-4bf1-b772-03f9bd090deb",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-27T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "563a96c8-ca8b-4c04-98f6-5e05a3f24445",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "68559718-8c24-4443-a751-46f9eba3f5a5",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "f1447bc6-dfba-411e-9b23-54a2aae2b98f",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-28T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "2d4c0d58-8225-468c-b08b-ab8b249d7867",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-16T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "81b6d156-ea06-41eb-ac83-43913a1c088e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-22T05:50:18.234Z",
    last_used_at: "2022-12-17T05:50:18.234Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "a240457d-2025-4b67-92b3-d7801724721b",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.234Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.234Z",
    ends_at: "2022-12-21T05:50:18.234Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "efbdddcd-7528-4fba-a65a-b96483265ac6",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "fd344cde-c9af-4f11-a0c7-023b20a2201a",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "4a9fc0db-0543-4023-9eaa-e4c7ea1d5e59",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-25T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "85f6441b-f9c2-470d-9030-d6161aa2bdd6",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "66a71a91-cb3f-48c4-93cc-80f6b6f83e84",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "0a629b84-13c4-4c96-b9f9-eb28a31d3d62",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "140faf6c-179b-41cd-9004-fc4139542edf",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "65447d90-3f10-420d-ba09-444db84195a8",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "81890a07-203d-4fef-9328-cd996451d392",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "99aa95f3-fb59-43d0-a165-036dc7f6b58a",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "b94f3316-d6c1-450c-b470-46fb37820fbf",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "56edbe9a-49d4-454c-ab36-ab36175547c8",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-25T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "29bee048-3169-409a-89e7-eb544e6791ec",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "08302064-1340-4384-84da-e298e61cf47f",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "aa2bf176-f4f7-4da7-9bc8-94860defee61",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "64d915b9-1910-47d4-a586-0ae971a74dbe",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "a0991967-a071-42fb-90f6-51fa35e3e1bc",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "3f2ad30a-760a-4893-b060-05488e97e61b",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "08162d14-709f-453f-933f-19834d94d913",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "d869dc33-a587-40d5-b0f1-e0d6f1955ca9",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "8d8270a5-ab24-48e2-93ad-bb3d9084ed3e",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "4848a0d5-b7fc-4e5a-a501-221eeda6c2a0",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "104e6447-ccc8-4006-93cd-82b6e31d524b",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "37d81f77-308d-456b-a79f-7a4200f3bd7d",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "84d5466e-e7f4-4f0d-9f1f-16e9f290f7bb",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "7a297bc2-bedd-4556-bd8f-28f9853e2ee4",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "184ffb0c-b347-41df-be9a-3d1b95e37868",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "82dec869-6ec2-4024-8015-fa6e190bdbe2",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7d176703-4e51-4444-a414-41d49358569a",
    building_id: "27816c08-658c-4c86-83ee-fde8a65ac669",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "ae7cda79-cf88-4f6a-8b3c-cf815abdc950",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    type: "timebound",
    device_id: "652e074b-91f1-4865-8cf1-e80cf2ad9e0b",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    starts_at: "2022-12-19T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "6676cc52-c340-4960-bdb6-6d4c7e91e6bd",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    type: "timebound",
    device_id: "e553d62a-4ab0-4d02-b438-ce91a2810b53",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    starts_at: "2022-12-19T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "35694fd0-021e-4ef9-b0a6-b74597fced68",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "8b6c751c-bd53-42d7-8abf-6820b934032a",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "e2a01da5-65af-49d3-a2ec-f846891212ef",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7495ff13-1209-4f37-8477-bddb08ab8eb4",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d1b68868-5f7a-40d1-b700-d951890ba377",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "9d49e8fb-0770-453b-bfc1-044acb4aea43",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-25T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "3889bed0-d321-4bec-9edc-805cf64da299",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "0a1d5a4a-8d01-4f8a-b38e-1e04d670a8d3",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.235Z",
    ends_at: "2022-12-16T05:50:18.235Z",
    last_used_at: "2022-12-14T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "0d52e248-3370-4b93-adeb-3395845e8a60",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "aac78e9f-9532-4eb9-bee8-d7085781f65e",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.235Z",
    ends_at: "2022-12-16T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "b21f91d8-daa2-446a-aba4-6aa1871a515b",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "5b48dd67-ade8-44d3-898b-fdbcf8fb98f9",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "94b1e3f1-e56d-465a-8966-0fe8fe3c7722",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "3e3ac0ca-3422-4e39-ab1e-5067e001677f",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "bafc63c5-cc5a-4a7c-ad1d-b775e6bb2627",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "9fef663b-17d8-4cd9-bcb6-4087a042cddb",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "b7843545-e1d6-409d-b3af-960425bb5f59",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "5672e41e-34be-4a2a-bdcd-4350fce4ce08",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "de43aa74-2bf3-4dcf-b1ba-963543488cc6",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "5b8612ea-2053-4d6f-a3e2-ddd09b824a5f",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "a3359f52-fece-4572-a1f7-dcd2c0856894",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "f233d23b-4dd2-4806-9897-ad545a4cdaf1",
    building_id: "fe38801c-b4c0-4e11-8195-e6f96bdac092",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "c651130b-136d-4588-aa91-d5f5aa271096",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    type: "timebound",
    device_id: "aaa189fb-6774-488f-84e4-1da3b9efd7b1",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    starts_at: "2022-12-19T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "1eaf4ff1-5917-4bbd-b626-6ea34313bf38",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    type: "timebound",
    device_id: "654b3a0e-9b99-4580-98ab-64bc82fde1dd",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    starts_at: "2022-12-19T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "16a3c630-a93a-47a9-a113-1bfd47668360",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "edbe997b-21b7-4567-84b8-b55beae1235c",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "236686d7-d7e2-43db-821b-c51832e0d60e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "fc3e3e00-5cb5-477c-93b1-ceb78a9b779c",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "71ce728b-1c8e-447d-a863-40803d2095cc",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "6e27fff4-9b76-461d-96f5-bb17f59d52d0",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "b850e6c0-1fdc-4b70-950b-f514202d68d2",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "4e8f45a1-a1b4-4016-9e03-a740537aaa48",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.235Z",
    ends_at: "2022-12-16T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "f4761d25-aa69-423c-b5b1-7e04e0993201",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "0e21dd78-7473-42cf-b9a5-264d29d07590",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.235Z",
    ends_at: "2022-12-16T05:50:18.235Z",
    last_used_at: "2022-12-15T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "96d3d769-b371-4f0d-8b86-be2f68b24d87",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "6be35199-e977-43da-9c1b-25c1a880bef3",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "196f76b5-27bf-4e83-9b84-13cf6f268d2d",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "242095bc-c82a-4028-8a31-9f75a03b795d",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "fb122819-e20c-4220-a4e4-bb0be92a1d03",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "c03a1640-9187-4760-bb1c-f623cdf0aaee",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "ce042544-18e8-4edd-8a8d-3f4a9ba54b21",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "274d6ab3-b780-477f-9884-52bcba87165f",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-25T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "e7e32dbf-9c3a-4631-9b9e-37e7e6a5d363",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "0594c332-5bf8-42f9-98db-e445813276a1",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "c6063c71-8b10-48b8-bad5-bd86b5919bb3",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "4000aead-a6e6-4e37-b421-3456093ea203",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "fb706d4d-913e-47ea-b60d-3fe5e8aa6f6e",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "0ef5b8a5-cc72-4776-b464-c79111540c77",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "3446a26d-1622-4342-9a80-e437cc90f1d3",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "0ef9c7d4-7c53-4dad-99d6-17a6ef2d7ec8",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "7c5995d5-b180-462c-837c-75fc82154317",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "2c75480b-5842-43b2-9089-c2d3d2bf1f90",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "9c1ef434-7b3a-4895-8afd-e108c9e1e673",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "edc8adee-329c-49b4-abad-14355bdbd40b",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "a08abf27-bd19-46cd-8db8-06a6935cf978",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "6fce444d-d86d-4f59-8601-a7ee1b2608bc",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "8fb3d65e-3970-43d0-a724-904f91ddcfc0",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "02812cb1-9e2c-4777-97e2-7e69e5d32267",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "cd4cc037-1b28-48f9-b479-56654e089cf8",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "2633079b-591f-4685-9cb4-5834806a7260",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "fcee6faf-f17a-4b96-bf06-c53717fd1d5e",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "60f5facf-9046-4c6f-a646-3a76f3c695fb",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "b63aae0f-a99d-4b68-8390-b944fd8e616c",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "95a308ac-6d38-4a5d-bfee-f41b75e27f53",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "b046947f-a5e6-4d05-a60d-0c5e7bef218d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "11924758-f5e1-43e0-b803-83198771e846",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "9f92a05b-3c51-4eb8-95cf-b7190de601d5",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "4c5deb90-1782-4684-bf4b-01d905ab9fb7",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "1abe2a31-3236-4edd-b0bf-954b164ffc71",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "607e2960-c020-45f6-91d9-687658485b3d",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "42e5c09a-2948-4e3d-9a04-cf1a2867ef16",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "23b0249e-0108-46fd-988a-55a95e9a491f",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "9132c169-0f8a-4345-9543-f26f8cbc8b0f",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "2a527812-1a03-44a5-b475-9c858c6d9888",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "2e3560aa-b1d6-4792-9c9b-b62e0cb009c3",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "5f5bca31-443d-4bba-a11b-7d5bb1a6003d",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "4fdc1b04-4f20-4ff3-9ada-df12633e705d",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "ac6f4f84-37b0-480c-8045-d19965c8a2ca",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "394e685a-eeaf-439d-8a15-5230402ec3d7",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "88c6f393-7578-49e5-ae3e-85fe3692f2c5",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "c37d0b67-89eb-4315-8211-0a11c71425d0",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "886a2387-802b-48e2-ae8a-f26a14e20eea",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "07230369-6ea4-48d7-872f-a6d27cc009aa",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "e71b6d41-64d4-4a5e-9bc3-69b6e873f0f8",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "5ea177e3-0603-49e6-a38f-4932b4e76e57",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "d3da506c-3f2f-451f-86d3-f55998764799",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "fba324ea-159c-4cb4-abb5-484972d6df67",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "70752a3d-e0d7-4c5c-ad59-fb09ae516255",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-25T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "dc270587-9da5-4ce1-8917-1f7d1a74fbf0",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "bb4ee90e-ec80-47e1-82e4-a7a64af35293",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "9e280368-1502-4fe3-adee-70797dbf7976",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "627d80cb-9abe-402c-9f26-6993c1faf713",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "31027bf7-4193-428f-83b5-8fc1d6b23760",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "62804da9-08c6-441d-a968-4e1fd70a9508",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "ea0d2372-efe7-4acf-bdbe-f887256292d9",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "3f881583-80f7-4a69-a4a7-65bdf9bc73ed",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "2d77258a-3e42-4b93-af52-f631f2b364bd",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "429680d7-7b14-4baf-9fc4-86c1bd6fa3a4",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-28T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "e450d60c-43f8-4e42-8aab-ab722771d62a",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "3d438494-0ad7-4e10-8eff-9c00200f32bb",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "d385b0c0-eba3-4d2d-a7a8-b73b529571c1",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "e01f6b25-deef-4279-a856-57f60c99253e",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "5c5eae9e-34e5-4cdc-83e4-1fb7817b5f65",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "db4f9532-b1d8-4183-a20f-3afd8f5462b9",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "7c466386-6e90-4692-8c87-d7404baeae8d",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "57d0ed77-5b5c-408d-a36d-f9f02585a35c",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "ee31b9e8-0d70-4621-8774-6148d9fe4428",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "45cc30aa-5d30-4d01-8986-90a38c548e94",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "226a1e6f-f5cd-462a-b61f-0d3a2f9b86e9",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "1e6bb352-02c4-4d11-b946-7b4636fec969",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "8bb7ab70-4f14-444d-8c49-3591837fa245",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "3febdb72-58f1-4977-bc45-87c22f96e154",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "12793819-af20-4279-a0be-c4ca3938f2f0",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "54d350d9-c27c-4598-bf72-f1dbe2d3dfe7",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "67ff9118-3e61-44c1-b592-6dfd2d944e64",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "16aea885-f3f2-4f18-b3b5-d2d9bf56cdfb",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "04d3768c-b862-4226-934b-a499fea69750",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "7338c3e6-6033-4ca3-a21c-8e7621b97f62",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-25T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "0f6ba416-1057-4ccc-8dbd-3efd349f2fb3",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "9de5eb7c-f4b3-4d15-92f2-7b62a3596f37",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "ccee3c3f-6a59-47c0-8306-b2d2124f1925",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "a55dbcf6-f02a-41e1-a65e-e5350e56cd05",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "52cd5e74-5114-4391-848a-2ce878998009",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "b6085a83-df09-4cdf-8da8-844cfd950da1",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "02c2d383-ab90-4a0e-a937-3ee63e318a0e",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "86e4df3e-9c6b-4a6c-9c47-f3d67edd8959",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "57c0e430-97f6-4ef1-ae60-2b29f189d635",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "9bc613b9-2537-4d2b-9c0d-16dda75e80a4",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-23T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "8bbe3381-9a16-43f1-bea5-ec167ea9e649",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "f00c5077-4ab0-4988-9098-0e92eefe11d7",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "afe706d8-2f65-4ba0-9c82-ad3c563101b6",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "2632f53c-fd88-4662-b17d-d81e0ca31a96",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "b5383f23-83ee-4872-ae5b-401b31422eee",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "c8883cdc-40ea-42ed-8923-adf370b5c923",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-20T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d79cb26b-60c4-4d92-a6fd-96d454d5c714",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "8efafc94-54da-4733-a681-da575a86a5be",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-21T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "70755d8a-c9a2-4dfe-90a2-81b01342eb78",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "5bd15193-0344-4e1a-8bc0-c456697fe355",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "fe68a07e-a139-43b4-b93c-7572d8b1db8e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "c67a3cdf-17c5-46a6-8730-d5f6e56c0d24",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "57c8f493-d9f2-4bcf-878d-ad2c80000759",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "6f397c03-235c-4529-8679-72ec8245b3cf",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-27T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "99837c00-8b6c-43eb-a4f8-c254231b75c2",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "a831d538-7694-48c1-8d71-093bde33cf88",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "36b075ac-24b1-4edb-9c43-ae139feb1239",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "73a2ed24-435c-4a55-8f8e-97a002900bf6",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "6a61a150-7ce9-4caa-8a8c-47ee7f92007a",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "fabe1a27-3d57-47b5-aa42-d449f3e21a1b",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-24T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "6b85242e-2970-439e-acf9-bf39e52fb4fb",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "cc7c600f-80a4-4501-9842-25888a8e40a7",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-22T05:50:18.235Z",
    last_used_at: "2022-12-18T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "0c3e8f4a-1a3a-4f91-be96-ece9807cc979",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "0074b149-ac50-4e67-a447-bfd856fc673e",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-26T05:50:18.235Z",
    last_used_at: "2022-12-17T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "2b6c8a3c-fc5d-4c54-a522-8f8ea27d3524",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.235Z",
    code: "1234",
    device_id: "44da5ea7-160f-400c-8435-91806cedcc09",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.235Z",
    ends_at: "2022-12-19T05:50:18.235Z",
    last_used_at: "2022-12-16T05:50:18.235Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "ae76c0fd-7683-4e92-b2ec-09df5d89aee2",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "aaa189fb-6774-488f-84e4-1da3b9efd7b1",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "382c7773-6efe-45ef-99d1-41c5cdb75489",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "654b3a0e-9b99-4580-98ab-64bc82fde1dd",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "ae484e79-6c69-4e5d-af59-3417a68cc035",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "edbe997b-21b7-4567-84b8-b55beae1235c",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "6c97b10a-a929-48b4-b25c-23cb06247eac",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "fc3e3e00-5cb5-477c-93b1-ceb78a9b779c",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "c20eed21-303b-4f09-859f-e4a43985abe9",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "6e27fff4-9b76-461d-96f5-bb17f59d52d0",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "435cf3a9-324c-4873-9145-4905a487b1fa",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4e8f45a1-a1b4-4016-9e03-a740537aaa48",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "edea4cc3-fe64-4e6f-add8-174615b8bcfc",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "0e21dd78-7473-42cf-b9a5-264d29d07590",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "d51fb1ad-1a8c-46dc-8abf-6502c2b9725a",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "6be35199-e977-43da-9c1b-25c1a880bef3",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "1202c123-b234-4449-af62-d76e773cde8e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "242095bc-c82a-4028-8a31-9f75a03b795d",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "6dd82cd4-4754-4d30-9d9c-89a264dc1015",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "c03a1640-9187-4760-bb1c-f623cdf0aaee",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "d2fa083c-dac8-4594-8662-5b4845075024",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "274d6ab3-b780-477f-9884-52bcba87165f",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "c9e6f9c1-9d60-4f32-8bd7-e82a9a8f0179",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "0594c332-5bf8-42f9-98db-e445813276a1",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "e51badb9-ac03-4814-ae72-13962739c940",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4000aead-a6e6-4e37-b421-3456093ea203",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "5063ed68-e87e-4e64-bcc3-73b7ebec6531",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "0ef5b8a5-cc72-4776-b464-c79111540c77",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "ee6352d7-ac12-47c1-813e-9fe4274ec868",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "0ef9c7d4-7c53-4dad-99d6-17a6ef2d7ec8",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "191fc3f3-2177-45ec-8bb7-15a6d212eb7b",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "2c75480b-5842-43b2-9089-c2d3d2bf1f90",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "3156afa1-6c33-481b-9e18-04c5c7367344",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "edc8adee-329c-49b4-abad-14355bdbd40b",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "a4c055f2-9522-4d6b-83ca-3c1d6a922ee6",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "6fce444d-d86d-4f59-8601-a7ee1b2608bc",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "022230f8-a094-47c8-9b79-01a4af9728fa",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "02812cb1-9e2c-4777-97e2-7e69e5d32267",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "39acc7ec-ed21-4ba5-ad81-e411a6804271",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "2633079b-591f-4685-9cb4-5834806a7260",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "e7ce4850-a64d-4af3-9a91-6b2b3070b1de",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "60f5facf-9046-4c6f-a646-3a76f3c695fb",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "1aa485cd-3180-4710-ae64-c19722678a5a",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "95a308ac-6d38-4a5d-bfee-f41b75e27f53",
    building_id: "227daf76-35d8-42d7-9068-14ef0c4a056b",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "b956d2de-b62f-4e7d-b5fd-dccb07ff1b17",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "1efb6f7b-6e3c-43e2-8446-0f1cd34a0db1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "a47ee552-4e25-4d3d-89cb-279d7d7fdf9b",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "790de092-8303-44f3-9397-2874b3a2dfe8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "5a7ff934-30fb-4d58-8e0d-564963ba3857",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4fae1dd1-d131-4cda-9dcf-0af6054ff1cc",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "5b14a4b4-4bde-4157-b440-b780acaaa295",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "cd968a6b-b531-4caa-884b-e414ce2be398",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "be25370c-98d7-4903-b98b-7558c9a62b8d",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ea125a83-0f8b-42de-8dca-af9f844504d7",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "8f8cceaa-0380-49de-b900-d4263673e8b2",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "c1753a8b-d22c-4fb3-98e7-6935ee4f3511",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "f475b6a4-8e77-4e1c-af82-52256b322855",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "18c53769-4c36-4871-a062-91e36ff026e0",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "cdeed803-e50e-439c-981a-786763ff7cf1",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "e3cf8d32-e511-49bc-81d9-d17257c9689f",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "ea0b4a2d-ab47-430a-8e97-e4008d3a6ee8",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ce291b4b-5183-43d5-a23c-c2c9dd49d204",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "1c5bb860-c585-496d-84b5-7f9ab089e8f1",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "73d80b6d-52bb-4ce4-8153-3016795afbb1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "797991d4-43e9-4e40-ac42-e22b48138f37",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "074237ee-5fc9-407a-84c9-560f683e906e",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "b54efdcb-7b5e-4046-b68f-f993e1748433",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "aadd6c5f-18e8-43fe-b67d-88be7c451fa1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "aa9612b1-37ec-4303-b9c9-405fc280d19f",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "24109c89-e85b-4142-80e3-14625beaa219",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "82a2894b-1a1b-4b0c-bd7e-bc4f8077ec01",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "640c9489-65ea-4c9e-b085-d5a1f187d15b",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "109062f4-e321-4eb9-9004-d12a245d9911",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a1be7d36-d1d5-4de3-abce-06db9bf754ee",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "1b080968-b562-467c-9aa1-7f7c8f5e48f4",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "340d4d9f-9664-4240-bb03-47336e5c5408",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "21c8d5a5-eb73-4545-a251-aff75f6e278d",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a69420df-4a6f-4054-af8e-47d5fcaf4c83",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "78af34b3-b93d-4b69-99ac-528d179f7b44",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8682c06d-ab4e-4822-90ed-1b687c6caf9e",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "d4b57a98-55ac-43f3-b59a-4de2496dc10d",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4e2a3d77-5f59-4b80-9613-8baf74e0d949",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "00684c66-d4c4-440e-b532-010a22d859f8",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "2f3ecdf6-4d61-481b-8d00-19edbe31d319",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "23ff84b5-8d84-45c2-b40c-2a047460f780",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "abf056e2-bf14-4242-84f6-a16c1108a6b8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "9be06b2b-e277-4a60-9cd1-dca0abd3c929",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "74c4ffc4-23a3-400e-9277-40e27cf51917",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "332a2b31-6489-4bf8-93e1-a3cdd262d604",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4d930b36-242f-4686-8ccc-1282c49f9f36",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "a060ec97-75f4-467b-be06-fbfbda0ff070",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "040cde3c-f150-460b-b45c-db17ec1b46ee",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "632ef8e6-f758-4e9d-9e5c-0e1900825213",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "bb629ade-d23c-46be-abd9-227a95a705a8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "45c946e7-bd73-468a-b6c4-0a13675f3937",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8cd75a85-ed1e-415f-bfa1-e58e261695cb",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "763c89af-c370-44b8-a18a-1c5f5e2e52f3",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "bebb0024-89bc-4b5f-b188-25b93da73dd8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "a040f5ee-caf9-42a8-9afb-5023067b94dc",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a869379f-65b8-4d0a-a169-7f076f8324f6",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "47078bbc-ab2b-4c14-83bb-d062b97b7e74",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f8bdf069-c5a9-45a9-815f-33868ced10d4",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "a11e7c79-2b60-4b39-92c3-bfdc32f9afa2",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f0421162-e471-47dd-af03-394dfdcad2c2",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "3658085d-63d1-4683-82d5-a4b8686ccb44",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "1efb6f7b-6e3c-43e2-8446-0f1cd34a0db1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "84ceeff4-f893-4ab5-b682-273096230e5e",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "790de092-8303-44f3-9397-2874b3a2dfe8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "9a065592-2b3f-457f-8267-80e42e96dcd0",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4fae1dd1-d131-4cda-9dcf-0af6054ff1cc",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "b7a288d2-df36-48f7-9df5-eccecdbcfaa8",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "cd968a6b-b531-4caa-884b-e414ce2be398",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "98c86b27-f0d3-4396-b66f-fb5fc8efa92e",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ea125a83-0f8b-42de-8dca-af9f844504d7",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "9944db09-0127-493c-9230-7ba1e591687e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "c1753a8b-d22c-4fb3-98e7-6935ee4f3511",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "753586b9-3181-4126-b849-d4832fcff668",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "18c53769-4c36-4871-a062-91e36ff026e0",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "907c01a6-967c-477b-a6eb-36216793dfaa",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "e3cf8d32-e511-49bc-81d9-d17257c9689f",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "f55b56fb-dc89-41d7-93b9-40e8b65f05c7",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ce291b4b-5183-43d5-a23c-c2c9dd49d204",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "da0281c5-cf8c-4296-b0bf-5463ea249fe6",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "73d80b6d-52bb-4ce4-8153-3016795afbb1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "e53e4e17-d045-4b1b-bb35-d75493ac3ead",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "074237ee-5fc9-407a-84c9-560f683e906e",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "ce7d714c-b82e-4626-ad8f-b741e2662d33",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "aadd6c5f-18e8-43fe-b67d-88be7c451fa1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "b9f89e3e-3087-4074-84b6-3633f793d367",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "24109c89-e85b-4142-80e3-14625beaa219",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "8148123c-2501-4fae-aec0-13cec9b831c9",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "640c9489-65ea-4c9e-b085-d5a1f187d15b",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "aa4d6907-78c8-4d74-b62d-aecb5e1f5592",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a1be7d36-d1d5-4de3-abce-06db9bf754ee",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "1b456654-0364-49e6-838b-e60f78cc3460",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "340d4d9f-9664-4240-bb03-47336e5c5408",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "fa324f6a-3484-4d47-8514-fa172ee795d3",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a69420df-4a6f-4054-af8e-47d5fcaf4c83",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "0dda72c5-b2f3-42cb-a8e2-4bb75a0200c1",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8682c06d-ab4e-4822-90ed-1b687c6caf9e",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "2520c512-04f4-4698-8cdd-11e69f77dbbf",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4e2a3d77-5f59-4b80-9613-8baf74e0d949",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "0f4aabeb-e373-4c88-8ee0-5ea62d2e112d",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "2f3ecdf6-4d61-481b-8d00-19edbe31d319",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "ef17f0e7-ce5b-42d9-85e7-80ff6d7a9b93",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "abf056e2-bf14-4242-84f6-a16c1108a6b8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "840f44f7-3657-4c02-ab22-4c86caf46a8f",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "74c4ffc4-23a3-400e-9277-40e27cf51917",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "601b4ed5-6f86-444a-84be-9cd5d59d85ea",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4d930b36-242f-4686-8ccc-1282c49f9f36",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "a62d9606-2e73-40c8-9102-91eb8316ff1e",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "040cde3c-f150-460b-b45c-db17ec1b46ee",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "97229961-7831-49b7-a345-8a3137be437f",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "bb629ade-d23c-46be-abd9-227a95a705a8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "a4f1d334-444b-418a-a88f-e6b281157c42",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8cd75a85-ed1e-415f-bfa1-e58e261695cb",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "87410f04-727c-481f-8a24-137f4a3b4bad",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "bebb0024-89bc-4b5f-b188-25b93da73dd8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "7d482d3c-00db-4f8d-8f36-48ad3cfb3828",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a869379f-65b8-4d0a-a169-7f076f8324f6",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "c87b3b0e-3f75-417b-a77d-1c6cb1387512",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f8bdf069-c5a9-45a9-815f-33868ced10d4",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "1a9b3590-1ac3-4108-a2fa-c8f233152593",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f0421162-e471-47dd-af03-394dfdcad2c2",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "b61e34ee-3a54-4055-9238-619279ade0b5",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "1efb6f7b-6e3c-43e2-8446-0f1cd34a0db1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "75f1a00b-37cb-4eed-afaa-62f895ff6299",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "790de092-8303-44f3-9397-2874b3a2dfe8",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "5caa1c24-9ff3-4a92-8af7-8d0c99246162",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4fae1dd1-d131-4cda-9dcf-0af6054ff1cc",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "79ce25a9-dcd6-4363-b18f-be8b7cecba8d",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "cd968a6b-b531-4caa-884b-e414ce2be398",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "beddc881-d110-4343-97da-98405dd8bf54",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ea125a83-0f8b-42de-8dca-af9f844504d7",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "67e64445-108b-4c57-a62b-78ec68ee729a",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "c1753a8b-d22c-4fb3-98e7-6935ee4f3511",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "d2964c33-5ec3-4aa2-a48c-82c1c1c68615",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "18c53769-4c36-4871-a062-91e36ff026e0",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "186e1155-a4db-4a5c-8e1a-b8fa1f5e2fc9",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "e3cf8d32-e511-49bc-81d9-d17257c9689f",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "44599db0-8ded-45bb-bd01-0e473ac76d36",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ce291b4b-5183-43d5-a23c-c2c9dd49d204",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "b29e3d5d-4bce-49dd-9764-745d775ace44",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "73d80b6d-52bb-4ce4-8153-3016795afbb1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "14668f59-848d-4d5d-a811-f72e6c02c83a",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "074237ee-5fc9-407a-84c9-560f683e906e",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "7facbaa0-9ce3-4613-ab86-6ad17da82986",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "aadd6c5f-18e8-43fe-b67d-88be7c451fa1",
    building_id: "8ad13286-17c9-48bd-a60f-ed6be3ecf057",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "33727032-a843-471b-b109-3ea7acd984f6",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "075c2687-469f-4f91-ae62-b0eeec71ce4c",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "a9782444-660d-4019-8f09-201f6512b77e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "fd0e5cf9-741b-4279-aa15-7446588d8418",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "1ba629dd-17f3-4cf0-8c3b-e3838982f82b",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "3d166f7b-4f52-4566-a3f8-ae0d64759643",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "2b41ab7c-b69c-45d1-83f7-c627bc7ce62e",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "2bd99808-3375-4860-b776-efc3d9f991fe",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "23012356-4d23-4e47-866f-09fed6bdb9ea",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "03e1a824-1619-431f-9159-9eef34baf7c4",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "46245eaa-c720-49f7-8744-043881f9366a",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d6ecb2de-b8cc-46d6-a380-921387806a6a",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-14T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "c6215914-ed90-4ee1-9a5f-a43a68b3275c",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "3c94b133-395d-4127-a649-1efe752cd2d0",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "57f31a30-8502-4bbc-8225-272cfe8f50b7",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "109c9712-97b6-4868-801e-ae6d53f01099",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "2c4e13ad-44e6-4c4b-a264-128153e30906",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "235afd9c-35d9-4a38-8ce6-0e713337655f",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "2c3b1c93-24de-44bb-9dde-afe9f1f00d4e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8fbc544c-d421-4bae-9e68-47aac1db1eca",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "4a83731b-8e23-4d9e-80c5-28363ab08e7c",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ea5f6060-09f6-4467-b87a-74863ec710db",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "2ef32d27-70ee-4748-84d7-7654c525638a",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "fe359c52-acf6-4212-afd9-d394364e01d2",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "bfe1d55e-7bcc-4e5f-9a35-498e78471ad2",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a308e4ec-4a16-43a8-9b98-b4f78d682aa0",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "2fe8ccbe-cdd7-4c22-8d04-f81601a351cf",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f485bbb3-d44b-48b9-aede-853bfc346812",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "175c690f-3ebc-4895-8206-55448e9a5c7c",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "7dc729da-f0d7-4e3b-91da-71d3250f55ca",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "c43aae2c-dc8f-4edd-920e-0e8fdbfc6841",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "7914ea4b-81bd-4129-8d91-7afabafd3312",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e2eb9e82-2923-4b53-9f63-1ac4a5e9f15f",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "09e98216-96fa-4ccc-8e33-168eec30dc75",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "594208d8-4532-4f8e-9d36-1b9f9bf1270d",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8418f2e0-3b30-4db3-8814-aa1173df8e3b",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "c585bf47-46c5-49f7-8ba9-7f4655c73cc3",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "de36ecc5-d03a-439e-acbb-e5cb6a02398f",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "616fb363-677d-491e-82a6-ae3c8e6e62e7",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "483098d8-e0fc-49ea-a42f-39e156229c39",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "ebee2d05-57ed-4972-ab52-6e7a2fe5ef75",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "0ed06d38-bba7-441e-8335-05fa5bb6c5d6",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "c7688599-3b34-4ea0-b03b-385f941fe711",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "c0016ae5-549d-41f5-bd03-f3ead7b70edc",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "a30563a2-e4d1-4787-b391-cd28e89ea6dc",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "88f3aa22-7789-4efb-b462-6f0d9232933e",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "1cb7d53e-4a77-4e8c-8156-fe1753cb40d0",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "07376b2e-7739-4271-96f2-c15981ca8ae4",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "a038b0d7-0bd9-4ed7-beaa-b93f053c6b64",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "bf476594-6c69-4973-8abe-d8495c34f64c",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "945abae4-5902-4734-b630-2908a256d3ad",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "368ea69c-e488-4b96-8579-6e4e82ac3096",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "89d33428-b301-4d95-8e95-34b63e8d7f53",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "814826fb-6685-4168-878f-117f1535cc4a",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "b0cb3198-ac68-4b07-b762-19298f404de0",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "075c2687-469f-4f91-ae62-b0eeec71ce4c",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "3e3a2051-af8a-4ff0-9f6c-56c915ae94e7",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "fd0e5cf9-741b-4279-aa15-7446588d8418",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "153edee1-b86a-4989-8b94-ac4913a3587f",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "3d166f7b-4f52-4566-a3f8-ae0d64759643",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "32f6e1a4-184e-4cd2-95b8-4f6889948253",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "2bd99808-3375-4860-b776-efc3d9f991fe",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "5d9e2127-f7f2-4803-8627-74f82cbfb7fd",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "03e1a824-1619-431f-9159-9eef34baf7c4",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "f2e0e371-1285-4392-b2c1-306182857fcb",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d6ecb2de-b8cc-46d6-a380-921387806a6a",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "54132286-7c9f-410c-aea8-0bfad36c3cf1",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "3c94b133-395d-4127-a649-1efe752cd2d0",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "d36ba993-a635-4a0b-863c-3855949c7297",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "109c9712-97b6-4868-801e-ae6d53f01099",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "3a85c920-0dd0-453c-abac-fcfaa950edcd",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "235afd9c-35d9-4a38-8ce6-0e713337655f",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "16efd5d0-b56c-44cd-b292-68a0be863784",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8fbc544c-d421-4bae-9e68-47aac1db1eca",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "3dce9e4b-f3b3-477f-8e9a-e8fdd3520f60",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ea5f6060-09f6-4467-b87a-74863ec710db",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "0a2d8f27-5d26-4bb2-886f-5f4d35eba0c0",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "fe359c52-acf6-4212-afd9-d394364e01d2",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "4a6abd8e-edf1-410e-97a1-22fc21013d0c",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "a308e4ec-4a16-43a8-9b98-b4f78d682aa0",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "55cbd878-a173-4890-b053-f64c0119276e",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f485bbb3-d44b-48b9-aede-853bfc346812",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "cd9f8a09-010e-4e0b-b328-03686adb0ed7",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "7dc729da-f0d7-4e3b-91da-71d3250f55ca",
    building_id: "c9450aa1-276f-496e-b333-dea0a6b2fee6",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "1b9dc7cf-b6b2-4e6c-be9d-b5770c657e77",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "4e456c03-e2b3-4e57-80dc-ed64ef1c5ba2",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e00919e9-6554-46bb-98cd-fff4461b86b2",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "42fa1af4-4447-4d4d-8867-3c836704b7a1",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "729d1513-9767-4b4b-be96-34d1d554d1a8",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "13f9a58b-af92-49cd-9a4e-11d67eda4c00",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "bf1abbe7-612b-41d7-beff-e97d88e1903c",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "55b844b4-6530-4247-b31c-2d63e5adaf8d",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "55b04f2d-5625-4870-bb65-bff5e9ddc6db",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "9cc0bc5a-f9ab-4d37-bc3c-52020f60d035",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "1a9ea3ee-da4d-491f-bf11-d498db2c6329",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d2448623-5362-42e0-86ba-cc0db7d7bddf",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "54f84e37-103d-44ea-80eb-8056508c4714",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8a6b5929-5dbd-4d8f-9822-fd0ab4081ab5",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-15T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "9e9f7d5e-193d-4d59-bc90-3e009b3159f6",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "b7be540a-8eb8-494c-ad77-d3b69186e262",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "fa79e898-c1a8-4064-85d3-3827b81fd2ac",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "76696d82-96ba-4d76-8348-577783371845",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "6ead38ca-e8dd-40aa-9690-b3ada1cd1bbe",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4c1cdd40-48cf-4aaf-b31e-c57cd8145041",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "881858a1-309f-445f-80fa-1166d1135b13",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "e1578a4a-9c4b-46c6-aa7c-bca733e640b2",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "6cde38d8-ac88-4d0d-a9d6-7bb8132b51a7",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "6068312f-be23-47c3-b63c-1255ebae1f37",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "f1d6fa3c-cf85-49c8-b2e0-a80e8b12612b",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ce659449-af98-4d0e-9866-4b9e6f047bd6",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "564e021d-64d2-461c-b2f2-69615d6e82d0",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4a34dac2-85c5-46d9-8a5f-ea150990bdc0",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "52b4df0c-38e2-45bf-85e3-fc274e7bc414",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "5472a410-bbec-426e-9b3c-15653bf936ec",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "d6c543cb-5cec-45fe-b604-a5e2f104f00c",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "b38b285c-80cc-486b-9844-2a81357eb0ae",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "d2f94ffa-5be2-4587-90db-c2b7401c566b",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "77f3f379-2a1b-49de-9de9-f46741639446",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "ddb12686-ed95-4ae5-a172-1692351b19e5",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f4a39294-0412-40ab-8542-1369b6faa4ab",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "c40180db-0412-474a-ab7d-b8c7a04ba2e8",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d755b97a-49c3-46e9-be3f-7f2a541459d3",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "8f5d03f5-6936-4d8b-b666-557f4844f682",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "3cbd3a1f-60fb-4176-b751-3ab3ec9692fd",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "0676f0a6-20ad-4847-9aad-e2931deb5566",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4e456c03-e2b3-4e57-80dc-ed64ef1c5ba2",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "65dc61a2-cec6-4d46-b80d-13fd3a36a05b",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "42fa1af4-4447-4d4d-8867-3c836704b7a1",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "6f1e1daf-89c5-41f6-8adb-88c3537a561e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "13f9a58b-af92-49cd-9a4e-11d67eda4c00",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-19T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "63e06d2a-fe5d-4f59-82d0-9e26583f28ef",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "55b844b4-6530-4247-b31c-2d63e5adaf8d",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "dcb7e619-1663-44ab-8495-485b6b180254",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "9cc0bc5a-f9ab-4d37-bc3c-52020f60d035",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "95683a71-a1a8-48a8-b838-10b46cd03286",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d2448623-5362-42e0-86ba-cc0db7d7bddf",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "fb57243f-c3c3-4e23-a7f6-15959ddae23c",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8a6b5929-5dbd-4d8f-9822-fd0ab4081ab5",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "483b7376-4bf8-4b62-8de2-1c824481ff77",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "b7be540a-8eb8-494c-ad77-d3b69186e262",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "95510b14-3997-4fbb-9809-08863177e460",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "76696d82-96ba-4d76-8348-577783371845",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "4d950884-a1df-455f-ab37-1f1f81bde5bb",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4c1cdd40-48cf-4aaf-b31e-c57cd8145041",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "4eab48fb-2284-48e0-80e5-8b0ef8c7da42",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "e1578a4a-9c4b-46c6-aa7c-bca733e640b2",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "45adf6b6-46c5-4fe4-a7d3-e3279cb19ed7",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "6068312f-be23-47c3-b63c-1255ebae1f37",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "f61bd0e7-3a0c-41a6-bb96-97f843887c77",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "ce659449-af98-4d0e-9866-4b9e6f047bd6",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "247e4aee-84c2-4c62-8e59-0d3e78be95c9",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4a34dac2-85c5-46d9-8a5f-ea150990bdc0",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "cc2b240c-60e1-4fe5-880e-5775dd96dec0",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "5472a410-bbec-426e-9b3c-15653bf936ec",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "33348550-773d-4173-989d-53c67cb61fe9",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "b38b285c-80cc-486b-9844-2a81357eb0ae",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "d1acfc3d-1e3d-4f00-9a1a-67f9edf9cf13",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "77f3f379-2a1b-49de-9de9-f46741639446",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "5231394f-3077-43ad-a30b-49f17d1d0199",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "f4a39294-0412-40ab-8542-1369b6faa4ab",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-28T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "37eb41ca-6459-4d21-ab66-2d78819acc36",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d755b97a-49c3-46e9-be3f-7f2a541459d3",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "01a2c877-612c-4cfd-b0f8-10015f1fc468",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "3cbd3a1f-60fb-4176-b751-3ab3ec9692fd",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "652d2b30-5167-405e-8304-e431d79c16b7",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "4e456c03-e2b3-4e57-80dc-ed64ef1c5ba2",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "30e22d3c-eb18-4a18-a901-43c3d6998856",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "42fa1af4-4447-4d4d-8867-3c836704b7a1",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "6a9b4bb9-2552-4b57-acc0-1c99e30adc53",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "13f9a58b-af92-49cd-9a4e-11d67eda4c00",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-27T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "2ea3c232-dbcb-47ae-b69d-f51a42b919ac",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "55b844b4-6530-4247-b31c-2d63e5adaf8d",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "72362889-d0dc-4eea-b877-4ff35bc1d8f2",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "9cc0bc5a-f9ab-4d37-bc3c-52020f60d035",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "fa39cf8b-50fb-4184-ad1d-dcac2c9cbc21",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d2448623-5362-42e0-86ba-cc0db7d7bddf",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-21T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "6c3115bb-f367-430f-8567-e8e36d9d410e",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "8a6b5929-5dbd-4d8f-9822-fd0ab4081ab5",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-23T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "d62b4de7-6060-4894-89cd-f57b93489daf",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "b7be540a-8eb8-494c-ad77-d3b69186e262",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-24T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "e7709b3c-1b72-4936-90ca-ef1302c569f5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "76696d82-96ba-4d76-8348-577783371845",
    building_id: "2bd3836c-3cf2-4e6e-8dda-8f6ec78d23e5",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-26T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "93df10f3-c55e-4a53-b49e-864442bcb617",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "f20d54b9-3736-47ad-a86c-6d44b5f53c6d",
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "89f34973-7659-42b4-8df0-7c5bfec55d17",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    type: "timebound",
    device_id: "df9833df-b794-4f2d-a2cd-dc5c5202ac77",
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    starts_at: "2022-12-19T05:50:18.236Z",
    ends_at: "2022-12-20T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "57baed4f-37c7-4195-a590-8f5886329cee",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "d3557d28-8fc5-4837-94a4-75c6a1fb8893",
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-25T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "88b7e2fa-78ae-4587-823b-6fe1a2e03ef0",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "c96674b3-2e5c-434b-b1f0-ada0dcee8f43",
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-17T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "eb3e238a-7048-4547-a800-79766e6dec22",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "35a92636-dc58-4fa2-8e05-bd29b89dc405",
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.236Z",
    ends_at: "2022-12-22T05:50:18.236Z",
    last_used_at: "2022-12-18T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "89f33d64-59dc-48e6-9f6f-0bb1974c5af3",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "03d9eb01-d49b-4c41-8d11-02ac8725f7aa",
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-16T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "497dde29-1457-407f-9163-d7ee3155eb04",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.236Z",
    code: "1234",
    device_id: "07868bc7-0ea5-4e1d-8646-53742a0a29da",
    building_id: "80605ae7-9cb0-4015-8481-daea1916de00",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.236Z",
    ends_at: "2022-12-16T05:50:18.236Z",
    last_used_at: "2022-12-14T05:50:18.236Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "c8c0d6ef-0454-405a-a0a8-669946073151",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    type: "timebound",
    device_id: "577ee38b-1753-496c-824e-07662ded837d",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    starts_at: "2022-12-19T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "5a57a720-bff9-4ad3-b300-fbd0e473f71f",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    type: "timebound",
    device_id: "0d0be591-e219-4797-9e16-510d67847f63",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    starts_at: "2022-12-19T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "77cd08ce-17d9-4d2c-bd03-a10ddccb59b2",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "ba820a05-b71d-4fda-9512-0a0a4965d642",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "21a1f35b-b731-4a86-8058-fe541ec778e9",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "8edf9164-6062-4d54-bee6-ded3209f40ad",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-27T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "4771f34f-63c2-4c83-9c26-ca57d32d2ca1",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "1bb48879-b4ad-4bc8-b097-adacd2d205c5",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "cd89d891-ab7f-4902-8a35-5dec00177af4",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "e17b7dd1-83d9-4e20-a0f8-e033c5808b2c",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.237Z",
    ends_at: "2022-12-16T05:50:18.237Z",
    last_used_at: "2022-12-15T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "92d108b0-4ed9-4637-9b5b-b8afe3d108f7",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "916d1c4b-e002-4408-a419-de1377dff145",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.237Z",
    ends_at: "2022-12-16T05:50:18.237Z",
    last_used_at: "2022-12-14T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "7f194156-cc9e-4d4b-afc7-855375e5727a",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "8efd3586-7900-4bc3-889e-80c571cf0d90",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "ea1dc85b-8f8d-408c-b3fe-ee9e7156fe6f",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "17137111-9ce9-49e5-8ac4-93685fe1a012",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-25T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "cb2ba825-5248-4ec4-9171-2db2d750a832",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "1a06d856-ee8e-4beb-8076-18b25690ca30",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-27T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "6a76fb00-546d-4ff4-ba4b-f2f5c1743d75",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "194ca452-562e-4533-8db2-7952dc6c528f",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "f561ca2a-6936-4591-af99-7544b383b3fb",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "c45db451-3d56-41b7-a61a-8357597539e3",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "39c73b43-57d3-4a2d-8e4b-e204b6e3988a",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "33631d94-53da-4070-a681-d09f231935cd",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-19T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "d9e463ab-d086-4743-936f-1c1349bda281",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "9d1ab12e-4d90-4a30-a412-5a01314f08f5",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "2cf4039e-aab8-4b21-8370-e45856127c7d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "b4c4fcdf-c4b4-4d67-83d3-f6f78a1aa1c4",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "c8c6590b-d50d-4d37-8ec2-db8afa25c0c9",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "263acbad-6ffa-4e98-8822-34202f731376",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-25T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "6584b354-8286-4e08-8402-c545d3a7689b",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "f9f0f016-f3e4-4992-8784-30297f503893",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "077a62d1-db1a-44c1-a7c9-9bda469d83ff",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "7d6ded06-5e0c-4849-9888-cf3cf19a1129",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "32323c8c-80ea-4979-b214-ec9e9a3d5b95",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "e8fcf9f9-ffe0-451a-8d00-1b6ac20fc435",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-25T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "c80c8d5c-6ff5-4bf9-9b1d-3d4d51456f48",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "6cd8da9b-594d-4eff-a61d-98087eac426d",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-19T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "fc399e2d-68c4-436a-87c7-f2d91cb3b53a",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "6f840206-ee18-4d54-a6be-12b3e6f89f6f",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-19T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "dd5f1f7c-9007-4f8f-b648-3117d1513875",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "349fa225-f259-4be7-9fa6-e34f6e97e7c3",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "8da5aa6a-a1e2-45c2-8ad2-6d2e79762e5d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "4a3ed6b1-7f40-4224-a0e5-eb8c5ad40fda",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "8eb6ee92-7009-44a2-aca2-dd87b223ca53",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "b7e29817-c99f-4e7a-8add-df90888c842f",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-28T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "b787e970-7630-4233-ae83-641591f83845",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "be184155-78eb-4937-b738-c26e3c44d156",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "f6f10966-2e3a-41e1-9263-f7f0ac3e01c4",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "39b3be8d-d9df-47e5-92cc-db9b227dd07b",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-25T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "eb995bc1-5e72-42f5-a544-85dca65a13c2",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "47f73ad6-3867-40bf-9aed-e083a2dc2265",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "c708c4f0-0f6e-4a3b-8b6c-5a75de09b9bc",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "494d653d-7866-4e41-af25-e14e26d44c52",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "1f4656bb-235d-400d-ac6c-b5dd6bc015bc",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "a4be20b8-81f4-416c-bf83-0195b70c4c39",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "cb7ff3e5-bb9e-41d4-ae18-4995a63e0e77",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "b45fd968-ea5e-4579-ab3a-1c8adc2affc6",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-28T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e2e1ce98-eaaf-40cf-8d89-4309a6802411",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "b7c84c6d-a380-4ac6-bfed-565a932a6bbf",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-23T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "e648b889-27ba-4303-937c-40a897bd3b0b",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "5f1f42a2-ee0a-4490-a519-fc72f55a455b",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "7766cdc9-b38c-4757-9f00-4d042a67648d",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "03ee5059-7593-4553-bb76-2696e3f7d7d3",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-28T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "10a9c9ec-0968-4053-8036-616651fc2545",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "b5f8f84d-c29d-497d-9bd9-565b61d9af99",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "28a03522-f9c8-46db-8485-af57701a7b2e",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "d13231f9-b758-4ff5-b235-cd131c05a750",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-23T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "169c4567-18c4-4d6e-a244-189a104f9c63",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "82bd448f-578f-4e56-828a-c29b489d5e17",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "b4bd81d4-67b9-4405-b263-6fe612293254",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "917a616e-f3c4-4329-8a35-5ddad4882deb",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "b45f2c77-94db-4eea-bcef-e3213648c336",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "dd873176-3d28-4d5a-af73-cfe674505368",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "376f56c8-8d9c-4b95-9a2d-0c0c6bc62207",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "c2564b60-9651-432c-9945-a852f4ba263e",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "e92d59a7-3753-40c3-91cd-73817a2800f3",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "dd4f4036-e5ac-499c-b515-0f9d797d8074",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-25T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "2b60eaa4-29e5-41fa-be84-72e0e40f0722",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "4f846470-2d87-404d-90e9-88d65972c140",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "ddb40ffa-2089-4233-80ba-837af347ccda",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "f2ca9cf9-b98b-4b9e-af83-272903a1d648",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "5e409099-39e7-4761-bb4b-78f38ede0d44",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "dbdff4f0-28da-43d1-965b-2c7c8607498d",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "6758c7af-5c3d-4b9f-8906-c0716b6b5b8b",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "823a9d60-0fae-4de0-b306-51b853f3b36c",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "bb7bc8ce-46b8-4404-a2fd-d9bb37af6f07",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "23169707-451d-476f-99e1-64165ec2c50d",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "b09caee8-1d83-4e69-9456-7a40c38a3b72",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "733a457e-acfd-4804-aabb-06843386b194",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-19T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "96e30873-cd1f-4bd5-b81c-ee02949cb2bd",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "69639022-0594-4068-b1cb-c9bd4fefe938",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-26T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "890b9f72-501d-410a-b068-9eaa1204887a",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "07500dd7-9a2a-4678-8177-2ba5fc78c0c9",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-28T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "17c64a60-17c1-41ca-92ec-e859d07e6578",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "213a9ba3-e00e-490a-9e0e-aedfdc202e7b",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "11dd279e-d644-4cc8-a5d3-454546721c4e",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "e0307205-fbd7-495e-a4c9-124ad1231433",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "2e2850f5-0c18-4df5-b6b2-cce21112c481",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "397503b7-bfaa-48a2-8e4c-7b10403fa60b",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "b77b6b3d-d81a-4ea2-bd5a-a8f2d969ece7",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "5dbf0289-0573-42ed-99eb-e57a899714d9",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "da4111e2-3a1d-4e09-8637-107845160a86",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "127886ba-76e3-4df9-9a1c-0fe0d4e0e35b",
    building_id: "0570bd0f-00c4-4cd3-918a-15f6167ba81e",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "33d7f0d7-8bae-4764-8ac7-0552497d7ce5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    type: "timebound",
    device_id: "aed06ec9-790e-46e8-9c57-e4952cd06f10",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    starts_at: "2022-12-19T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "fe81731a-50eb-4842-96e9-00ee87e86331",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    type: "timebound",
    device_id: "20b1797a-ce4b-4fb6-b53e-8a1261e820ed",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    starts_at: "2022-12-19T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "583be540-ceb9-4cd6-92fe-0672c05a26a8",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "8f49b3d4-b89d-4edc-a42e-400f733d80ea",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "391b3f79-f93d-4899-9db8-26d5dcd985e7",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "4abd9b83-43da-440b-874d-1ef6999ac1a0",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-19T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "e4ef2595-7443-4abf-9fa2-ddf5a8d92d87",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "7fc8811a-bad4-4a04-b169-6bc0d39df06d",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "40d9074b-6add-4a7b-84a5-744355ed6453",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "81fa6ba0-3539-4c6a-8923-5b171cee8128",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.237Z",
    ends_at: "2022-12-16T05:50:18.237Z",
    last_used_at: "2022-12-15T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "fe5b115f-a17d-4f8b-85f3-2b1036e9995d",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "3aa791a6-f003-4323-a99d-b6784d62b401",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-14T05:50:18.237Z",
    ends_at: "2022-12-16T05:50:18.237Z",
    last_used_at: "2022-12-15T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "9baf0074-1b79-4964-9c48-8f269dec21e6",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "607ede90-bf72-4134-8de8-8e5316154e0b",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 7,
  },
  {
    access_pass_id: "5dd9bb73-9aed-4014-85bf-2ce920261f9c",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "d9547eb2-0a85-45a8-a787-b4f74697099d",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "12e7977c-22bc-4f50-84a0-12a0b1b6466c",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "b4062334-e57f-475d-ada0-d90b95f90ca9",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-27T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "c5acb5b5-a7c9-4010-a8b5-a7b2cf69a1db",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "1663b96e-dd75-4814-86b5-a6bed14737d0",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "be309187-a5f8-4d48-aa51-f98edb26b849",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "c257bd46-54a0-410a-8170-f8c2397eda39",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-19T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "a11334ef-c010-4155-a0fc-3ecb4c1ac481",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "8b5e74b2-d8e5-4dcc-8e1c-d44988fe648f",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "29e403dc-fe4f-4e7c-9cfb-afa6c717e67a",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "4d06eeb4-1b6f-41bf-8595-504ad9148423",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-25T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "8374ff95-57ff-4922-ba89-6cb0732d3425",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "8fbcce3a-61f6-4da6-8eaf-cc58be57d35a",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "383df930-040e-44db-b5ba-26cef949ab60",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "08865e07-16ac-447d-83db-be1a42d8bd56",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-25T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 3,
  },
  {
    access_pass_id: "2785240b-052c-450f-aa51-67d666cdcbc7",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "58ef5050-49b7-43ec-b88e-6a8c731a6544",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "945fcb7c-3a3b-43dd-9bc5-d57b26acd36b",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "9265ecef-7a0a-4810-944e-56bf8a58eeed",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 2,
  },
  {
    access_pass_id: "463afd98-516b-4dbc-b380-a2d6bf2f489e",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "0e356829-10ee-4a78-9d9e-1db7fa0bf50c",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "4457a3d5-f57b-49f5-a2c4-a90a862f3bc3",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "92a39c97-0a83-441f-a687-45884ec42f74",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-28T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "53462ccf-143b-45fa-a005-59350b643b1d",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "8a7b778f-a2b3-41cc-9c53-6868d02562ca",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-22T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "27d1345d-e457-4993-b566-63d12108e291",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "ab109c38-fc76-41e5-9c79-f910e32a7fa9",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-28T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 5,
  },
  {
    access_pass_id: "67c48080-b449-427e-866b-558ecd1901d7",
    name: "Ethan Earhorn",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "aed06ec9-790e-46e8-9c57-e4952cd06f10",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-27T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "be00114d-2f7a-4927-b2b3-cdf624e1612a",
    name: "USPS Delivery",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "20b1797a-ce4b-4fb6-b53e-8a1261e820ed",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-19T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 1,
  },
  {
    access_pass_id: "e2db9b2a-2d63-446e-98f4-3972a07b32c5",
    name: "United Plumbing",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "8f49b3d4-b89d-4edc-a42e-400f733d80ea",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-23T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
  {
    access_pass_id: "40516f14-7959-49b1-8fe9-a6d7b939b7e5",
    name: "Emergency Services",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "4abd9b83-43da-440b-874d-1ef6999ac1a0",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-20T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 4,
  },
  {
    access_pass_id: "6dc3eabf-9b19-4351-9ca4-f5708579b335",
    name: "Alexandra Aoun",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "7fc8811a-bad4-4a04-b169-6bc0d39df06d",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-21T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 0,
  },
  {
    access_pass_id: "d16c172c-38a0-441d-905f-84eca92b83d5",
    name: "Belle Blue",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "81fa6ba0-3539-4c6a-8923-5b171cee8128",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-28T05:50:18.237Z",
    last_used_at: "2022-12-18T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 8,
  },
  {
    access_pass_id: "43653055-d339-4530-a40c-b6c37a9f3251",
    name: "Callie Class",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "3aa791a6-f003-4323-a99d-b6784d62b401",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-23T05:50:18.237Z",
    last_used_at: "2022-12-17T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 9,
  },
  {
    access_pass_id: "c404b14a-ef3f-4a7f-aaf2-ba0b8fafd9bc",
    name: "Dylan Dancer",
    created_at: "2022-12-18T05:50:18.237Z",
    code: "1234",
    device_id: "607ede90-bf72-4134-8de8-8e5316154e0b",
    building_id: "57051d8d-c75e-48ca-8151-f73d7277db22",
    type: "timebound",
    starts_at: "2022-12-17T05:50:18.237Z",
    ends_at: "2022-12-24T05:50:18.237Z",
    last_used_at: "2022-12-16T05:50:18.237Z",
    recently_used_count: 1,
    used_count: 6,
  },
]

// const devices = ExampleDevices.map((device) => {
//   const access_codes = ExampleAccessCodes.filter((access_code) => access_code.device_id === device.device_id)
//   return {
//     ...device,
//     access_pass_count: access_codes.length,
//     active_access_pass_count: access_codes.filter((access_code) => access_code.ends_at > time()).length,
//   }
// })
// console.log(devices)

export const ExampleUnlockEvent = (
  device_id: string,
  access_pass_id: string | undefined
) => {
  const device =
    ExampleDevices.find((d) => d.device_id === device_id) || ExampleDevices[0]
  const access_code =
    ExampleAccessCodes.find((a) => a.access_pass_id === access_pass_id) ||
    ExampleAccessCodes[0]
  return {
    event_id: uuid(),
    created_at: time(getRandomIntegerInRange(-20, 0)),
    event_type: "lock.unlocked",
    device_id: device?.device_id,
    device_name: device?.name,
    access_code_id: access_code?.access_pass_id,
    access_code_name: access_code?.name,
    method: "access_code",
  }
}

export const ExampleLowBatteryEvent = (device_id: string) => {
  const device =
    ExampleDevices.find((d) => d.device_id === device_id) || ExampleDevices[0]
  return {
    event_id: uuid(),
    created_at: time(),
    event_type: "device.low_battery",
    device_id: device?.device_id,
    device_name: device?.name,
    battery_level: 0.2,
  }
}
