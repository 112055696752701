import { useState } from "react"
import { Button } from "@mui/material"
import classNames from "classnames"

import useMyOrganization from "hooks/auth/useMyOrganization"

import Screen from "components/modals/LinkedAccountsModal/fragments/Screen"
import { PageProps } from "components/modals/LinkedAccountsModal/screens/types"

import { brands } from "lib/brands"
import { icons } from "lib/constants/icons"

const BrandLoginScreen = ({ progressData, screenProps }: PageProps) => {
  const myOrg = useMyOrganization()
  const [loading, setLoading] = useState(false)

  const login = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      screenProps.moveForward()
    }, 2000)
  }

  return (
    <>
      <Screen {...screenProps}>
        <div className="wfull">
          <div className="brand-image-wrap">
            <img
              // @ts-ignore
              src={brands[progressData.brand.toLowerCase()].logo.src}
              alt={progressData.brand}
              className={classNames(
                "brand-image",
                progressData.brand === "Akuvox" && "invert"
              )}
            />
          </div>
          <p className="text text-c m-0 mb-4">
            Select the brand for an existing external account that you&apos;d
            like to link to the {myOrg?.name ?? "A1 properties"} organization.
          </p>
        </div>

        <div className="wfull mt-8">
          <div className="wfull flex justify-e align-c flex-row mb-4">
            <p className="small-caption">Sign in with phone number</p>
            <i className="icon size-3 opacity-06 ml-1">{icons.chevronRight}</i>
          </div>
          <div className="login-wrap">
            <div className="grid-block flex align-s justify-c">
              <label className={loading ? "disabled" : ""} htmlFor="email">
                Email address
              </label>
            </div>
            <div className="grid-block flex align-s justify-c">
              <input
                name="email"
                type="email"
                placeholder="me@example.com"
                disabled={loading}
              />
            </div>
            <div className="grid-block flex align-s justify-c">
              <label className={loading ? "disabled" : ""} htmlFor="email">
                Password
              </label>
            </div>
            <div className="grid-block flex align-s justify-c">
              <input
                name="email"
                type="password"
                placeholder="************"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </Screen>
      <div className="buttons">
        {loading ? (
          <p className="opacity-07 mb-4">Signing in...</p>
        ) : (
          <>
            <Button variant="outlined" onClick={screenProps.moveBackward}>
              Cancel
            </Button>
            <Button variant="contained" onClick={login}>
              Sign in
            </Button>
          </>
        )}
      </div>
    </>
  )
}

export default BrandLoginScreen
