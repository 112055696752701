import type { Action } from "types"

interface Link extends Action {
  // If true, the link will only be shown if the user has an organization
  requiresOrg?: boolean
}

export const links: Link[] = [
  {
    label: "Home",
    href: "/",
  },
  // {
  //   label: "Account Info",
  //   href: "/account",
  //   requiresOrg: true,
  // },
  {
    label: "Linked Accounts",
    href: "/linked-accounts",
    requiresOrg: true,
  },
  {
    label: "Members",
    href: "/members",
    requiresOrg: true,
  },
  // {
  //   label: "Settings",
  //   href: "/settings",
  // },
  {
    label: "Log out",
    href: "/logout",
  },
]
