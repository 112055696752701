import { brands } from "lib/brands"

interface Props {
  brand: keyof typeof brands | "Seam"
}

const LinkingLogo = ({ brand }: Props) => {
  return (
    <div className="linking-logo">
      <img
        src={
          brand === "Seam" ? "/brand/seam-symbol.svg" : brands[brand].logo.src
        }
        alt={brand}
      />
    </div>
  )
}

export default LinkingLogo
