import { useState } from "react"
import { AnimatePresence } from "framer-motion"

import BrandLoginScreen from "components/modals/LinkedAccountsModal/screens/BrandLoginScreen"
// Screen
import BrandsListScreen from "components/modals/LinkedAccountsModal/screens/BrandsListScreen"
import DevicesAddedScreen from "components/modals/LinkedAccountsModal/screens/DevicesAddedScreen"
import DevicesScreen from "components/modals/LinkedAccountsModal/screens/DevicesScreen"
import LinkingAccountScreen from "components/modals/LinkedAccountsModal/screens/LinkingAccountScreen"
import AnimatedPage from "components/pages/accessPasses/AnimatedPage"

import Modal, { Props as ModalProps } from "../Modal"

import { Direction } from "types"

const screens = [
  BrandsListScreen,
  BrandLoginScreen,
  LinkingAccountScreen,
  DevicesScreen,
  DevicesAddedScreen,
]

export interface ProgressData {
  brand: string
  devicesSelected: any[]
  building: string
}

interface Props extends ModalProps {}

const LinkedAccountsModal = ({ ...props }: Props) => {
  const [screen, setScreen] = useState<number>(0)
  const [direction, setDirection] = useState<Direction>("forwards")
  const [progressData, setProgressData] = useState({
    brand: "August",
    devicesSelected: [] as any[],
    building: "",
  })

  const moveForward = () => {
    setScreen((screen) => Math.min(screen + 1, screens.length - 1))
    setDirection("forwards")
  }

  const moveBackward = () => {
    setScreen((screen) => Math.max(screen - 1, 0))
    setDirection("backwards")
  }

  const screenProps = {
    direction,
    moveForward,
    moveBackward,
    close: props.close,
    reset: () => {
      setScreen(0)
      setDirection("forwards")
      setProgressData({
        brand: "august",
        devicesSelected: [] as any[],
        building: "",
      })
    },
  }

  const shouldShowBackButton = () => {
    switch (screen) {
      case 0:
        return false
      case 1:
        return true
      case 2:
        return false
      case 3:
        return false
      case 4:
        return true // TODO
    }
  }

  return (
    <Modal
      onBackButtonClick={shouldShowBackButton() ? moveBackward : undefined}
      showCloseButton={screen === 0}
      clickOutsideToClose={screen === 0}
      {...props}
    >
      <div className="modal-linked-accounts hfull">
        <AnimatePresence mode="wait" initial={false}>
          {screens.map((Screen, index) => {
            if (index !== screen) return <></>

            return (
              <AnimatedPage key={index} direction={direction}>
                <Screen
                  progressData={progressData}
                  setProgressData={setProgressData}
                  screenProps={screenProps}
                />
              </AnimatedPage>
            )
          })}
        </AnimatePresence>
      </div>
    </Modal>
  )
}

export default LinkedAccountsModal
