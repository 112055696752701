import BuildingInfoModal from "components/modals/BuildingInfoModal"
import { ModalProps } from "components/modals/Modal/Modal"

interface Props extends Pick<ModalProps, "isOpen" | "close"> {}

const AddBuildingModal = ({ ...props }: Props) => {
  return (
    <BuildingInfoModal
      operatingData={{
        mode: "create",
      }}
      {...props}
    />
  )
}

export default AddBuildingModal
