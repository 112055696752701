import { SvgIcon } from "@mui/material"

import Tile from "components/cards/Tile"

import PadlockLocked from "assets/images/padlock-locked.svg"
import PadlockUnlocked from "assets/images/padlock-unlocked.svg"

export interface Props {
  locked: boolean
  salto_locked_state?: any
}

const iconStyle = {
  width: "36px",
  height: "36px",
}

export const LockedTile = ({ locked, salto_locked_state }: Props) => {
  if (locked == undefined && !salto_locked_state) {
    return <></>
  }
  if (!!salto_locked_state) {
    switch (salto_locked_state) {
      case "locked":
        return (
          <Tile
            value={<SvgIcon component={PadlockLocked} sx={iconStyle} />}
            label="Locked"
          />
        )
      case "unlocked":
        return (
          <Tile
            value={<SvgIcon component={PadlockUnlocked} sx={iconStyle} />}
            label="Unlocked"
          />
        )
      case "office_mode":
        return (
          <Tile
            value={<SvgIcon component={PadlockUnlocked} sx={iconStyle} />}
            label="Office Mode"
          />
        )
      case "uncertain":
      case "none":
      default:
        return <></>
    }
  }
  if (locked) {
    return (
      <Tile
        value={<SvgIcon component={PadlockLocked} sx={iconStyle} />}
        label="Locked"
      />
    )
  }
  return (
    <Tile
      value={<SvgIcon component={PadlockUnlocked} sx={iconStyle} />}
      label="Unlocked"
    />
  )
}

export default LockedTile
