import { useEffect, useState } from "react"
import { Checkbox } from "@mui/material"

import { Children } from "types"

interface Props extends Children {
  label: string
  onChange?: (value: boolean) => void
}

const OptionalBlock = ({ label, onChange, children }: Props) => {
  const [enabled, setEnabled] = useState<boolean>(true)

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(enabled)
    }
  }, [enabled])

  return (
    <div className="optional-block wfull">
      <div className="HStack">
        <Checkbox
          color="primary"
          defaultChecked
          onChange={(ev) => {
            setEnabled(ev.target.checked)
          }}
          className="mr-2"
        />
        <label className="caption fw-600 m-0">{label}</label>
      </div>

      {enabled && <div className="block">{children}</div>}
    </div>
  )
}

export default OptionalBlock
