import { useEffect, useMemo, useState } from "react"
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import classNames from "classnames"
import pluralize from "pluralize"

import { useSeamQuery } from "hooks/useSeamQuery"
import useSessionStore from "hooks/useSessionStore"

import { PanelID, usePanels } from "providers/PanelsProvider"

import BaseModal, { Props as BaseModalProps } from "components/modals/BaseModal"
import { ModalProps } from "components/modals/Modal/Modal"

import { colorDefaults } from "lib/mui"
import { getSavedObject, saveObject } from "lib/utils/storage"
import BuildingIcon from "assets/images/building-purple.svg"
import CheckGreenIcon from "assets/images/check-green.svg"
import DeviceIcon from "assets/images/device-purple.svg"
import MemberIcon from "assets/images/user-purple.svg"

const columns = [
  {
    id: "buildings",
    icon: <BuildingIcon />,
    title: "Add buildings",
    status: "No buildings added yet",
    content:
      "Add buildings to your organization to control access to the devices in those buildings. When you connect devices and map them to these buildings, you'll be able to quickly grant building access.",
    actionLabel: "Add a building",
  },
  {
    id: "devices",
    icon: <DeviceIcon />,
    title: "Connect devices",
    status: "No devices connected yet",
    content:
      "Devices are added by linking your organization to a device account. Once your device account is linked, you’ll want to map those devices to your buildings, so you can create access passes to those buildings.",
    actionLabel: "Add devices",
  },
  {
    id: "members",
    icon: <MemberIcon />,
    title: "Invite members",
    status: "No members have joined",
    content:
      "If you want give others the ability to control access to your buildings, invite them to join your organization. Once your members set up their accounts, they'll be able to see the organization whenever they sign in.",
    actionLabel: "Invite members",
  },
]

interface Props
  extends Omit<BaseModalProps, "className" | "onClose">,
    Pick<ModalProps, "close"> {}

const OrgSetupModal = ({ ...props }: Props) => {
  const organization_id = useSessionStore((s) => s.organization_id)
  const panels = usePanels()
  const [checked, setChecked] = useState<boolean | null>(null)

  useEffect(() => {
    const organizationIds = getSavedObject<string[]>(
      "panel:hide-org-setup-modal",
      []
    )

    if (checked === null) {
      setChecked(organizationIds.includes(organization_id ?? "null"))
    } else {
      if (checked) {
        if (organizationIds.includes(organization_id ?? "null")) return

        saveObject("panel:hide-org-setup-modal", [
          ...organizationIds,
          organization_id ?? "null",
        ])
      } else {
        saveObject(
          "panel:hide-org-setup-modal",
          organizationIds.filter(
            (id: string) => id !== (organization_id ?? "null")
          )
        )
      }
    }
  }, [checked])

  const { data: buildings } = useSeamQuery(["buildings", {}, "list"], (seam) =>
    seam.buildings.list({})
  )

  const getBuildingCount = () => {
    if (typeof buildings === "undefined") return 0
    return buildings.length
  }

  const { data: organizationSummaryCounts } = useSeamQuery(
    ["organizations", { organization_id }, "get_count_summary"],
    async (seam) =>
      seam.organizations.get_count_summary({
        organization_id: organization_id!,
        between: [new Date(0), new Date()],
      })
    // NOTE: organization_id is guaranteed to be defined here
    //       because it is a precondition of useSeamQuery
  )

  const total_org_members = useMemo(() => {
    if (!organizationSummaryCounts) return 0

    return (
      organizationSummaryCounts.total_members +
      organizationSummaryCounts.total_admins +
      organizationSummaryCounts.total_super_admins
    )
  }, [organizationSummaryCounts])

  const { data: linked_accounts } = useSeamQuery(
    ["linked_accounts", {}, "list"],
    (seam) => seam.linked_accounts.list({})
  )

  const total_devices = linked_accounts?.reduce(
    (acc, linked_account) => acc + linked_account.device_count,
    0
  )

  const closeThisAndOpen = (modal: PanelID) => {
    props.close()
    panels.set(modal, true)
  }

  const actionMap: Record<string, () => void> = {
    buildings: () => closeThisAndOpen("addBuildingModal"),
    devices: () => closeThisAndOpen("linkedAccountModal"),
    members: () => closeThisAndOpen("inviteMemberModal"),
  }

  const getStatusLine = (
    subject: string,
    count: number,
    zeroPredicate: string,
    predicate: string = "added",
    pluralizeZeroSubject: boolean = true
  ) => {
    if (count === 0)
      return `No ${subject}${pluralizeZeroSubject && "s"} ${zeroPredicate}`
    return `${count} ${pluralize(subject, count)} ${predicate}`
  }

  // TODO: Why is total_org_members - 1 sometimes -1 and sometimes 0?
  const getMemberCount = () => {
    const count = total_org_members - 1 // -1 for the current user
    if (count === -1) return 0
    return count
  }

  const statusMap: Record<string, string> = {
    buildings: getStatusLine("building", getBuildingCount(), "added yet"),
    devices: getStatusLine("device", total_devices ?? 0, "connected yet"),
    members: getStatusLine("member", getMemberCount(), "have joined", "joined"),
  }

  return (
    <BaseModal className="org-setup-modal" onClose={props.close} {...props}>
      <div className="wfull flex-c text-c mb-12">
        <h1 className="title">Set up your new organization</h1>

        <div className="mw-96 mt-2">
          <p className="text">
            For new organizations, add buildings and connect some devices in
            order to start controlling access to buildings.
          </p>
        </div>
      </div>

      <div className="wfull grid grid-3">
        {columns.map((column) => (
          <div key={column.title} className="grid-block">
            <div className="inner-column">
              <div className="VStack">
                <div className="HStack">
                  {column.icon}
                  <div className="ml-2">
                    <h2 className="column-title">{column.title}</h2>
                  </div>
                </div>

                <div className="HStack my-3">
                  {!statusMap[column.id].startsWith("No") && (
                    <div className="mr-1">
                      <CheckGreenIcon />
                    </div>
                  )}
                  <p className="caption sm m-0 lh-1">{statusMap[column.id]}</p>
                </div>
                <p className="text">{column.content}</p>
              </div>

              <div className="mt-4">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={actionMap[column.id]}
                >
                  {column.actionLabel}
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="wfull flex-c text-c py-8">
        <p className="caption">
          You can find this info later in the account menu on top right.
        </p>
      </div>

      <div className="footer">
        <div className={classNames("wfull", checked ? "flex-c" : "SBStack")}>
          {!checked && (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked ?? false}
                      onChange={(e) => setChecked(e.target.checked)}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                    />
                  }
                  label="Don't show this automatically"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: colorDefaults.text.textGray1,
                      fontSize: "0.875rem",
                      fontWeight: "400",
                      lineHeight: "1",
                      userSelect: "none",
                      ml: 0.5,
                    },
                  }}
                />
              </FormGroup>
            </div>
          )}

          <button className="close-button" onClick={props.close}>
            Close
          </button>
        </div>
      </div>
    </BaseModal>
  )
}

export default OrgSetupModal
