import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useRouter } from "next/router"
import { Box, SxProps, Typography } from "@mui/material"
import { GoogleMap, MarkerF } from "@react-google-maps/api"

import DynamicLink from "components/interactive/DynamicLink"
import Icon from "components/media/Icon"

import { createCenterControl, createZoomControls } from "./controls"
import { styles } from "./style"

export interface Pin {
  lat: number
  lng: number
  href?: string
}

interface Props {
  pins: Pin[]
  autoBound?: boolean
  center?: [number, number]
  zoom?: number
  height?: number | string
  address?: {
    addr1: string
    city: string
    state: string
    zip_code: string
  }
  sx?: SxProps
}

const Map = ({
  pins,
  center,
  zoom = 12,
  height = "353px",
  address,
  autoBound,
  sx,
}: Props) => {
  const [mapsNamespace, setMapsNamespace] = useState<typeof google.maps>()
  const [map, setMap] = useState<google.maps.Map>()

  const router = useRouter()

  const toLiteral = (coords: [number, number]): google.maps.LatLngLiteral => ({
    lat: coords[0],
    lng: coords[1],
  })

  const bounds = useMemo(() => {
    if (!mapsNamespace) return
    const bounds = new mapsNamespace.LatLngBounds()

    for (const pin of pins) {
      bounds.extend({
        lat: pin.lat,
        lng: pin.lng,
      })
    }

    return bounds
  }, [pins, mapsNamespace])

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMapsNamespace(window.google.maps)
    setMap(map)

    const styledMapType = new google.maps.StyledMapType(styles)

    map.mapTypes.set("styled_map", styledMapType)
    map.setMapTypeId("styled_map")

    const zoomControls = createZoomControls(map)
    const centerControl = createCenterControl()

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControls)
    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(centerControl)
  }, [])

  useEffect(() => {
    if (autoBound && bounds && map) {
      map.fitBounds(bounds)
    }
  }, [autoBound, bounds, map])

  const full_address_string = useMemo(() => {
    if (!address) return ""
    return encodeURI(
      `${address.addr1}, ${address.city}, ${address.state} ${address.zip_code}`
    )
  }, [address])

  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        boxShadow: address
          ? "0px 0px 1px #0B53A8, 0px 2px 14px rgba(7, 70, 107, 0.09)"
          : "none",
        mb: address ? 0 : 3,
        ...sx,
      }}
    >
      <GoogleMap
        mapContainerStyle={{
          height,
          borderRadius: "0.75rem",
          borderBottomLeftRadius: address ? 0 : "0.75rem",
          borderBottomRightRadius: address ? 0 : "0.75rem",
        }}
        options={{
          disableDefaultUI: true,
        }}
        zoom={zoom}
        center={center ? toLiteral(center) : undefined}
        onLoad={onMapLoad}
      >
        {pins.map((pin) => (
          <MarkerF
            key={JSON.stringify(pin)}
            position={{
              lat: pin.lat,
              lng: pin.lng,
            }}
            icon={"/icons/pin.svg"}
            onClick={() => {
              if (pin.href) {
                router.push(pin.href)
              }
            }}
          />
        ))}
      </GoogleMap>
      {address && (
        <Box
          sx={{
            padding: 2,
            backgroundColor: "#F6F9FB",
            borderBottomLeftRadius: "0.75rem",
            borderBottomRightRadius: "0.75rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "0.875rem",
                lineHeight: "1",
              }}
            >
              {address.addr1}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.875rem",
                lineHeight: "1",
              }}
            >
              {address.city}, {address.state} {address.zip_code}
            </Typography>
          </Box>

          <Box>
            <DynamicLink
              href={
                "https://google.com/maps/search/?api=1&query=" +
                full_address_string
              }
            >
              <Icon
                icon="map"
                sx={{
                  width: "1.5rem",
                  height: "1.5rem",
                }}
              />
            </DynamicLink>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Map
