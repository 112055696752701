import Image from "next/image"

import DynamicLink from "components/interactive/DynamicLink"

const Logo = () => {
  return (
    <DynamicLink href="/" className="fw-500 flex-c dark-invert">
      <Image src="/brand/seam.svg" alt="Seam Logo" width={120} height={28} />
    </DynamicLink>
  )
}

export default Logo
