import Button from "@mui/material/Button"

import { usePanels } from "providers/PanelsProvider"

import LinkedAccountRow from "components/modals/AddDevicesModal/LinkedAccountRow"
import Description from "components/modals/Modal/Description"
import Modal, { ModalProps } from "components/modals/Modal/Modal"

import ChevronRight from "assets/images/chevron-right@24px.svg"
import LinkedCloud from "assets/images/linked-cloud.svg"

import { LinkedAccount } from "types"

type Props = Pick<ModalProps, "close" | "isOpen"> & {
  onLinkNewAccount: () => void
  linkedAccounts: LinkedAccount[]
  onSelectLinkedAccount: (account: LinkedAccount) => void
}

const SelectLinkedAccountModal = ({
  onLinkNewAccount,
  linkedAccounts,
  onSelectLinkedAccount,
  ...modalProps
}: Props) => {
  const panels = usePanels()

  return (
    <Modal
      {...modalProps}
      headerLabel="Add devices to building"
      className="add-devices-modal"
    >
      <Description>
        Choose a linked account below or link a new device account
      </Description>
      <div className="accounts-list mx-8 scrollable-content">
        <div className="sbstack row" onClick={onLinkNewAccount}>
          <div className="d-flex align-center">
            <div className="mr-4">
              <LinkedCloud />
            </div>
            <span>Link a new device account</span>
          </div>
          <ChevronRight />
        </div>
        {linkedAccounts.map((linkedAccount) => (
          <div key={linkedAccount.linked_account_id} className="pr-2 pl-6 row">
            <LinkedAccountRow
              linkedAccount={linkedAccount}
              onClick={() => onSelectLinkedAccount(linkedAccount)}
            />
          </div>
        ))}
      </div>
      <div className="buttons">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => panels.close("addDevicesModal")}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default SelectLinkedAccountModal
