import { useMemo } from "react"
import { Skeleton, Typography } from "@mui/material"
import { Box } from "@mui/system"
import dayjs from "dayjs"

import Card from "components/cards/Card"
import Incidents from "components/data/Incidents"
import MapComponent, { Pin } from "components/interactive/Map/Map"

import Sidebar from "./Sidebar"

import { mapBy } from "lib/utils"

import type { ApiResponseByClientMethod } from "types"

export interface Props {
  buildings: ApiResponseByClientMethod<"buildings.list">
  incidents: ApiResponseByClientMethod<"incidents.list">
  isLoading: boolean
}

const Home = ({ buildings, incidents, isLoading }: Props) => {
  const incidentsByFormattedDate = useMemo(() => {
    const today = dayjs().format("MMM D, YYYY")

    return mapBy(incidents, (incident) => {
      const formattedDate = dayjs(incident.created_at).format("MMM D, YYYY")
      return formattedDate === today ? "Today" : formattedDate
    })
  }, [incidents])

  const mapPins = useMemo<Pin[]>(
    () =>
      buildings
        .filter((building) => building.location)
        .map((building) => ({
          lat: building.location.latitude,
          lng: building.location.longitude,
          href: `/buildings/${building.building_id}`,
        })),
    [buildings]
  )

  if (isLoading) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        width={"100%"}
        height={600}
      />
    )
  }

  return (
    <Sidebar>
      <MapComponent autoBound pins={mapPins} />
      <Card
        title="Recent issues"
        sx={{
          mt: 3,
        }}
      >
        {incidents.length > 0 ? (
          <>
            {[...incidentsByFormattedDate.entries()].map(
              ([formattedDate, incidents]) => (
                <Incidents
                  key={formattedDate}
                  title={formattedDate}
                  incidents={incidents}
                />
              )
            )}
          </>
        ) : (
          <Box
            sx={{
              height: "10rem",
            }}
            className="flex-c"
          >
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                mb: 0,
              }}
            >
              No recent activity
            </Typography>
          </Box>
        )}
      </Card>
    </Sidebar>
  )
}

export default Home
