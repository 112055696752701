// TODO: The comments in this file are for the future when we can upload
// images to the backend.

// import { ChangeEventHandler } from "react"
import { Box, Typography } from "@mui/material"

import Icon from "components/media/Icon"
import Image from "components/media/Image"

import { useBuildingInfoModalContext } from "../context"
import ImagePickerAction from "./ImagePickerAction"

import { colorDefaults } from "lib/mui"
import ChevronRightIcon from "assets/images/chevron-right.svg"
import TrashIcon from "assets/images/trash.svg"
// import UploadIcon from "assets/images/upload.svg"
// import { readFile } from "lib/utils/file"

const vstack = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}

const ImagePicker = () => {
  const context = useBuildingInfoModalContext()

  // const onFileUpload: ChangeEventHandler<HTMLInputElement> = async (ev) => {
  //   if (ev.target.files?.length !== 1) {
  //     return
  //   }

  //   const file = ev.target.files[0]
  //   const formData = new FormData()
  //   const buffer = await readFile(ev.target.files[0])

  //   formData.append(
  //     "file",
  //     new Blob([(buffer ?? new ArrayBuffer(0)) as ArrayBuffer]),
  //     file.name
  //   )

  //   console.log(formData)
  // }

  return (
    <Box
      sx={{
        backgroundColor: "var(--gray-base)",
        borderRadius: "0.375rem",
        padding: "1.5rem",
        paddingTop: context.state.selectedImage ? "1.5rem" : "4rem",
        paddingBottom: "1rem",
        position: "relative",
        ...vstack,
      }}
    >
      {context.state.selectedImage ? (
        <Box
          sx={{
            width: "100%",
            height: "16rem",
            borderRadius: "0.5rem",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Image
            src={context.state.selectedImage.url ?? "/public/placeholder.png"}
            alt={context.state.selectedImage.alt}
            style={{ objectFit: "cover" }}
            fill
          />
        </Box>
      ) : (
        <Box
          sx={{
            mb: 8,
            ...vstack,
          }}
        >
          <Typography
            sx={{
              color: colorDefaults.text.textGray2,
              fontSize: "0.875rem",
              fontWeight: "600",
              lineHeight: "134%",
              textAlign: "center",
              mb: 1,
            }}
          >
            No image
          </Typography>
          <Typography
            sx={{
              maxWidth: "18rem",
              color: colorDefaults.text.textGray2,
              fontSize: "0.75rem",
              fontWeight: "400",
              lineHeight: "140%",
              textAlign: "center",
            }}
          >
            Images are not required, but they the improve access pass user
            experience and site navigation.
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          gap: 1,
          position: "absolute",
          bottom: "0.5rem",
        }}
      >
        {/* <ImagePickerAction>
          <label htmlFor="building-image" className='HStack hfull m-0'>
            <Icon>
              <UploadIcon />
            </Icon>
            <span>Upload image</span>
            <input
              id="building-image"
              name="building-image"
              onChange={onFileUpload}
              hidden
              accept=".jpg,.jpeg,.png,.webp"
              type="file"
            />
          </label>
        </ImagePickerAction> */}
        {context.state.selectedImage && (
          <ImagePickerAction
            onClick={() => {
              context.setState({
                ...context.state,
                selectedImage: undefined,
              })
            }}
          >
            <Icon>
              <TrashIcon />
            </Icon>
            <span>Remove</span>
          </ImagePickerAction>
        )}
        {context.state.images.length > 1 && (
          <ImagePickerAction
            onClick={() => {
              context.navigate("images", "forwards")
            }}
            iconAlignment="right"
          >
            <span>More images</span>
            <Icon>
              <ChevronRightIcon />
            </Icon>
          </ImagePickerAction>
        )}
      </Box>
    </Box>
  )
}

export default ImagePicker
