import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import Button from "@mui/material/Button"
import { useQueryClient } from "@tanstack/react-query"
import emailValidator from "email-validator"

import { useSeamMutation } from "hooks/useSeamMutation"

import ChipInput from "components/form/ChipInput"
import RoleInviteSelect from "components/menus/RoleInviteSelect/RoleInviteSelect"
import { RoleID } from "components/menus/RoleMenu/RoleMenu"
import InviteSentScreen from "components/modals/InviteMemberModal/InviteSentScreen"
import Modal, { Props as ModalProps } from "components/modals/Modal"
import Description from "components/modals/Modal/Description"
import Tooltip from "components/presentational/Tooltip"

type Props = Omit<ModalProps, "headerLabel" | "position" | "className">

const InviteMemberModal = (props: Props) => {
  return (
    <Modal
      headerLabel="Invite members"
      className="invite-member-modal"
      {...props}
    >
      <Content {...props} />
    </Modal>
  )
}

const Content = ({ isOpen, close }: Props) => {
  const [sent, setSent] = useState(false)
  const [role, setRole] = useState<RoleID>("org:member")

  const { control, handleSubmit, reset, watch } = useForm<{ emails: string[] }>(
    {}
  )

  const qc = useQueryClient()
  const emails = watch("emails", [])
  const hasEmails = emails.length > 0
  const hasBadEmail =
    emails.filter((e) => !emailValidator.validate(e)).length > 0

  const canSubmit = hasEmails && !hasBadEmail

  const { mutateAsync: sendInvitation } = useSeamMutation(
    (seam, ev: { email: string }) =>
      seam.organizations.invite_user({
        email: ev.email,
        role,
      })
  )

  // Clear form on open/close
  useEffect(() => {
    reset()
  }, [isOpen, reset])

  return (
    <>
      <InviteSentScreen
        showing={sent}
        numInvites={emails.length}
        role={role}
        onClose={close}
      />
      <Description>
        Enter email addresses to invite people to your organization. Recipients
        will receive an email with instructions.
      </Description>
      <form
        onSubmit={handleSubmit(async () => {
          for (const email of emails) {
            await sendInvitation({ email })
          }
          qc.invalidateQueries(["organizations", {}, "pending_users.list"])
          setSent(true)
        })}
      >
        <Controller
          name="emails"
          control={control}
          render={({ field: { value, onChange, onBlur } }) => (
            <ChipInput
              className="mb-6"
              placeholder="Enter email addresses"
              label={(itemCount) =>
                itemCount > 1 ? `Recipients (${itemCount})` : "Recipients"
              }
              onBlur={onBlur}
              helperText="Tip: Try pasting a list of email addresses to add multiple members. "
              value={value}
              onChange={onChange}
              isValid={emailValidator.validate}
            />
          )}
        />
        <div className="d-flex align-center mb-4">
          <span className="role-invite-label mr-2">
            Initial role for recipients
          </span>
          <RoleInviteSelect value={role} onChange={setRole} />
        </div>
        {hasBadEmail && (
          <div className="form-error">
            Please fix or remove bad email addresses before sending.
          </div>
        )}
        <div className="buttons">
          <Button variant="contained" color="secondary" onClick={close}>
            Cancel
          </Button>
          <Tooltip
            when={!hasEmails}
            anchor={
              <div>
                <Button variant="contained" type="submit" disabled={!canSubmit}>
                  Send
                </Button>
              </div>
            }
            content={<span>Please enter at least one email address</span>}
            openOnHover
          />
        </div>
      </form>
    </>
  )
}

export default InviteMemberModal
