import { useSeamQuery } from "hooks/useSeamQuery"

import { rolesOrderedByAccess } from "lib/constants/roles"

import { OrgRole } from "types"

export type UserOrgPermissions = {
  userRole: OrgRole
  canViewAllBuildings: boolean
}

/**
 * Utility to quickly get the highest role of a user in relation to the current
 * organization.
 *
 * This returns a user's highest role in the organization, technically a user
 * can have multiple roles, but usually we only care about the highest role in
 * the UI (e.g. there's usually only a dropdown for a single role to be
 * selected)
 *
 */
export const useUserOrgRole = (user_id?: string): UserOrgPermissions => {
  const { data: userOrgRolesList } = useSeamQuery(
    ["users", { user_id }, "list_organization_roles"],
    // TODO change to get_organization_roles when it's available on the backend,
    // but lowest priority since this is equivalent
    (seam) => seam.users.list_organization_roles({ user_ids: [user_id!] }),
    { enabled: Boolean(user_id) }
  )
  const userOrgRoles = userOrgRolesList?.[0]?.organization_roles ?? []

  for (const role of rolesOrderedByAccess) {
    if (userOrgRoles.includes(role)) {
      return {
        userRole: role,
        canViewAllBuildings: Boolean(
          userOrgRolesList?.[0]?.can_view_all_buildings
        ),
      }
    }
  }

  return { userRole: "org:member", canViewAllBuildings: false }
}

export default useUserOrgRole
