import { useCallback, useEffect, useState } from "react"
import { Autocomplete, Button, Checkbox, TextField } from "@mui/material"

import Screen from "components/modals/LinkedAccountsModal/fragments/Screen"
import { PageProps } from "components/modals/LinkedAccountsModal/screens/types"

const buildings = [
  "1300 Euclid",
  "Azure Apartments",
  "Bayview Lofts",
  "Bridgeview Suites",
  "Dakota Village",
  "Franklin Parkside",
  "General Apartments",
  "Horizon Condos",
]

const devices = [
  {
    name: "Unit 9A Front door",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 9A Rear entrance",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 9C Front door",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 9C Rear Entrance",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 11A Front door",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 11A Rear entrance",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 9A Front door 2",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 9A Rear entrance 2",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 9C Front door 2",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 9C Rear Entrance 2",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 11A Front door 2",
    model: "August WiFi Smart Lock Pro",
  },
  {
    name: "Unit 11A Rear entrance 2",
    model: "August WiFi Smart Lock Pro",
  },
]

const DevicesScreen = ({
  progressData,
  setProgressData,
  screenProps,
}: PageProps) => {
  const [building, setBuilding] = useState<string>("")
  const [selected, setSelected] = useState<string[]>([])

  const toggleSelected = useCallback(
    (id: string) => {
      if (selected.includes(id)) {
        setSelected(selected.filter((item) => item !== id))
      } else {
        setSelected([...selected, id])
      }
    },
    [selected]
  )

  const selectAll = () => {
    setSelected(devices.map((device) => device.name))
  }

  useEffect(() => {
    setProgressData((progressData) => ({
      ...progressData,
      devicesSelected: selected,
      building,
    }))
  }, [selected, building])

  return (
    <Screen {...screenProps}>
      <div className="wfull hfull">
        <div className="wfull mb-6">
          <div className="HStack justify-c">
            <p className="m-0">Add devices to</p>
            <div className="ml-2">
              <Autocomplete
                disablePortal
                options={buildings}
                value={building}
                onChange={(event, newValue) => {
                  setBuilding(newValue || "")
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    // label="Movie"
                    hiddenLabel
                    variant="outlined"
                    sx={{
                      background: "#F3F5F7",
                      border: "none !important",
                      boxShadow: "none !important",
                    }}
                    {...params}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className="devices-list">
          <div className="header">
            <div className="SBStack">
              <div>
                <div className="HStack">
                  <button className="header-action" onClick={selectAll}>
                    All
                  </button>
                  <p className="fs--1 mx-2 opacity-05">•</p>
                  <button
                    className="header-action"
                    onClick={() => setSelected([])}
                  >
                    None
                  </button>
                </div>
              </div>
              <div>
                <p className="fs--1 m-0">
                  {selected.length} of {devices.length} selected
                </p>
              </div>
            </div>
          </div>
          <div className="list">
            {devices.map((device, index) => (
              <div key={`${device.name}${index}`} className="device-row">
                <div className="HStack wfull hfull">
                  <div className="mr-2">
                    <Checkbox
                      onChange={() => toggleSelected(device.name)}
                      checked={selected.includes(device.name)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                  <img
                    src="/devices/smartthings/august.png"
                    alt="August lock"
                    height="60%"
                  />

                  <div className="VStack ml-2">
                    <p className="fw-600 lh-1 m-0">{device.name}</p>
                    <p className="fs--1 opacity-06 lh-1 m-0">{device.model}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="buttons">
        <Button variant="outlined" onClick={screenProps.moveBackward}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={screenProps.moveForward}
          disabled={selected.length === 0 || building === ""}
        >
          Next
        </Button>
      </div>
    </Screen>
  )
}

export default DevicesScreen
