import { useRouter } from "next/router"
import { Box, Skeleton, Typography } from "@mui/material"
import { countBy } from "lodash"
import pluralize from "pluralize"

import { useSeamQuery } from "hooks/useSeamQuery"

import { usePanels } from "providers/PanelsProvider"

import { Incident } from "./Incident"
import Row from "./Row"

import { brands } from "lib/brands"
import { LinkedAccountType, UNKNOWN_LOCK_IMAGE } from "lib/constants"

interface Props {
  incident: Incident
}

const LinkedAccountRow = ({ incident }: Props) => {
  const linked_account_id = incident.linked_account_id

  // TODO (Optimization): query for linked account device & building counts only
  const { data: devices } = useSeamQuery(
    ["devices", { linked_account_id }, "list"],
    (seam) => seam.devices.list({ linked_account_id }),
    { enabled: Boolean(linked_account_id) }
  )

  const { data: linkedAccount, isLoading } = useSeamQuery(
    ["linked_accounts", { linked_account_id }, "get"],
    (seam) => seam.linked_accounts.get({ linked_account_id }),
    { enabled: Boolean(linked_account_id) }
  )

  const { push } = useRouter()
  const panels = usePanels()

  if (isLoading) {
    return (
      <Skeleton animation="wave" variant="rounded" height={60} width="100%" />
    )
  }

  const onClick = () => {
    push(`/linked_account/${incident.linked_account_id}`)
    panels.set("issueModal", false)
  }

  const brand = linkedAccount?.account_type
  const brandImage = {
    // TODO: we need an "UNKNOWN_BRAND_IMAGE"
    src: brand ? brands[brand as LinkedAccountType].logo : UNKNOWN_LOCK_IMAGE,
    alt: brand
      ? brands[brand as LinkedAccountType].readableName
      : "Unknown Account Type",
  }

  const deviceCount = devices ? devices.length : 0

  const buildingCount = Object.keys(countBy(devices, "building_id")).length

  const linkedAccountEmail =
    linkedAccount?.user_identifier?.email ?? linkedAccount?.linked_account_id

  const buildingCaption =
    `${buildingCount} ` + pluralize("building", buildingCount)

  const deviceCaption = `${deviceCount} ` + pluralize("device", deviceCount)

  return (
    <Row
      incidentType={incident.incident_type}
      label={linkedAccountEmail}
      caption={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: 0.6,
          }}
        >
          {buildingCaption}
          <Typography>•</Typography>
          {deviceCaption}
        </Box>
      }
      imageDetails={brandImage}
      onClick={onClick}
    />
  )
}

export default LinkedAccountRow
