import { Toaster } from "react-hot-toast"
import type { NextComponentType, NextPageContext } from "next"
import type { AppProps } from "next/app"
import { Auth0Provider } from "@auth0/auth0-react"
import { ThemeProvider } from "@mui/material"
import { LicenseInfo } from "@mui/x-license-pro"
import { LoadScriptNext } from "@react-google-maps/api"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import { AuthProvider } from "providers/AuthProvider"
import { PanelsProvider } from "providers/PanelsProvider"
import {
  FakeSeamClientProvider,
  SeamClientProvider as LiveSeamClientProvider,
} from "providers/SeamClientProvider"

import Private from "templates/Private"

import HandleOrgInvite from "components/auth/HandleOrgInvite"

import "../styles/seam.css" // Required for custom components
import "../styles/main.scss"

import { theme } from "lib/mui"

import type { Children } from "types"

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY ?? "")

const queryClient = new QueryClient()

const googleMapLibraries = ["places"]

interface Props extends AppProps {
  Component: NextComponentType<NextPageContext, any, any> & {
    // Components can declare a template for themselves. The template property
    // is static which means that it is not calculated during the rendering
    // of the component itself. This is one way to preserve state between
    // route changes.
    //
    // The default component template is the RootTemplate component.
    template?: ({ children }: Children) => JSX.Element
  }
}

const shouldUseFakeApi = () => {
  if (!process.env.NEXT_PUBLIC_USE_FAKE_API) return true
  if (process.env.NEXT_PUBLIC_USE_FAKE_API === "false") return false
  if (process.env.NEXT_PUBLIC_USE_FAKE_API === "true") return true
  if (process.env.NODE_ENV === "test") return true
  return typeof window === "undefined"
    ? false
    : window.location.hostname.includes("demo")
}

const App = ({ Component, pageProps }: Props) => {
  const Template = Component.template || Private

  const SeamClientProvider = shouldUseFakeApi()
    ? FakeSeamClientProvider
    : LiveSeamClientProvider

  return (
    <QueryClientProvider client={queryClient}>
      <SeamClientProvider>
        <ThemeProvider theme={theme}>
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
            authorizationParams={{
              scope: "openid email profile",
              redirect_uri: `${process.env.NEXT_PUBLIC_SPACE_FRONTEND_URL}/auth/callback`,
            }}
          >
            <AuthProvider>
              <PanelsProvider>
                <div className="hide md:show bg-amber-100 flex-c text-c p-4">
                  <p className="m-0">
                    Seam Space isn&apos;t mobile friendly just yet. For the best
                    experience, please use a desktop browser.
                  </p>
                </div>
                <LoadScriptNext
                  loadingElement={<div></div>}
                  googleMapsApiKey={
                    process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ""
                  }
                  // Ignoring this because it's wanting a union[], but we're
                  // declaring it manually, so it's fine.
                  // @ts-ignore
                  libraries={googleMapLibraries}
                >
                  <Template>
                    <Component {...pageProps} />
                  </Template>
                </LoadScriptNext>
                <Toaster />
                <HandleOrgInvite />
              </PanelsProvider>
            </AuthProvider>
          </Auth0Provider>
        </ThemeProvider>
      </SeamClientProvider>
    </QueryClientProvider>
  )
}

export default App
