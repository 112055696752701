import React, { cloneElement } from "react"

import useMyOrgRole from "hooks/auth/useMyOrgRole"

import Menu from "components/menus/Menu"
import { MenuProps } from "components/menus/Menu/Menu"
import MenuTrigger from "components/menus/MenuTrigger"
import RoleItem from "components/menus/RoleMenu/RoleItem"

import { roles } from "lib/constants/roles"

// TODO: This should [probably] be a type from the API
export type RoleID = "org:member" | "org:admin" | "org:superadmin"

export interface RoleMenuProps extends Omit<MenuProps, "onChange" | "button"> {
  value: RoleID
  onChange: (roleID: RoleID) => void
  topContent?: JSX.Element
  bottomContent?: JSX.Element
}

const RoleMenu = ({
  value: selected,
  onChange,
  topContent,
  bottomContent,
  ...MenuProps
}: RoleMenuProps) => {
  const myRole = useMyOrgRole().userRole

  const selectedRole = roles.find((r) => r.id === selected)
  if (!selectedRole) {
    throw new Error(`Undefined role: ${selected}`)
  }

  return (
    <div className="role-menu">
      <Menu
        PaperProps={{
          className: "role-menu-list",
        }}
        button={({ open, isOpen }) => (
          <MenuTrigger
            isOpen={isOpen}
            onClick={open}
            icon={selectedRole.icon}
            label={selectedRole.label.replace(" (default)", "")}
          />
        )}
        {...MenuProps}
      >
        {[
          topContent ? cloneElement(topContent, { key: "top" }) : null,
          ...roles
            .filter((role) => {
              if (myRole === "org:superadmin") {
                return true
              } else if (myRole === "org:admin") {
                return role.id === "org:member" || role.id === "org:admin"
              } else {
                return role.id === "org:member"
              }
            })
            .map((role) => (
              <RoleItem
                key={role.id}
                icon={role.icon}
                label={role.label}
                caption={role.caption}
                isSelected={role.id === selectedRole.id}
                onClick={() => {
                  onChange(role.id)
                  close()
                }}
              />
            )),
          bottomContent ? cloneElement(bottomContent, { key: "bottom" }) : null,
        ]}
      </Menu>
    </div>
  )
}

export default RoleMenu
