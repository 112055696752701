import Image from "next/image"
import Button from "@mui/material/Button"

import { RoleID } from "components/menus/RoleMenu/RoleMenu"

interface Props {
  numInvites: number
  role: RoleID
  showing: boolean
  onClose: () => void
}

const getRoleLabel = (role: RoleID, pluralize: boolean) => {
  switch (role) {
    case "org:member":
      return pluralize ? "members" : "member"
    case "org:admin":
      return pluralize ? "admins" : "admin"
    case "org:superadmin":
      return pluralize ? "super admins" : "super admin"
    default:
      throw new Error(`Label not set for role: '${role}'`)
  }
}

const InviteSentScreen = ({ numInvites, role, showing, onClose }: Props) => {
  if (!showing) {
    return null
  }

  const title =
    numInvites > 1
      ? `${numInvites} invites were sent`
      : `${numInvites} invite was sent`

  const roleLabel = getRoleLabel(role, numInvites > 1)
  const description =
    numInvites > 1 ? (
      <span>
        Recipients will be added as <strong>{roleLabel}</strong> after signing
        in. They can create Seam Space accounts using a link in the email if
        needed.
      </span>
    ) : (
      <span>
        The recipient will be added as a <strong>{roleLabel}</strong> after
        signing in, and can create a Seam Space account using a link in the
        email if needed.
      </span>
    )
  return (
    <div className="invite-sent-screen">
      <div className="inner">
        <Image
          src="/icons/green-check.svg"
          alt="Success"
          width={40}
          height={40}
        />
        <span className="title">{title}</span>
        <p className="description">{description}</p>
      </div>
      <div className="footer">
        <Button variant="contained" color="secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  )
}

export default InviteSentScreen
