// Slightly modified from https://github.com/xplato/alexandria/blob/main/src/storage.ts

const isServer = typeof window === "undefined"

export const saveObject = <T>(key: string, value: T): void => {
  if (isServer) return

  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    // Unsupported
  }
}

export const getBlob = (key: string): string | undefined => {
  if (isServer) return undefined

  let blob

  try {
    blob = localStorage.getItem(key) || undefined
  } catch (e) {
    // Unsupported
  }

  return blob || undefined
}

export const getSavedObject = <T>(key: string, fallback: T): T => {
  const blob = getBlob(key)

  if (typeof blob === "undefined") {
    saveObject(key, fallback)
    return fallback
  }

  let value: T = fallback

  try {
    value = JSON.parse(blob) as T

    if (Array.isArray(fallback) && !Array.isArray(value)) {
      console.error("Invalid array", key, value)
      saveObject(key, fallback)
      return fallback
    }
  } catch (e) {
    // Invalid
    saveObject(key, Array.isArray(fallback) ? "[]" : "{}")
  }

  return value
}
