import { Button } from "@mui/material"

import Screen from "components/modals/LinkedAccountsModal/fragments/Screen"
import { PageProps } from "components/modals/LinkedAccountsModal/screens/types"

const DevicesAddedScreen = ({ progressData, screenProps }: PageProps) => {
  const count = progressData.devicesSelected.length

  return (
    <>
      <Screen {...screenProps}>
        <div className="wfull hfull flex-c mt-32">
          <div className="wfull flex-c">
            <img src="/ui/check.svg" width={60} height={60} />
          </div>

          <p className="mt-6">
            {count === 12 ? "All 12" : count}{" "}
            {count > 1 ? "devices were" : "device was"} added to{" "}
            {progressData.building}!
          </p>
        </div>
      </Screen>

      <div className="buttons">
        <Button
          variant="contained"
          onClick={() => {
            screenProps.close()
            screenProps.reset()
          }}
        >
          Done
        </Button>
      </div>
    </>
  )
}

export default DevicesAddedScreen
