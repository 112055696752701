// eslint-disable-next-line no-restricted-syntax
export enum LinkedAccountType {
  Akuvox = "akuvox",
  August = "august",
  Brivo = "brivo",
  ButterflyMX = "butterflymx",
  DoorKing = "doorking",
  Genie = "genie",
  Linear = "linear",
  Lockly = "lockly",
  Nuki = "nuki",
  Salto = "salto",
  Schlage = "schlage",
  Seam = "seam_relay",
  // SeamRelay = "seam_relay",
  SmartThings = "smartthings",
  Yale = "yale_access",
  TwoN = "2n",
}
