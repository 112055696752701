import LinkingLogo from "components/modals/LinkedAccountsModal/fragments/LinkingLogo"

import { LinkedAccountType } from "lib/constants"

interface Props {
  src?: string
  alt?: string
  seam?: boolean
}

const LinkingImage = ({ src, alt, seam }: Props) => {
  return (
    <div className="linking-image">
      <LinkingLogo brand="Seam" />
      {/* <div className="w-40"> */}
      <img src="/ui/link.svg" />
      {/* </div> */}
      <LinkingLogo brand={LinkedAccountType.August} />
    </div>
  )
}

export default LinkingImage
