import { useMemo } from "react"
import { Box, Skeleton, Typography } from "@mui/material"
import moment from "moment-timezone"

import { useSeamQuery } from "hooks/useSeamQuery"

import Brand from "components/brand/Brand"
import Card from "components/cards/Card"
import Tile from "components/cards/Tile"
import DeviceImage from "components/media/DeviceImage"
import BatteryTile from "components/pages/devices/sidebar/BatteryTile"
import { LinkedAccountCard } from "components/pages/devices/sidebar/LinkedAccountCard"
import LockedTile from "components/pages/devices/sidebar/LockedTile"
import Caption from "components/typography/Caption"

import Sidebar from "./Sidebar"

import { convertDeviceTypeToBrand } from "lib/brands"
import { DEVICE_TYPES, LinkedAccountType } from "lib/constants"
import { convertSnakeToTitleCase, interweave } from "lib/utils"
import { getDeviceProductModel } from "lib/utils/devices"

interface Props {
  device: any
  isLoading: boolean
  linkedAccount: any
}

const Device = ({ device, linkedAccount, isLoading }: Props) => {
  const device_id = device?.device_id
  const { data: accessPasses, isLoading: accessLoading } = useSeamQuery(
    ["access_codes", { device_id }, "list"],
    (seam) => seam.access_codes.list({ device_id }),
    { enabled: Boolean(device_id) }
  )

  const { activePassCount, futurePassCount } = useMemo(() => {
    if (accessLoading || !accessPasses)
      return { activePassCount: 0, futurePassCount: 0 }
    const activePassCount = accessPasses.filter((pass: any) => {
      if (pass.type == "ongoing") return true
      const startsAtDatetime = moment(pass.starts_at)
      const endsAtDatetime = moment(pass.ends_at)
      if (startsAtDatetime.isAfter(moment())) return false
      if (endsAtDatetime.isBefore(moment())) return false
      return true
    }).length
    const futurePassCount = accessPasses.filter((pass: any) => {
      if (pass.type == "ongoing") return false
      const startsAtDatetime = moment(pass.starts_at)
      const endsAtDatetime = moment(pass.ends_at)
      if (startsAtDatetime.isAfter(moment())) return true
      if (endsAtDatetime.isBefore(moment())) return false
      return false
    }).length
    return { activePassCount, futurePassCount }
  }, [accessPasses, accessLoading])

  if (isLoading || !device)
    return (
      <Sidebar>
        <Skeleton
          sx={{
            background: "#EFF2F5",
            width: "100%",
            height: "900px",
          }}
          variant="rounded"
        />
      </Sidebar>
    )

  const metadataKey = Object.keys(device.properties).find((key) =>
    /_metadata/.test(key)
  )
  const details: Record<string, any> = metadataKey
    ? device.properties[metadataKey]
    : {}

  const detailKeys = Object.keys(details)
  const detailValues = Object.values(details)

  const brand = convertDeviceTypeToBrand(device.device_type)

  return (
    <Sidebar>
      {isLoading && (
        <Skeleton
          sx={{
            background: "#EFF2F5",
            borderRadius: "16px",
            width: "100%",
            height: "187px",
          }}
          variant="rectangular"
        />
      )}
      <DeviceImage
        deviceModel={getDeviceProductModel(device)}
        deviceType={device.device_type}
        online={device.properties.online}
      />

      <div className="w-100p grid grid-2 my-6">
        <BatteryTile
          batteryLevel={device.properties.battery_level}
          isPowered={device.properties.is_powered}
        />
        <LockedTile
          locked={device.properties.locked}
          salto_locked_state={
            device.device_type == DEVICE_TYPES.SALTO_LOCK &&
            device.properties.salto_metadata.locked_state
          }
        />
      </div>

      <Card title="Access passes">
        <div className="w-100p grid grid-2 mt-4">
          <Tile value={activePassCount} label="Active" contained />
          <Tile value={futurePassCount} label="Future" contained />
        </div>
      </Card>

      <LinkedAccountCard
        accountType={brand || LinkedAccountType.August}
        userIdentifier={linkedAccount.user_identifier}
        createdAt={linkedAccount.created_at}
      />

      <Card
        title="Device details"
        sx={{
          mt: 3,
        }}
      >
        <Box my={1}>
          <Brand brand={brand} />
        </Box>

        <Box
          mt={3}
          mb={4}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: "0.25rem 0",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "0.75rem 1rem",
            }}
          >
            {interweave(detailKeys, detailValues).map((value, index) => {
              const isKey = index % 2 === 0
              return (
                <Box key={value}>
                  <Caption
                    sx={{
                      lineHeight: 1,
                      mb: 0,
                    }}
                  >
                    {isKey ? convertSnakeToTitleCase(value) : value}
                    {isKey ? ":" : ""}
                  </Caption>
                </Box>
              )
            })}
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              fontSize: "1rem",
              lineHeight: "1.25rem",
              fontWeight: "600",
              mb: 1,
            }}
          >
            Device ID
          </Typography>
          <Caption>{device.device_id}</Caption>
        </Box>
      </Card>
    </Sidebar>
  )
}

export default Device
