import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Button } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"

import { useSeamMutation } from "hooks/useSeamMutation"

import Input from "components/form/Input"
import InputLabel from "components/form/InputLabel"
import LocationInput from "components/form/LocationInput"
import Screen from "components/modals/StackModal/fragments/Screen"
import { StackOperatingContext } from "components/modals/StackModal/types"

import {
  BuildingInfoModalData,
  Image,
  useBuildingInfoModalContext,
} from "../context"
import ImagePicker from "../fragments/ImagePicker"

import { DEMO } from "lib/constants/demo"
import { guessTimezone } from "lib/utils"
import { extractGoogleImageAttribution } from "lib/utils/google"

interface Props {
  context: StackOperatingContext<BuildingInfoModalData>
}

const BuildingInfoScreen = ({}: Props) => {
  const { push } = useRouter()
  const qc = useQueryClient()
  const context = useBuildingInfoModalContext()

  useEffect(() => {
    context.setShowBackButton(false)
  }, [])

  const [name, setName] = useState<string>(() => {
    return context.state.name
  })

  const getGooglePlaceId = (): string => {
    if (process.env.NEXT_PUBLIC_DEMO === "true") {
      return DEMO.googlePlaceId
    }

    return context.state?.location?.place_id ?? ""
  }

  const { mutateAsync: createBuilding } = useSeamMutation(async (seam, _ev) => {
    const building = await seam.buildings.create({
      name,
      timezone: guessTimezone(),
      image_url: context.state?.selectedImage
        ? context.state?.selectedImage.url
        : undefined,
      ext_google_place_id: getGooglePlaceId(),
    })
    qc.invalidateQueries(["buildings"])
    push(`/buildings/${building.building_id}`)
  })

  const { mutateAsync: updateBuilding } = useSeamMutation(async (seam, _ev) => {
    await seam.buildings.update({
      building_id: context.state?.building_id!,
      name,
      timezone: guessTimezone(),
      image_url: context.state?.selectedImage
        ? context.state?.selectedImage.url
        : undefined,
      ext_google_place_id: getGooglePlaceId(),
    })
    qc.invalidateQueries(["buildings"])
    qc.invalidateQueries([
      "building",
      { building_id: context.state?.building_id! },
      "get",
    ])
  })

  const handleSave = async () => {
    if (!canContinue()) return
    if (context.state.mode === "create") {
      await createBuilding({})
    } else if (context.state.mode === "edit") {
      await updateBuilding({})
    }
  }

  const canContinue = () => {
    if (name.trim() === "") {
      return false
    }

    if (context.state.location === null) {
      return false
    }

    return true
  }

  return (
    <Screen>
      <div className="scrollable-content">
        <InputLabel altClassName="mb-0">Building name</InputLabel>
        <Input
          value={name}
          onChange={(ev) => {
            setName(ev.target.value)
          }}
          fullWidth
          nativeFocus
        />

        <LocationInput
          defaultValue={context.state.location}
          onChange={(location) => {
            if (location) {
              if (location.photos) {
                const images: Image[] = location.photos.map((photo) => {
                  return {
                    url: photo.getUrl(),
                    alt:
                      photo.html_attributions.length > 0
                        ? extractGoogleImageAttribution(
                            photo.html_attributions[0]
                          )
                        : "Google photo",
                  }
                })

                context.setState({
                  ...context.state,
                  name,
                  location,
                  images,
                  selectedImage: context.state.selectedImage || images[0],
                })
              } else {
                context.setState({
                  ...context.state,
                  name,
                  location,
                  images: [],
                  selectedImage: undefined,
                })
              }
            }
          }}
          containerProps={{
            mt: 4,
          }}
        />

        {context.state.images.length > 0 && (
          <>
            <InputLabel
              sx={{
                mt: 4,
              }}
            >
              Building image
            </InputLabel>

            <ImagePicker />
          </>
        )}
      </div>
      <div className="buttons">
        <Button variant="contained" color="secondary" onClick={context.close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSave()
            context.close()
          }}
          disabled={!canContinue()}
        >
          Save
        </Button>
      </div>
    </Screen>
  )
}

export default BuildingInfoScreen
