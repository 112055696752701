import { useSeamQuery } from "hooks/useSeamQuery"
import useSessionStore from "hooks/useSessionStore"

/**
 * Utility to quickly get the current organization.
 */
export const useMyOrganization = () => {
  const organization_id = useSessionStore((state) => state.organization_id)

  const { data: organization } = useSeamQuery(
    ["organizations", { organization_id }, "get"],
    (seam) =>
      seam.organizations.get({ organization_id } as {
        organization_id: string
      }),
    { enabled: Boolean(organization_id) }
  )

  return organization
}

export default useMyOrganization
