import type { Children, Direction } from "types"

export interface Props extends Children {
  direction: Direction
  moveForward: () => void
  moveBackward: () => void
  close: () => void
  reset: () => void
}

const Screen = ({ direction, children }: Props) => {
  return <div className="screen">{children}</div>
}

export default Screen
