// A basic menu item button

import { ButtonBase, ButtonBaseProps } from "@mui/material"

import { colorDefaults } from "lib/mui"

interface Props extends ButtonBaseProps {}

const MenuItem = ({ children, ...props }: Props) => {
  return (
    <ButtonBase
      sx={{
        width: "100%",
        height: "2.5rem",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "0 1rem",

        "&:hover": {
          backgroundColor: colorDefaults.background.bgA,
        },
      }}
      {...props}
    >
      {children}
    </ButtonBase>
  )
}

export default MenuItem
