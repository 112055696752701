import { ChangeEventHandler, useCallback, useEffect, useState } from "react"
import { toast } from "react-hot-toast"
import { Button } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"

import { useBuilding } from "hooks/api/useBuilding"
import { useSeamMutation } from "hooks/useSeamMutation"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import TimingBlock from "components/modals/AccessPassCreateModal/fragments/TimingBlock"
import Screen from "components/modals/StackModal/fragments/Screen"

import Accordion from "../fragments/Accordion"

const DetailsScreen = () => {
  const context = useAccessPassModalContext()

  // const handleNameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
  //   (ev) => {
  //     context.setState((data) => ({
  //       ...data,
  //       name: (ev.target as HTMLInputElement).value,
  //     }))
  //   },
  //   []
  // )

  const [name, setName] = useState(context.state.name ?? "")
  const { building } = useBuilding()

  const queryClient = useQueryClient()
  const { isLoading: isSaving, mutateAsync: saveAccessPass } = useSeamMutation(
    async (seam, event: typeof context.state) => {
      const { devices, name, start, end } = event

      if (!devices) throw new Error("Can't create access pass without devices!")
      if (!name) throw new Error("Can't create access pass without name!")
      if (!building)
        throw new Error("Can't create access pass without building!")

      const starts_at = start?.date
        ?.add(start?.time?.valueOf(), "ms")
        ?.toISOString()

      const ends_at = end?.date?.add(end?.time?.valueOf(), "ms")?.toISOString()

      await seam.access_passes.create({
        devices: devices.map((d) => ({
          device_id: d.device_id,
          can_use_access_code: true,
          can_use_remote_unlock: true,
        })),
        starts_at,
        ends_at,
        access_pass_name: name,
        building_id: building.building_id,
      })
      queryClient.invalidateQueries(["access_passes"])
    },
    {}
  )

  useEffect(() => {
    context.setHeaderLabel("Access Pass Details")
    context.setShowBackButton(true)
  }, [])

  if (!building) {
    return <Screen />
  }

  return (
    <Screen>
      <div className="wfull HStack justify-c">
        <p className="text text-c m-0 mr-4">Access pass name</p>
        <input
          name="name"
          id="name"
          type="text"
          className="s-input lg"
          placeholder="Guest: John Doe, Electrician, etc."
          defaultValue={name}
          onChange={(e) => {
            setName(e.target.value)
            context.state.name = e.target.value
            // Doing direct mutation to prevent entire DetailScreen re-rendering. Entire re-rendering unfocuses
            // input on every change. Fine for demo, will re-address later.
          }}
        />
      </div>

      <div className="wfull VStack">
        <div className="detail-label-wrap">
          <h3>Devices</h3>
        </div>
        <Accordion />
      </div>

      <div className="wfull VStack pb-16">
        <div className="detail-label-wrap">
          <h3>Timing</h3>
        </div>
        <TimingBlock timezone={building.timezone} />
      </div>

      <div className="buttons">
        <Button variant="contained" color="secondary" onClick={context.close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            saveAccessPass(context.state)
              .then(() => context.next())
              .catch((e) => toast.error(e.toString()))
          }}
          disabled={isSaving || name.trim() === ""}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </div>
    </Screen>
  )
}

export default DetailsScreen
