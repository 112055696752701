import { MouseEvent, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"

import useScrollLock from "hooks/useScrollLock"

import Header from "components/modals/Modal/Header"
import { setModalMinHeight } from "components/modals/Modal/set-min-height"
import Animated from "components/motion/Animated"
import Keyboard from "components/utility/Keyboard"

import { variants } from "lib/constants/motion"

import type { Children } from "types"

export interface ModalProps extends Children {
  isOpen: boolean
  close: () => void
  headerLabel: string
  onBackButtonClick?: () => void
  showCloseButton?: boolean
  clickOutsideToClose?: boolean
  className?: string
  headerHeight?: string | number
}

const Modal = ({
  isOpen,
  close,
  headerLabel,
  onBackButtonClick,
  showCloseButton = true,
  clickOutsideToClose = true,
  headerHeight = "56px",
  children,
  className,
}: ModalProps) => {
  const containerEl = useRef<HTMLDivElement>(null)
  const scroll = useScrollLock(containerEl)
  const [innerEl, setInnerEl] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      scroll.lock()

      // When modal opens, set focus so that user can use keyboard to navigate
      containerEl.current?.focus()
    } else {
      scroll.unlock()
    }
  }, [isOpen, scroll])

  // Automatically adjusts the modal's min-height to intentionally
  // clip content on very short viewports to indicate to the
  // user that there is more content to scroll.
  useEffect(() => {
    if (!innerEl || !isOpen) {
      return
    }

    const observer = new ResizeObserver(setModalMinHeight)
    observer.observe(innerEl)

    return () => {
      try {
        observer.unobserve(innerEl)
      } catch {
        // ignore potential errors if element has already been removed
      }
    }
  }, [isOpen, innerEl])

  return (
    <AnimatePresence>
      {isOpen && (
        <Animated
          variants={variants.fade.default}
          transition={{
            type: "tween",
            duration: 0.3,
          }}
          className={classNames("modal", className)}
          onClick={clickOutsideToClose ? close : undefined}
        >
          <Animated
            variants={variants.scale.partial}
            transition={{
              type: "spring",
              duration: 0.5,
            }}
            className="inner"
            onClick={
              clickOutsideToClose
                ? (ev: MouseEvent) => ev.stopPropagation()
                : undefined
            }
            ref={setInnerEl}
          >
            <Header
              close={close}
              label={headerLabel}
              onBackButtonClick={onBackButtonClick}
              showCloseButton={showCloseButton}
              height={headerHeight}
            />
            <div className="content" tabIndex={0} ref={containerEl}>
              {children}
            </div>
          </Animated>

          <Keyboard
            keys={["Escape"]}
            onKeyPress={close}
            handleFocusableElements
          />
        </Animated>
      )}
    </AnimatePresence>
  )
}

export default Modal
