import { useState } from "react"
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs from "dayjs"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"

interface Props {
  rangePosition: "start" | "end"
}

const DatePicker = ({ rangePosition }: Props) => {
  const context = useAccessPassModalContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const getMinDate = () => {
    // Can't select a date before the start date
    if (rangePosition === "end") {
      return context.state.start?.date || dayjs()
    } else {
      return dayjs()
    }
  }

  return (
    <div className="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUIDatePicker
          open={isOpen}
          label={`Choose ${rangePosition} date`}
          value={context.state[rangePosition]?.date}
          onChange={(newValue) => {
            if (!newValue) return

            context.setState((data) => ({
              ...data,
              [rangePosition]: {
                ...data[rangePosition],
                date: newValue,
              },
            }))
          }}
          disableOpenPicker
          closeOnSelect
          minDate={getMinDate()}
          inputFormat="ddd MMM D, YYYY"
          // This is a known issue:
          // https://github.com/mui/mui-x/issues/4487
          //
          // Aparently, the new components would resolve this issue
          // by disassociating the input from the picker, but that's
          // currently in alpha as of 01/2023.
          //
          // Currently, the `disableMaskedInput` prop is the only
          // workaround. This does, however, mean that the input
          // is uncontrolled, so manually typing a date will not
          // cause the DatePicker to reflect that value. This is
          // suitable for the demo, but this must be revisited
          // soon.
          mask="___ ___ _, ____"
          disableMaskedInput
          disablePast
          renderDay={(day, selectedDays, props) => (
            <PickersDay onClick={() => setIsOpen(false)} {...props} />
          )}
          renderInput={({ inputRef, inputProps }) => (
            <div className="wfull HStack relative">
              <input
                ref={inputRef}
                type="text"
                className="s-input wfull"
                onClick={() => setIsOpen(!isOpen)}
                {...inputProps}
              />
            </div>
          )}
        />
      </LocalizationProvider>
    </div>
  )
}

export default DatePicker
