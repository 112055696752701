import { ForwardedRef, forwardRef, MouseEventHandler } from "react"
import { motion, MotionProps } from "framer-motion"

import type { Children } from "types"

interface Props extends Children, MotionProps {
  onClick?: MouseEventHandler
  [key: string]: any
}

const Animated = forwardRef(
  (
    { variants, children, ...props }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <motion.div
        ref={ref}
        variants={variants}
        initial="hidden"
        animate="visible"
        exit={variants?.exit ? "exit" : "hidden"}
        {...props}
      >
        {children}
      </motion.div>
    )
  }
)
Animated.displayName = "Animated"

export default Animated
