import React, { useEffect } from "react"
import { useRouter } from "next/router"
import * as Sentry from "@sentry/nextjs"
import { pick } from "lodash"

import { useSeamClient } from "hooks/useSeamClient"
import useSessionStore from "hooks/useSessionStore"

import RootTemplate from "templates/RootTemplate"

import type { Children } from "types"

const Private = ({ children }: Children) => {
  const router = useRouter()
  const {
    organization_id,
    seam_user_session_token,
    setOrganizationId,
    setPostAuthRedirectUrl,
  } = useSessionStore((s) =>
    pick(s, [
      "organization_id",
      "seam_user_session_token",
      "setOrganizationId",
      "setPostAuthRedirectUrl",
    ])
  )
  const seam = useSeamClient()

  useEffect(() => {
    if (!seam_user_session_token) {
      setPostAuthRedirectUrl(router.asPath)
      router.push("/logout")
    }
  }, [])

  useEffect(() => {
    async function handleEffect() {
      try {
        const res = await seam.axios.get("/organizations/list")
        if (res.status != 200)
          throw new Error(`Failed to fetch organizations: ${res.statusText}`)

        if (res.data.organizations.length === 0) {
          router.push("/organizations/no-org")
          return
        }

        if (!organization_id) {
          setOrganizationId(res.data.organizations[0].organization_id)
        }
      } catch (err) {
        console.error(err)
        Sentry.captureException(err)
      }
    }
    handleEffect()
  }, [])

  return (
    <RootTemplate>
      <div className="private-template">{children}</div>
    </RootTemplate>
  )
}

export default Private
