// A button that looks like a link

import { ButtonBase, ButtonBaseProps, SxProps } from "@mui/material"

import { colorDefaults } from "lib/mui"

import { Children } from "types"

interface Props extends Children, ButtonBaseProps {
  sx?: SxProps
}

const LinkButton = ({ sx, children, ...props }: Props) => {
  return (
    <ButtonBase
      sx={{
        color: colorDefaults.text.hyperlink,
        ...sx,
      }}
      {...props}
    >
      {children}
    </ButtonBase>
  )
}

export default LinkButton
