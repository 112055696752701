import { useCallback } from "react"
import { Checkbox } from "@mui/material"

import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import DatetimePicker from "components/modals/AccessPassCreateModal/fragments/TimingBlock/DatetimePicker"

interface Props {
  timezone: string
}

const TimingBlock = ({ timezone }: Props) => {
  const context = useAccessPassModalContext()

  const toggle = useCallback(() => {
    context.setState((data) => ({
      ...data,
      customTimeEnabled: !context.state.customTimeEnabled,
    }))
  }, [context.state.customTimeEnabled])

  return (
    <div className="device-time-picker">
      <div className="VStack">
        <div className="SBStack wfull">
          <div className="HStack">
            <Checkbox
              color="primary"
              checked={Boolean(context.state.customTimeEnabled)}
              onChange={toggle}
              className="mr-2"
            />
            <label className="caption m-0 no-select" onClick={toggle}>
              Specify dates & times
            </label>
          </div>
          <div className="HStack">
            {context.state.customTimeEnabled && (
              <p className="picker-caption" title={timezone}>
                All times in {timezone}
              </p>
            )}
          </div>
        </div>

        <div className="VStack align-c wfull mt-4 pb-2">
          {!context.state.customTimeEnabled && (
            <p className="picker-caption italic">
              This pass will be active 24/7 until it is manually disabled.
            </p>
          )}

          {context.state.customTimeEnabled && (
            <div className="tracks wfull grid grid-2 gap-6">
              <div className="track">
                <DatetimePicker rangePosition="start" />
              </div>
              <div className="track">
                <DatetimePicker rangePosition="end" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TimingBlock
