import { SxProps, Typography } from "@mui/material"

import type { Children } from "types"

interface Props extends Children {
  sx?: SxProps
}

const Caption = ({ sx, children, ...props }: Props) => (
  <Typography
    variant="body1"
    sx={{
      fontSize: "0.875rem",
      lineHeight: "1.1875rem",
      color: "rgba(35, 43, 58, 1)",
      ...sx,
    }}
    {...props}
  >
    {children}
  </Typography>
)

export default Caption
