import Icon from "components/media/Icon"

interface Props {
  close: () => void
}

const CloseButton = ({ close }: Props) => {
  return (
    <button onClick={close} className="close-button">
      <Icon icon="x" />
    </button>
  )
}

export default CloseButton
