import { StaticImageData } from "next/image"
import * as Sentry from "@sentry/react"

import {
  DEVICE_TYPES,
  LinkedAccountType,
  SALTO_DEVICE_MODEL_IMAGES,
  SaltoLockModel,
  SMARTTHINGS_DEVICE_MODEL_IMAGES,
  SmartThingsLockModel,
  UNKNOWN_LOCK_IMAGE,
} from "lib/constants"

type Brand = {
  [key in LinkedAccountType]: {
    logo: StaticImageData
    readableName: string
  }
}

import TwoNLogo from "../public/brands/Logo-2N.png"
import AkuvoxLogo from "../public/brands/Logo-Akuvox.png"
import AugustLogo from "../public/brands/Logo-August.png"
import BrivoLogo from "../public/brands/Logo-Brivo.png"
import ButterflyMXLogo from "../public/brands/Logo-ButterflyMX.png"
import DoorkingLogo from "../public/brands/Logo-Doorking.png"
import GenieLogo from "../public/brands/Logo-Genie.png"
import LinearLogo from "../public/brands/Logo-Linear.png"
import LocklyLogo from "../public/brands/Logo-Lockly.png"
import NukiLogo from "../public/brands/Logo-Nuki.png"
import SaltoLogo from "../public/brands/Logo-Salto.png"
import SchlageLogo from "../public/brands/Logo-Schlage.png"
import SeamLogo from "../public/brands/Logo-Seam.png"
import SmartthingsLogo from "../public/brands/Logo-Smartthings.png"
import YaleLogo from "../public/brands/Logo-Yale.png"

export const brands: Brand = {
  akuvox: {
    logo: AkuvoxLogo,
    readableName: "Akuvox",
  },
  august: {
    logo: AugustLogo,
    readableName: "August",
  },
  brivo: {
    logo: BrivoLogo,
    readableName: "Brivo",
  },
  butterflymx: {
    logo: ButterflyMXLogo,
    readableName: "ButterflyMX",
  },
  doorking: {
    logo: DoorkingLogo,
    readableName: "DoorKing",
  },
  genie: {
    logo: GenieLogo,
    readableName: "Genie",
  },
  linear: {
    logo: LinearLogo,
    readableName: "Linear",
  },
  lockly: {
    logo: LocklyLogo,
    readableName: "Lockly",
  },
  nuki: {
    logo: NukiLogo,
    readableName: "Nuki",
  },
  salto: {
    logo: SaltoLogo,
    readableName: "Salto",
  },
  schlage: {
    logo: SchlageLogo,
    readableName: "Schlage",
  },
  seam_relay: {
    logo: SeamLogo,
    readableName: "Seam",
  },
  smartthings: {
    logo: SmartthingsLogo,
    readableName: "SmartThings",
  },
  yale_access: {
    logo: YaleLogo,
    readableName: "Yale",
  },
  "2n": {
    logo: TwoNLogo,
    readableName: "2N",
  },
}

export const fetchBrandLogoAndName = (
  type: LinkedAccountType
): { logo: StaticImageData; readableName: string } | undefined => {
  if (!type) return undefined
  const logo_and_name = brands?.[type]
  if (!logo_and_name) {
    Sentry.captureException(
      new Error(`No logo and name found for brand ${type}`)
    )
    return {
      logo: SeamLogo,
      readableName: "Unknown",
    }
  }
  return logo_and_name
}

// Not every available brand has an image in Seam Connect :(
export const allBrands = [
  "2N",
  "Akuvox",
  "Assa Abloy",
  "Alloy",
  "August",
  "AlPhone",
  "Brivo",
  "ButterflyMX",
  "Chamberlain",
  "Comlit",
  "Donalock",
  "DoorBird",
  "DoorKing",
  "Dormakaba",
  "Genie",
  "Hotek",
  "HID",
  "IglooHome",
  "kisi",
  "kwikset",
  "Latch",
  "Liftmaster",
  "Lenel",
  "Level",
  "Linear",
  "Lockly",
  "Miwa",
  "myQ",
  "mircom",
  "nuki",
  "Patch and Co",
  "OpenPath",
  "Saflok",
  "Salto",
  "Schlage",
  "SmartThings",
  "Ultraloq",
  "Vingcard",
  "Verkada",
  "Viking",
  "Wyze",
  "Yale",
  "4Suites",
]

export interface ImageDetails {
  alt: string
  src: StaticImageData
}

import TwoNLockImg from "../public/devices/2n/2n_ip-verso.png"
import DoorkingCallboxImg from "../public/devices/doorking/doorking_1834_front.png"
import LocklyLockImg from "../public/devices/lockly/lockly_secure-pro_deadbolt-smart-lock_satin-nickel_front.png"
import SaltoLockImg from "../public/devices/salto/salto_xs4-original=keypad-scandinavian_satin-stainless-steel.png"
import SchlageLockImg from "../public/devices/schlage/schlage_connect-smart-deadbolt_with-camelot-trim_z-wave-plus-enabled_satin-nickel_front.png"
import AugustLockImg from "../public/devices/smartthings/august.png"
import YaleLockImg from "../public/devices/yale/yale_assure-lock-touchscreen-with-z-wave-plus_satin-nickel_front.png"

export function fetchBrandImageDetails(
  deviceType: string,
  deviceModel?: string
): ImageDetails {
  switch (deviceType) {
    // case DEVICE_TYPES.AKUVOX_LOCK:
    //   return {
    //     alt: "Akuvox",
    //     src: "/images/akuvox.png",
    //   }
    case DEVICE_TYPES.AUGUST_LOCK:
      return {
        alt: "August Lock",
        src: AugustLockImg,
      }
    case DEVICE_TYPES.SCHLAGE_LOCK:
      return {
        alt: "Schlage Lock",
        src: SchlageLockImg,
      }
    case DEVICE_TYPES.SMARTTHINGS_LOCK:
      if (deviceModel) {
        return {
          alt: deviceModel,
          src:
            SMARTTHINGS_DEVICE_MODEL_IMAGES[
              deviceModel as SmartThingsLockModel
            ] || UNKNOWN_LOCK_IMAGE,
        }
      }
      return {
        alt: "SmartThings Hub connected Lock",
        src: UNKNOWN_LOCK_IMAGE,
      }
    case DEVICE_TYPES.YALE_LOCK:
      return {
        alt: "Yale Lock",
        src: YaleLockImg,
      }
    case DEVICE_TYPES.LOCKLY_LOCK:
      return {
        alt: "Lockly Lock",
        src: LocklyLockImg,
      }
    case DEVICE_TYPES.DOORKING_LOCK:
      return {
        alt: "Doorking Callbox",
        src: DoorkingCallboxImg,
      }
    // case DEVICE_TYPES.BUTTERFLYMX_PANEL:
    //   return {
    //     alt: "ButterflyMX Intercom",
    //     src: "/images/akuvox.png",
    //   }
    // case DEVICE_TYPES.GENIE_DOOR:
    //   return {
    //     alt: "Genie Garage Opener",
    //     src: "/images/akuvox.png",
    //   }
    case DEVICE_TYPES.SALTO_LOCK:
      if (deviceModel) {
        return {
          alt: deviceModel,
          src:
            SALTO_DEVICE_MODEL_IMAGES[deviceModel as SaltoLockModel] ||
            UNKNOWN_LOCK_IMAGE,
        }
      }
      return {
        alt: "Salto Lock",
        src: SaltoLockImg,
      }
    case DEVICE_TYPES.TWON_LOCK:
      return {
        alt: "2N Lock",
        src: TwoNLockImg,
      }
    // case DEVICE_TYPES.LINEAR_LOCK:
    //   return {
    //     alt: "Akuvox Intercom",
    //     src: "/images/akuvox.png",
    //   }
    // case DEVICE_TYPES.SEAM_RELAY:
    //   return {
    //     alt: "Seam Relay",
    //     src: "/images/akuvox.png",
    //   }
    // case DEVICE_TYPES.BRIVO_ACCESS_POINT:
    //   return {
    //     alt: "Brivo",
    //     src: "/images/akuvox.png",
    //   }
    default:
      return {
        alt: "Unknown Device",
        src: UNKNOWN_LOCK_IMAGE,
      }
  }
}

export const convertDeviceTypeToBrand = (deviceType: string) => {
  switch (deviceType) {
    case DEVICE_TYPES.AKUVOX_LOCK:
      return LinkedAccountType.Akuvox
    case DEVICE_TYPES.AUGUST_LOCK:
      return LinkedAccountType.August
    case DEVICE_TYPES.SCHLAGE_LOCK:
      return LinkedAccountType.Schlage
    case DEVICE_TYPES.SMARTTHINGS_LOCK:
      return LinkedAccountType.SmartThings
    case DEVICE_TYPES.LOCKLY_LOCK:
      return LinkedAccountType.Lockly
    case DEVICE_TYPES.YALE_LOCK:
      return LinkedAccountType.Yale
    case DEVICE_TYPES.DOORKING_LOCK:
      return LinkedAccountType.DoorKing
    case DEVICE_TYPES.BUTTERFLYMX_PANEL:
      return LinkedAccountType.ButterflyMX
    case DEVICE_TYPES.GENIE_DOOR:
      return LinkedAccountType.Genie
    case DEVICE_TYPES.SALTO_LOCK:
      return LinkedAccountType.Salto
    case DEVICE_TYPES.LINEAR_LOCK:
      return LinkedAccountType.Linear
    case DEVICE_TYPES.SEAM_RELAY:
      return LinkedAccountType.Seam
    case DEVICE_TYPES.BRIVO_ACCESS_POINT:
      return LinkedAccountType.Brivo
    case DEVICE_TYPES.TWON_LOCK:
      return LinkedAccountType.TwoN
    default:
      return undefined
  }
}
