import {
  Box,
  ButtonBase,
  Snackbar as MUISnackbar,
  SnackbarProps,
  Typography,
} from "@mui/material"
import Slide, { SlideProps } from "@mui/material/Slide"

import Icon from "components/media/Icon"

import { icons } from "lib/constants"

import { Children } from "types"

const hoverEffect = {
  transition: "filter 0.2s",

  "&:hover": {
    filter: "brightness(0.8)",
  },
}

type Variant = "success" | "info" | "warning" | "error"

const iconMap: Record<Variant, keyof typeof icons> = {
  success: "successCircle",
  info: "infoSnackbar",
  warning: "warningTriangle",
  error: "errorFilled",
}

const SlideTransition = (props: SlideProps) => {
  return <Slide {...props} direction="up" />
}

interface Props extends Children {
  isOpen: boolean
  onClose: Required<SnackbarProps>["onClose"]
  variant?: Variant
  dismissable?: boolean
  autoHideDuration?: number
  message?: string
  action?: string
  onActionClick?: () => void
}

const Snackbar = ({
  isOpen,
  onClose,
  variant = "info",
  dismissable = true,
  autoHideDuration,
  message,
  action,
  onActionClick,
  children,
}: Props) => {
  if (action && !onActionClick) {
    throw new Error("onActionClick must be provided if action is provided")
  }

  if (onActionClick && !action) {
    throw new Error("action must be provided if onActionClick is provided")
  }

  return (
    <MUISnackbar
      open={isOpen}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      autoHideDuration={autoHideDuration}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "1rem",
          borderRadius: "0.75rem",
          backgroundColor: "#30373A",
          boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Icon icon={iconMap[variant]} size="1.5rem" />

        {message && (
          <Typography
            sx={{
              maxWidth: "30rem",
              fontSize: "1rem",
              fontWeight: 400,
              lineHeight: "134%",
              color: "white",
              ml: "0.625rem",
            }}
          >
            {message}
          </Typography>
        )}

        {children}

        {action && (
          <ButtonBase
            onClick={onActionClick}
            sx={{
              ml: "1rem",
              cursor: "pointer",
              ...hoverEffect,
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: 600,
                lineHeight: "134%",
                color: "var(--hyperlink)",
                whiteSpace: "nowrap",
              }}
            >
              {action}
            </Typography>
          </ButtonBase>
        )}

        {dismissable && (
          <ButtonBase
            onClick={(event) => onClose(event, "escapeKeyDown")}
            sx={{
              color: "white",
              cursor: "pointer",
              ml: "1rem",
              ...hoverEffect,
            }}
          >
            <Icon icon="xSnackbar" size="1.5rem" />
          </ButtonBase>
        )}
      </Box>
    </MUISnackbar>
  )
}

export default Snackbar
