import classNames from "classnames"

import useMyOrganization from "hooks/auth/useMyOrganization"

import Screen, {
  Props as ScreenProps,
} from "components/modals/LinkedAccountsModal/fragments/Screen"

import { allBrands, brands } from "lib/brands"

interface Props {
  screenProps: ScreenProps
}

const BrandsListScreen = ({ screenProps }: Props) => {
  const myOrg = useMyOrganization()

  return (
    <Screen {...screenProps}>
      <p className="text text-c m-0 mb-4">
        Select the brand for an existing external account that you&apos;d like
        to link to the {myOrg?.name ?? "A1 properties"} organization.
      </p>

      <div className="brand-box">
        {allBrands.map((brand, index) => {
          if (!Object.keys(brands).includes(brand.toLowerCase())) return null

          return (
            <div
              key={`${brand}${index}`}
              className="brand-row"
              onClick={screenProps.moveForward}
            >
              <div className="hstack align-c">
                <div className="image-wrap">
                  <img
                    // @ts-ignore
                    src={brands[brand.toLowerCase()].logo.src}
                    alt={brand}
                    className={classNames(
                      "brand-image",
                      brand === "Akuvox" && "invert"
                    )}
                  />
                </div>
                <span>{brand}</span>
              </div>
            </div>
          )
        })}
      </div>
    </Screen>
  )
}

export default BrandsListScreen
