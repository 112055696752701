import { Context, useState } from "react"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"

import Modal, { Props as ModalProps } from "components/modals/Modal"
import AnimatedPage from "components/pages/accessPasses/AnimatedPage"

import { ScreenProps, StackOperatingContext } from "./types"

import { Direction } from "types"

type Screen<T extends {}> = ({ context }: ScreenProps<T>) => JSX.Element

interface Props<T extends {}> {
  OperatingContext: Context<StackOperatingContext<T>>

  // Screens and sequence are separate so that consumers can arbitrarily
  // switch screens. This simulates a stack-based navigation in that you
  // can move between screens while revolving around a larger structured
  // sequence. Eventually, I'll expand this logic in SeamUI to incorporate
  // a real stack navigation system so that UIs like these can be more
  // easily and reliably constructed.
  screens: {
    [id: string]: Screen<T>
  }
  sequence: string[]

  isOpen: boolean
  close: () => void
  headerLabel: string
  screenProps?: T
  onClose?: () => void
  onReset?: () => void
  className?: string

  modalProps?: Omit<
    ModalProps,
    "isOpen" | "close" | "headerLabel" | "onBackButtonClick"
  >
}

const StackModal = <T extends {}>({
  OperatingContext,
  screens,
  sequence,
  isOpen,
  close: _close,
  headerLabel: _headerLabel,
  screenProps,
  onClose,
  onReset,
  className,
  modalProps,
}: Props<T>) => {
  const [currentScreen, setCurrentScreen] = useState<string>(
    Object.keys(screens)[0]
  )
  const [direction, setDirection] = useState<Direction>("forwards")
  const [headerLabel, setHeaderLabel] = useState<string>(_headerLabel)
  const [onBackButtonClickGoTo, setOnBackButtonClickGoTo] = useState<
    string | undefined
  >(undefined)
  const [showBackButton, setShowBackButton] = useState<boolean>(true)

  const navigate = (to: string, direction: Direction = "forwards") => {
    setDirection(direction)
    setCurrentScreen(to)
  }

  const isCurrentScreenInSequence = () => {
    if (!sequence.includes(currentScreen)) {
      throw new Error(
        "StackModal: Cannot perform automatic navigation; Current screen not specified in sequence."
      )
    }
  }

  const next = () => {
    isCurrentScreenInSequence()

    const index = sequence.indexOf(currentScreen)

    // Check if next index is out of sequence bounds
    if (index + 1 > sequence.length - 1) {
      throw new Error(
        "StackModal: Cannot perform automatic navigation; Next index out of sequence bounds (there is no next screen)"
      )
    }

    navigate(sequence[index + 1], "forwards")
  }

  const previous = () => {
    isCurrentScreenInSequence()

    const index = sequence.indexOf(currentScreen)

    // Check if previous index is out of sequence bounds
    if (index - 1 < 0) {
      throw new Error(
        "StackModal: Cannot perform automatic navigation; Previous index out of sequence bounds (there is no previous screen)"
      )
    }

    navigate(sequence[index - 1], "backwards")
  }

  const reset = () => {
    setCurrentScreen(Object.keys(screens)[0])
    setDirection("forwards")
    setHeaderLabel(_headerLabel)
    setOnBackButtonClickGoTo(undefined)

    if (typeof onReset === "function") onReset()
  }

  const close = () => {
    reset()
    _close()

    if (typeof onClose === "function") onClose()
  }

  ///

  const handleBackButtonClick = () => {
    if (onBackButtonClickGoTo) {
      navigate(onBackButtonClickGoTo, "backwards")
      setOnBackButtonClickGoTo(undefined)
    } else {
      previous()
    }
  }

  // TODO: Revisit
  // @ts-ignore
  const context: StackOperatingContext<T> = {
    currentScreen,
    direction,
    navigate,
    next,
    previous,
    close,
    headerLabel,
    setHeaderLabel,
    onBackButtonClickGoTo,
    setOnBackButtonClickGoTo,
    showBackButton,
    setShowBackButton,
    ...screenProps,
  }

  return (
    <OperatingContext.Provider value={context}>
      <Modal
        isOpen={isOpen}
        close={close}
        headerLabel={headerLabel}
        onBackButtonClick={showBackButton ? handleBackButtonClick : undefined}
        {...modalProps}
      >
        <div className={classNames("sequence-modal", className)}>
          <AnimatePresence mode="wait" initial={false} custom={direction}>
            {Object.entries(screens).map(([id, CurrentScreen]) => {
              if (id !== currentScreen) return null

              return (
                <AnimatedPage key={id} direction={direction}>
                  <CurrentScreen context={context} />
                </AnimatedPage>
              )
            })}
          </AnimatePresence>
        </div>
      </Modal>
    </OperatingContext.Provider>
  )
}

export default StackModal
