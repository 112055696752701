import Icon from "components/media/Icon"
import CloseButton from "components/modals/Modal/CloseButton"

interface Props {
  close: () => void
  label: string
  height: string | number
  onBackButtonClick?: () => void
  showCloseButton?: boolean
}

const Header = ({
  close,
  label,
  height,
  onBackButtonClick,
  showCloseButton,
}: Props) => {
  return (
    <header
      className="modal-header"
      style={{
        flex: `0 0 ${height}`,
      }}
    >
      <div className="grid-block flex align-s justify-c">
        {!!onBackButtonClick && (
          <button className="header-button" onClick={onBackButtonClick}>
            <Icon icon="chevronLeft" />
          </button>
        )}
      </div>
      <div className="grid-block flex-c">
        <div className="h-100p flex-c">
          <p className="label">{label}</p>
        </div>
      </div>
      <div className="grid-block flex align-e">
        {showCloseButton && (
          <div className="h-100p flex-c">
            <CloseButton close={close} />
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
