import { useEffect, useState } from "react"
import Image from "next/image"
import { Button, Checkbox } from "@mui/material"

import CopyInput from "components/interactive/CopyInput"
import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import OptionalBlock from "components/modals/AccessPassCreateModal/fragments/OptionalBlock"
import Screen from "components/modals/StackModal/fragments/Screen"

interface Details {
  email: string
  phone: string
}

const ShareScreen = () => {
  const context = useAccessPassModalContext()

  const [details, setDetails] = useState({
    email: "",
    phone: "",
  })

  const set = <K extends keyof Details>(key: K, value: Details[K]) => {
    setDetails((details) => ({
      ...details,
      [key]: value,
    }))
  }

  useEffect(() => {
    context.setShowBackButton(false)
    context.setHeaderLabel("Share it!")
  }, [])

  return (
    <Screen>
      <div className="wfull flex-c">
        <div className="mw-100 flex-c text-c">
          <p className="fs--2 opacity-0.6 text-c m-0 mb-4">
            Use this form to share your access pass. Sending via text or email
            will automatically save a timestamped record.
          </p>

          <OptionalBlock label="Send email">
            <div className="wfull HStack align-s">
              <div className="pt-2">
                <p className="text text-c m-0 mr-4 white-space-nowrap">
                  Email address
                </p>
              </div>
              <div className="VStack wfull">
                <input
                  name="name"
                  id="name"
                  type="text"
                  className="s-input mnw-60 wfull"
                  placeholder="someone@example.com"
                  value={details.email}
                  onChange={(ev) => set("email", ev.target.value)}
                />
                <div className="HStack mt-2">
                  <Checkbox color="primary" size="small" className="mr-2" />
                  <label className="caption opacity-0.7 m-0">
                    Send me a copy
                  </label>
                </div>
              </div>
            </div>
          </OptionalBlock>

          <OptionalBlock label="Send text message">
            <div className="wfull HStack justify-c">
              <p className="text text-c m-0 mr-4 white-space-nowrap">
                Phone number
              </p>
              <input
                name="phone"
                id="phone"
                type="text"
                maxLength={10}
                className="s-input mnw-60 wfull"
                placeholder="xxx-xxx-xxxx"
                value={details.phone}
                onChange={(ev) => set("phone", ev.target.value)}
              />
            </div>
          </OptionalBlock>

          <div className="VStack wfull">
            <div className="HStack">
              <Image src="/icons/link.svg" alt="Link" width={24} height={24} />
              <p className="caption fw-600 m-0 ml-2">Link</p>
            </div>
            <div className="block">
              <div className="HStack">
                <p className="white-space-nowrap m-0 mr-4">Share this link</p>
                <CopyInput value="https://seam.to/jk21" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="buttons">
        <Button variant="contained" color="secondary" onClick={context.close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={context.next}
          disabled={details.email === "" && details.phone === ""}
        >
          Send
        </Button>
      </div>
    </Screen>
  )
}

export default ShareScreen
