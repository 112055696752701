import { useSeamQueryWithoutOrg } from "hooks/useSeamQueryWithoutOrg"
import useSessionStore from "hooks/useSessionStore"

import { getInitials } from "lib/utils"

export const useUser = (userId?: string) => {
  const { data: user, ...query } = useSeamQueryWithoutOrg(
    ["users", { user_id: userId }, "get"],
    (seam) => seam.users.get({ user_id: userId! }),
    { enabled: Boolean(userId) }
  )

  const fullName = user ? `${user.first_name} ${user.last_name}` : "..."
  const initials = user
    ? getInitials(`${user.first_name} ${user.last_name}`)
    : ""

  return {
    ...query,
    user: {
      ...user,
      full_name: fullName,
      initials,
    },
  }
}

export const useOwnUser = () => {
  const userId = useSessionStore((s) => s.user_id)

  return useUser(userId)
}
