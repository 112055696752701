import { useSeamQuery } from "hooks/useSeamQuery"

export const useBuildingQuery = (building_id: string | null) =>
  useSeamQuery(
    ["buildings", { building_id }, "get"],
    (seam) =>
      seam.buildings.get({
        building_id: building_id!,
      }),
    { enabled: Boolean(building_id) }
  )

export const useDeviceQuery = (device_id: string | null) =>
  useSeamQuery(
    ["devices", { device_id }, "get"],
    (seam) =>
      seam.devices.get({
        device_id: device_id!,
      }),
    { enabled: Boolean(device_id) }
  )
