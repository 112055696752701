import { createContext, useContext, useState } from "react"

import AccessPassCreateModal from "components/modals/AccessPassCreateModal"
import AddBuildingModal from "components/modals/AddBuildingModal"
import AddDevicesModal from "components/modals/AddDevicesModal"
import EditBuildingModal from "components/modals/EditBuildingModal"
import InviteMemberModal from "components/modals/InviteMemberModal/InviteMemberModal"
import IssueModal from "components/modals/IssueModal"
import LinkedAccountsModal from "components/modals/LinkedAccountsModal"
import { ModalProps } from "components/modals/Modal/Modal"
import CreateOrganizationModal from "components/modals/OrganizationInfoModal/CreateOrganizationModal"
import EditOrganizationModal from "components/modals/OrganizationInfoModal/EditOrganizationModal"
import OrgSetupModal from "components/modals/OrgSetupModal"

import { Children } from "types"

export type PanelID =
  | "issueModal"
  | "linkedAccountModal"
  | "accessPassCreateModal"
  | "orgSetupModal"
  | "inviteMemberModal"
  | "addBuildingModal"
  | "editBuildingModal"
  | "addDevicesModal"
  | "createOrganizationModal"
  | "editOrganizationModal"

export interface Panel {
  id: string
  isOpen: boolean
}

interface State {
  get: (id: PanelID) => Panel | undefined
  set: (id: PanelID, isOpen: boolean) => void
  open: (id: PanelID) => void
  close: (id: PanelID) => void
  closeAll: () => void
}

export const Context = createContext<State>({
  get: () => undefined,
  set: () => undefined,
  open: () => undefined,
  close: () => undefined,
  closeAll: () => undefined,
})

interface Props extends Children {}

export const PanelsProvider = ({ children }: Props) => {
  const [panels, setPanels] = useState<Panel[]>([])

  const get = (id: PanelID): Panel | undefined => {
    const found = panels.find((panel) => panel.id === id)

    if (!found) {
      setPanels((panels) => [...panels, { id, isOpen: false }])
    }

    return found
  }

  const set = (id: PanelID, isOpen: boolean) => {
    setPanels((panels) =>
      panels.map((panel) => {
        if (panel.id === id) {
          return { ...panel, isOpen }
        }
        return panel
      })
    )
  }

  const open = (id: PanelID) => set(id, true)

  const closeAll = () => {
    setPanels((panels) => panels.map((panel) => ({ ...panel, isOpen: false })))
  }

  const close = (id: PanelID) => set(id, false)

  const value: State = {
    get,
    set,
    open,
    close,
    closeAll,
  }

  const modalProps = (id: PanelID): Pick<ModalProps, "isOpen" | "close"> => ({
    isOpen: get(id)?.isOpen || false,
    close: () => close(id),
  })

  return (
    <Context.Provider value={value}>
      {children}

      <IssueModal {...modalProps("issueModal")} />
      <LinkedAccountsModal
        headerLabel="Link new account"
        {...modalProps("linkedAccountModal")}
      />
      <AccessPassCreateModal {...modalProps("accessPassCreateModal")} />
      <OrgSetupModal {...modalProps("orgSetupModal")} />
      <InviteMemberModal {...modalProps("inviteMemberModal")} />
      <AddBuildingModal {...modalProps("addBuildingModal")} />
      <EditBuildingModal {...modalProps("editBuildingModal")} />
      <AddDevicesModal {...modalProps("addDevicesModal")} />
      <CreateOrganizationModal {...modalProps("createOrganizationModal")} />
      <EditOrganizationModal {...modalProps("editOrganizationModal")} />
    </Context.Provider>
  )
}

/**
 * usePanels allows you to easily open a modal for many different purposes.
 *
 * To open a panel, use the `set` function:
 *
 * export default () => {
 *   const panels = usePanels()
 *   return (
 *     <button onClick={panels.set("issueModal", true)}>
 *        Open issue modal
 *     </button>
 *   )
 * )
 */
export const usePanels = () => {
  const context = useContext(Context)

  if (context === undefined) {
    throw new Error("usePanels must be used within a Provider")
  }

  return context
}
