import { useState } from "react"
import { BsStars } from "react-icons/bs"
import { Box, Button, ButtonBase, Popover, Typography } from "@mui/material"
import pluralize from "pluralize"

import { useSeamQuery } from "hooks/useSeamQuery"
import { useSeamQueryWithoutOrg } from "hooks/useSeamQueryWithoutOrg"

import { usePanels } from "providers/PanelsProvider"

import DynamicLink from "components/interactive/DynamicLink"
import Icon from "components/media/Icon"
import Keyboard from "components/utility/Keyboard"

import OrgSwitcher from "../OrgSwitcher"
import { links } from "./links"
import Trigger from "./Trigger"

import { colorDefaults } from "lib/mui"
import OrgPurpleIcon from "assets/images/org-purple.svg"

const Desktop = () => {
  const panels = usePanels()

  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const { data: organizations } = useSeamQueryWithoutOrg(
    ["organizations", {}, "list"],
    (seam) => seam.organizations.list({}),
    { enabled: isOpen }
  )

  const { data: incidents } = useSeamQuery(
    ["incidents", {}, "list"],
    (seam) => seam.incidents.list({}),
    { enabled: isOpen }
  )

  const deviceIncidents = (incidents ?? []).filter((i) => i.device_id)

  // Tracking the state of the OrgSwitcher here so that
  // we can show the overlay for the whole menu
  const [isOrgSwitcherOpen, setIsOrgSwitcherOpen] = useState(false)

  const hasOrganizations = organizations && organizations.length !== 0

  return (
    <div className="desktop-menu-wrapper relative">
      <Trigger
        onClick={(ev) => {
          setAnchorEl(ev.target as HTMLButtonElement)
          setIsOpen((isOpen) => !isOpen)
        }}
      />

      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          className: "desktop-menu",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {hasOrganizations ? (
          <>
            <OrgSwitcher
              isOpen={isOrgSwitcherOpen}
              onOpen={() => setIsOrgSwitcherOpen(true)}
              onClose={() => setIsOrgSwitcherOpen(false)}
              closeDesktopMenu={() => setIsOpen(false)}
            />

            {isOrgSwitcherOpen && (
              <div
                className="overlay"
                onClick={() => setIsOrgSwitcherOpen(false)}
              />
            )}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              padding: "0.75rem",
            }}
          >
            <Button
              onClick={() => {
                setIsOpen(false)
                panels.open("createOrganizationModal")
              }}
              variant="contained"
              color="secondary"
              sx={{
                width: "100%",
                height: "2.5rem",
                borderRadius: "0.5rem",
                padding: "0 0.75rem !important",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: 0.5,
                }}
              >
                <BsStars color={colorDefaults.primary.main} />
              </Box>
              <Typography
                sx={{
                  fontSize: "0.875rem",
                }}
              >
                Create your first organization!
              </Typography>
            </Button>
          </Box>
        )}

        {hasOrganizations && (
          <Box
            sx={{
              width: "100%",
              padding: "0 0.75rem",
              paddingBottom: "0.75rem",
            }}
          >
            <ButtonBase
              onClick={() => {
                setIsOpen(false)
                panels.open("orgSetupModal")
              }}
              sx={{
                width: "100%",
                height: "3rem",
                padding: "0 0.5rem",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                borderRadius: "0.5rem",
                border: `1px solid ${colorDefaults.text.textGray3}`,
                transition:
                  "var(--duration-moderate-02) var(--expressive-standard)",

                "&:hover": {
                  borderColor: colorDefaults.text.textGray2,
                  backgroundColor: colorDefaults.background.gray,
                },
              }}
            >
              <OrgPurpleIcon />
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  color: colorDefaults.text.textDefaultColor,
                  ml: 1,
                }}
              >
                Set up this organization
              </Typography>
            </ButtonBase>
          </Box>
        )}

        {deviceIncidents.length > 0 && (
          <>
            <div className="separator"></div>

            <div className="issue-block">
              <button
                onClick={() => {
                  panels.set("issueModal", true)
                  close()
                }}
                className="menu-action"
              >
                <Icon icon="errorLarge" />
                <p className="white-space-nowrap mb-0 mt-0 ml-1-5">
                  {`${deviceIncidents.length} ${pluralize(
                    "device issue",
                    deviceIncidents.length
                  )}`}
                </p>
              </button>
            </div>
          </>
        )}

        <div className="separator"></div>

        <div className="VStack wfull py-2">
          {links
            .filter((link) => {
              if (link.requiresOrg === true) {
                return hasOrganizations
              }

              return true
            })
            .map((link) => (
              <DynamicLink
                key={link.label}
                href={link.href ?? ""}
                className="menu-action"
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                <span>{link.label}</span>
              </DynamicLink>
            ))}
        </div>
      </Popover>

      <Keyboard
        keys={["Escape"]}
        onKeyPress={() => close()}
        handleFocusableElements
      />
    </div>
  )
}

export default Desktop
