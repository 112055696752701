import { useMemo } from "react"
import { Skeleton, Typography } from "@mui/material"
import { Box } from "@mui/system"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

import { useSeamQuery } from "hooks/useSeamQuery"

import Card from "components/cards/Card"
import Tile from "components/cards/Tile"
import Incidents from "components/data/Incidents"
import Map, { Pin } from "components/interactive/Map/Map"
import BuildingImage from "components/media/BuildingImage"
import BuildingMembers from "components/pages/buildings/BuildingMembers"

import Sidebar from "./Sidebar"

import { mapBy } from "lib/utils"

import { ApiResponseByClientMethod } from "types"

dayjs.extend(utc)
dayjs.extend(timezone)

interface Props {
  building?: ApiResponseByClientMethod<"buildings.get">
  isLoading: boolean
}

const Building = ({ building, isLoading }: Props) => {
  const building_id = building?.building_id

  const incidentsQuery = useSeamQuery(
    ["incidents", { building_id }, "list"],
    (seam) => seam.incidents.list({ building_id }),
    { enabled: Boolean(building_id) }
  )

  const incidentsByFormattedDate = useMemo(() => {
    if (!incidentsQuery.data || !building) return

    const today = dayjs().tz(building.timezone).format("MMM D, YYYY")

    return mapBy(incidentsQuery.data, (incident) => {
      const formattedDate = dayjs(incident.created_at)
        .tz(building.timezone)
        .format("MMM D, YYYY")
      return formattedDate === today ? "Today" : formattedDate
    })
  }, [building, incidentsQuery.data])

  const queries = ["is:online"]
  const { data: deviceCounts } = useSeamQuery(
    ["devices", { building_id }, "list_counts", { queries }],
    (seam) =>
      seam.devices.list_counts({
        queries,
        building_id,
      }),
    { enabled: Boolean(building_id) }
  )

  const { data: buildingCounts } = useSeamQuery(
    ["buildings", { building_id }, "get_count_summary"],
    (seam) => seam.buildings.get_count_summary({ building_id }),
    { enabled: Boolean(building_id) }
  )

  const { data: buildingUsers = [] } = useSeamQuery(
    ["users", { building_id }, "list"],
    (seam) => seam.users.list({ building_id }),
    { enabled: Boolean(building_id) }
  )

  const mapPins = useMemo<Pin[]>(
    () =>
      building && building.location
        ? [
            {
              lat: building.location.latitude,
              lng: building.location.longitude,
              href: `/buildings/${building.building_id}`,
            },
          ]
        : [],
    [building]
  )

  if (isLoading || !building)
    return (
      <Sidebar>
        <Skeleton
          sx={{
            background: "#EFF2F5",
            width: "100%",
            height: "900px",
          }}
          variant="rounded"
        />
      </Sidebar>
    )

  return (
    <Sidebar>
      <Map
        pins={mapPins}
        center={[building.location.latitude, building.location.longitude]}
        zoom={15}
        height={215}
        sx={{
          mb: 3,
        }}
        address={building.location}
      />

      {building?.image_url && <BuildingImage src={building.image_url} />}

      <div className="w-100p grid grid-2 my-6">
        <Tile value={deviceCounts?.[0].count} label="Devices online" />
        <Tile
          value={buildingCounts?.active_access_passes as number}
          label="Active passes"
        />
      </div>

      <BuildingMembers members={buildingUsers} />

      <Card
        title="Recent issues"
        sx={{
          mt: 3,
        }}
      >
        {incidentsByFormattedDate && incidentsByFormattedDate.size > 0 ? (
          <>
            {[...incidentsByFormattedDate.entries()].map(
              ([formattedDate, incidents]) => (
                <Incidents
                  key={formattedDate}
                  title={formattedDate}
                  incidents={incidents}
                />
              )
            )}
          </>
        ) : (
          <Box
            sx={{
              height: "10rem",
            }}
            className="flex-c"
          >
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
                mb: 0,
              }}
            >
              No recent activity
            </Typography>
          </Box>
        )}
      </Card>
    </Sidebar>
  )
}

export default Building
