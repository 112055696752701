import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import * as Sentry from "@sentry/nextjs"
import axios from "axios"

import { useSeamClient } from "hooks/useSeamClient"
import useSessionStore from "hooks/useSessionStore"

import Alert from "components/modals/Alert"
import Snackbar from "components/presentational/Snackbar"

import { icons } from "../../lib/constants"

const ERROR_TYPE_TO_TITLE: Record<string, string> = {
  already_member_of_organization: "Already a member",
  expired: "Sorry, your invitation has expired",
}

const HandleOrgInvite = () => {
  const [isHandlingInvite, setIsHandlingInvite] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [errorTitle, setErrorTitle] = useState<string | null>(null)
  const [msg, setMsg] = useState("")
  const router = useRouter()
  const seam = useSeamClient()
  const setOrganizationId = useSessionStore((s) => s.setOrganizationId)
  const seam_user_session_token = useSessionStore(
    (s) => s.seam_user_session_token
  )

  const { org_invite_token } = router.query
  useEffect(() => {
    // API call requires authentication
    if (!org_invite_token || !seam_user_session_token) return

    if (isHandlingInvite) return

    setIsHandlingInvite(true)

    const handleInvite = async () => {
      try {
        // todo: use useSeamMutation
        const {
          data: { joined_organization },
        } = await seam.axios.post("/internal/organization_invitations/accept", {
          org_invite_token,
        })

        setMsg(`You have joined the ${joined_organization.name} organization`)

        setOrganizationId(joined_organization.organization_id)
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.data.error.message) {
          setMsg(error.response?.data.error.message)
          setErrorTitle(
            ERROR_TYPE_TO_TITLE[error.response.data.error.type as string] ??
              "Error"
          )
        } else {
          Sentry.captureException(error, {
            extra: {
              org_invite_token,
            },
          })
          setMsg(
            "An unknown error occurred while trying to join the organization"
          )
          setErrorTitle("Error")
        }
      }

      setIsOpen(true)
      router.push("/")
    }

    void handleInvite()
  }, [
    org_invite_token,
    seam,
    seam_user_session_token,
    isHandlingInvite,
    setOrganizationId,
  ])

  if (errorTitle) {
    return (
      <Alert
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={errorTitle}
        content={msg}
        icon={icons.infoCircle}
        actions={
          <button onClick={() => setIsOpen(false)} className="primary">
            OK
          </button>
        }
        clickOutsideToClose={false}
      />
    )
  }

  return (
    <Snackbar
      message={msg ?? ""}
      isOpen={isOpen}
      onClose={(_, reason) => {
        // Require explicit closure
        if (reason === "clickaway") return

        setIsOpen(false)
      }}
      variant="success"
    />
  )
}

export default HandleOrgInvite
