export const DEVICE_TYPES = {
  AKUVOX_LOCK: "akuvox_lock",
  AUGUST_LOCK: "august_lock",
  SCHLAGE_LOCK: "schlage_lock",
  SMARTTHINGS_LOCK: "smartthings_lock",
  LOCKLY_LOCK: "lockly_lock",
  YALE_LOCK: "yale_lock",
  DOORKING_LOCK: "doorking_lock",
  BUTTERFLYMX_PANEL: "butterflymx_panel",
  GENIE_DOOR: "genie_door",
  SALTO_LOCK: "salto_lock",
  LINEAR_LOCK: "linear_lock",
  SEAM_RELAY: "seam_relay",
  BRIVO_ACCESS_POINT: "brivo_access_point",
  TWON_LOCK: "2n_lock",
} as const

export type DeviceType = (typeof DEVICE_TYPES)[keyof typeof DEVICE_TYPES]
