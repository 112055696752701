import React, { MouseEventHandler } from "react"
import MuiMenu, { MenuProps as BaseMenuProps } from "@mui/material/Menu"

export interface MenuProps extends Omit<BaseMenuProps, "open"> {
  disabled?: boolean
  button: (props: {
    open: (event: React.MouseEvent<HTMLElement>) => void
    isOpen: boolean
  }) => React.ReactElement
}

/**
 * How much space between the menu, and the anchor element.
 */
const SPACE_BETWEEN_ANCHOR_PX = 4

const Menu = ({
  button,
  anchorOrigin,
  transformOrigin,
  ...props
}: MenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuClick: MouseEventHandler<Element> = (ev) => {
    ev.stopPropagation()
    handleClose()
  }

  const open = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const isOpen = Boolean(anchorEl)

  const anchorHeight = anchorEl?.clientHeight || 0

  return (
    <>
      {button({ open, isOpen })}
      <MuiMenu
        onClick={handleMenuClick}
        MenuListProps={{
          disablePadding: true,
        }}
        anchorOrigin={
          anchorOrigin ?? {
            vertical: anchorHeight + SPACE_BETWEEN_ANCHOR_PX,
            horizontal: "left",
          }
        }
        transformOrigin={
          transformOrigin ?? {
            vertical: "top",
            horizontal: "left",
          }
        }
        PaperProps={{
          style: {
            minWidth: 150,
            zIndex: 5000,
          },
        }}
        keepMounted
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        {...props}
      />
    </>
  )
}

export default Menu
