import { useState } from "react"
import { toast } from "react-hot-toast"
import { Checkbox } from "@mui/material"
import classNames from "classnames"
import { AnimatePresence } from "framer-motion"

import Image from "components/media/Image"
import { useAccessPassModalContext } from "components/modals/AccessPassCreateModal/context"
import Animated from "components/motion/Animated"

import { fetchBrandImageDetails } from "lib/brands"
import { icons } from "lib/constants/icons"
import { generateMods } from "lib/utils"
import { getDeviceProductModel } from "lib/utils/devices"

import { Device } from "types"

const AVAILABLE_METHODS = ["Web unlock", "Access code"]

interface Props {
  device: Device
}

const Row = ({ device }: Props) => {
  const context = useAccessPassModalContext()
  const [open, setOpen] = useState<boolean>(false)

  const [selectedMethods, setSelectedMethods] =
    useState<string[]>(AVAILABLE_METHODS)

  const toggle = () => {
    setOpen(!open)
  }

  const toggleMethod = (method: string) => {
    if (selectedMethods.length === 1 && selectedMethods.includes(method)) {
      toast("You must select at least one method.", {
        icon: icons.error,
      })
      return
    }

    if (selectedMethods.includes(method)) {
      setSelectedMethods(selectedMethods.filter((m) => m !== method))
    } else {
      setSelectedMethods([...selectedMethods, method])
    }
  }

  const img = fetchBrandImageDetails(
    device.device_type,
    getDeviceProductModel(device)
  )

  return (
    <div
      className={classNames(
        "row",
        generateMods({
          open,
        })
      )}
    >
      <button className="trigger" onClick={toggle}>
        <div className="HStack">
          <div className="image">
            <Image src={img.src} alt={img.alt} />
          </div>
          <h4 className="name">{device.name}</h4>
        </div>
        <div className="HStack">
          <p className="caption">{selectedMethods.join(", ")}</p>
          <i className="icon size-6">{icons.accordionChevron}</i>
        </div>
      </button>

      <div className="content">
        <AnimatePresence>
          {open && (
            <Animated
              variants={{
                hidden: {
                  opacity: 0,
                  height: 0,
                },
                visible: {
                  opacity: 1,
                  height: "auto",
                },
              }}
              className="content-inner"
            >
              <div className="wfull SBStack">
                <div className="HStack">
                  {AVAILABLE_METHODS.map((method) => (
                    <div key={method} className="HStack mr-4">
                      <Checkbox
                        name={method}
                        id={method}
                        color="primary"
                        checked={selectedMethods.includes(method)}
                        onChange={() => {
                          toggleMethod(method)
                        }}
                        size="small"
                        className="mr-1"
                      />
                      <label htmlFor={method} className="caption m-0">
                        {method}
                      </label>
                    </div>
                  ))}
                </div>
                <button
                  className="row-action"
                  onClick={() => {
                    if (context.state) {
                      context.setState({
                        ...context.state,
                        device:
                          context.state.devices?.filter(
                            (d) => d.device_id === device.device_id
                          )[0] || ({} as Device),
                      })
                    }

                    context.navigate("deviceInfo", "forwards")
                  }}
                >
                  <span>Device info</span>
                  <i className="icon">{icons.accordionChevron}</i>
                </button>
              </div>
              <div className="h-4"></div>
            </Animated>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Row
