import { Skeleton } from "@mui/material"
import { partition } from "lodash"

import { useSeamQuery } from "hooks/useSeamQuery"

import Modal, { Props as ModalProps } from "components/modals/Modal"

import DeviceIssues from "./DeviceIssues"
import { Incident } from "./Incident"
import LinkedAccountIssues from "./LinkedAccountIssues"

type Props = Omit<ModalProps, "headerLabel">

const IssueModal = ({ ...props }: Props) => {
  const options = { is_resolved: false }
  const { data: incidents, isLoading } = useSeamQuery(
    ["incidents", {}, "list", options],
    (seam) => seam.incidents.list(options),
    { enabled: props.isOpen }
  )

  const [deviceIncidents, linkedAccountIncidents] = partition(
    incidents ?? [],
    (incident) => incident.device_id
  )

  const incidentCount = deviceIncidents.length + linkedAccountIncidents.length

  return (
    <Modal {...props} headerLabel={`${incidentCount} issues found`}>
      <div className="modal-issues">
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rounded"
            height={60}
            width="100%"
          />
        ) : (
          <>
            <LinkedAccountIssues
              incidents={linkedAccountIncidents as Incident[]}
            />
            <DeviceIssues incidents={deviceIncidents as Incident[]} />
          </>
        )}
      </div>
    </Modal>
  )
}

export default IssueModal
