import type {} from "@mui/lab/themeAugmentation"
import { buttonClasses, createTheme } from "@mui/material"
import type {} from "@mui/x-data-grid-pro/themeAugmentation"

export const colorDefaults = {
  primary: {
    light: "#DAD0F6",
    main: "#7959D3",
    dark: "#5A35C1",
  },
  background: {
    bgA: "#F1F3F4",
    bgB: "#E9EDEF",
    gray: "#F3F5F7",
    lightHyperlink: "#EEFCFF",
    selected: "#F8FAFD",
  },
  itemHoverBg: "#F4F6F8",
  itemClickBg: "#DDE0E2",
  lines: {
    dividerStrokeLight: "#EEEEEE",
  },
  searchHighlight: "#B6F2FF",
  status: {
    redAlert: "#E36857",
  },
  text: {
    hintTextColor: "#A0A5AD",
    hyperlink: "#078FDB",
    textDefaultColor: "#232B3A",
    textGray1: "#6E7179",
    textGray2: "#9DA1A9",
    textGray3: "#D5D8DC",
    textDimmed: "#BEC4CB",
  },
  white: "#FFFFFF",
  button: {
    neutralStroke: "#D3D9DD",
    adminBg: "#E1F2FB",
    adminBgHover: "#CBE5F3",
  },
}

export const theme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: [
      "Source Sans Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(", "),
  },
  palette: {
    primary: colorDefaults.primary,
    secondary: {
      light: "#CBD0D3",
      main: "#CBD0D3",
      dark: "",
    },
    // warning: {}, // TODO
    success: {
      main: "#5FDA93",
    },
    error: {
      light: "#E36857",
      main: "#E36857", // ?; previously #D32F2F
      dark: "#B71C1C", // ?
    },
    text: {
      primary: "#232B3A",
      secondary: "#9DA1A9",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          textTransform: "inherit",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "outlined" && {
            border: `2px solid ${colorDefaults.primary.main}`,
            borderRadius: 12,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            fontWeight: "600",

            "&:hover": {
              borderWidth: 2,
            },
            "&:disabled": {
              borderWidth: 2,
            },
          }),
          ...(ownerState.variant === "contained" && {
            borderRadius: 12,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            fontWeight: "600",

            "&:hover": {
              borderWidth: 2,
            },

            [`&.${buttonClasses.containedSecondary}`]: {
              padding: "5px 15px", // -1px for border
              fontWeight: "normal",
              backgroundColor: "#F4F6F8",
              border: "1px solid #D3D9DD",
              color: colorDefaults.text.textDefaultColor,
              "&:hover": {
                backgroundColor: "#E8ECEE",
                boxShadow: "none",
                borderWidth: "1px",
              },
            },
          }),
          [`&.${buttonClasses.textSecondary}`]: {
            color: colorDefaults.text.hyperlink,
          },
          [`&.${buttonClasses.sizeLarge}`]: {
            fontSize: "1.125rem",
          },
          textTransform: "inherit",
        }),
        sizeSmall: {
          padding: "0.375rem 1rem",
          lineHeight: "134%",
          fontSize: "14px",
          borderRadius: "0.5rem",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          ".MuiBreadcrumbs-li a": {
            color: colorDefaults.text.textGray1,
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
          borderRadius: 0,
          "& .MuiDataGrid-footerContainer": {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            overflow: "hidden",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
          "& .MuiDataGrid-row:first-of-type": {
            borderTop: `1px solid ${colorDefaults.lines.dividerStrokeLight}`,
          },
          "& .MuiDataGrid-row:focus": {
            backgroundColor: colorDefaults.itemClickBg,
          },
          "& .MuiTablePagination-root": {
            backgroundColor: colorDefaults.background.bgA,
            width: "100%",
          },
          "& .MuiTablePagination-selectLabel": {
            display: "none",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          padding: "2px 8px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colorDefaults.text.hyperlink,
          textDecoration: "none",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 5000,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: "16px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: `${colorDefaults.text.textGray1}`,
          fontSize: "16px",
          fontWeight: "600",
          paddingLeft: "48px",
          paddingRight: "48px",
          textTransform: "inherit",
          "&.Mui-selected": {
            color: colorDefaults.text.textDefaultColor,
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colorDefaults.text.textGray3}`,
          "&.MuiTabs-scroller.MuiTabs-indicator": {
            height: "3px",
          },
          boxShadow: `0 13px 10px -15px ${colorDefaults.text.textGray3}`,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        displayedRows: {
          marginBottom: 0,
        },
        root: {
          backgroundColor: colorDefaults.background.gray,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&.MuiTablePagination-select": {
            display: "none",
          },
        },
        selectLabel: {
          display: "none !important",
        },
        selectRoot: {
          display: "none",
        },
      },
      variants: [
        {
          props: { variant: "head" },
          style: {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          },
        },
        {
          props: { variant: "footer" },
          style: {
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiTableRow: {
      defaultProps: {
        hover: true,
      },
      styleOverrides: {
        hover: {
          backgroundColor: "#F4F6F8",
        },
        root: {
          borderBottom: `1px solid ${colorDefaults.lines.dividerStrokeLight}`,
          height: "80px",
          "&:first-of-type": {
            borderTop: `1px solid ${colorDefaults.lines.dividerStrokeLight}`,
          },
          "&.Mui-selected": {
            backgroundColor: colorDefaults.itemClickBg,
            "&:hover": {
              backgroundColor: colorDefaults.itemClickBg,
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 3500,
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1000,
      lg: 1200,
    },
  },
})

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: false
  }
}
