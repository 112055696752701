import { DEVICE_TYPES, LinkedAccountType } from "lib/constants"

import { Device, LinkedAccount } from "types"

type AugustProperties = {
  august_metadata: {
    _skuNumber: string
  }
}

type SaltoProperties = {
  salto_metadata: {
    lock_type: string
  }
}

type SmartThingsProperties = {
  manufacturer: string
}

/**
 * Fetches the device product model from a device object
 */
export const getDeviceProductModel = (device: Device | null) => {
  switch (device?.device_type) {
    case DEVICE_TYPES.AUGUST_LOCK:
      return (device?.properties as AugustProperties)?.august_metadata
        ?._skuNumber
    case DEVICE_TYPES.SALTO_LOCK:
      return (device?.properties as SaltoProperties)?.salto_metadata?.lock_type
    case DEVICE_TYPES.SMARTTHINGS_LOCK:
      return (device?.properties as SmartThingsProperties)?.manufacturer
    default:
      return undefined
  }
}

export const getAccountUserIdentifier = (account: LinkedAccount | null) => {
  switch (account?.account_type) {
    case LinkedAccountType.Akuvox:
      return account?.user_identifier?.api_url
    case LinkedAccountType.Seam:
      return "Seam"
    default:
      return account?.user_identifier?.email || account?.user_identifier?.phone
  }
}
