import Image from "next/image"
import { Box, ButtonBase } from "@mui/material"

import {
  Image as ContextImage,
  useBuildingInfoModalContext,
} from "components/modals/BuildingInfoModal/context"

import CheckBlackIcon from "assets/images/check-black.svg"

const selectedShadow =
  "0px 0px 0px 1px #FFFFFF, 0px 0px 0px 5px rgba(7, 143, 219, 0.6), 0px 0px 3px 7px rgba(182, 242, 255, 0.5)"
const hoverShadow =
  "0px 0px 0px 1px #FFFFFF, 0px 0px 3px 4px rgba(182, 242, 255, 0.5)"

interface Props {
  image: ContextImage
}

const SelectableImage = ({ image }: Props) => {
  const context = useBuildingInfoModalContext()

  const isSelected = context.state.selectedImage?.url === image.url

  const select = () => {
    if (isSelected) {
      context.setState({
        ...context.state,
        selectedImage: undefined,
      })
    } else {
      context.setState({
        ...context.state,
        selectedImage: image,
      })
    }
  }

  return (
    <ButtonBase
      onClick={select}
      sx={{
        position: "relative",
        boxShadow: isSelected ? selectedShadow : "none",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        borderRadius: "0.5rem",
        aspectRatio: "1 / 1",

        "&, & .overlay": {
          transition: "var(--quick-1) var(--expressive)",
        },

        "&:hover": {
          boxShadow: isSelected ? selectedShadow : hoverShadow,

          "& .overlay": {
            opacity: "1 !important",
          },
        },
      }}
    >
      {isSelected && (
        <Box
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            zIndex: 2,
            filter: "invert(1)",
          }}
        >
          <CheckBlackIcon />
        </Box>
      )}

      <Image
        src={image.url}
        alt={image.alt}
        fill
        style={{ objectFit: "cover" }}
      />

      <Box
        className="overlay"
        sx={{
          position: "absolute",
          inset: 0,
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          opacity: isSelected ? 1 : 0,
          zIndex: 1,
        }}
      />
    </ButtonBase>
  )
}

export default SelectableImage
